import {
  useOrganizationPlaylistQuery,
  useAddSpaceMutation,
  useEditSpaceMutation,
  useQueryClient
} from '../../shared/queryHooks'
import { useAuthContext } from '../../context/AuthContext'
import { useModal } from '../../context/ModalContext'
import { useState } from 'react'
import { logError } from '../../shared/logger'
import { PuffLoader } from 'react-spinners'
import LoadingScreen from '../../components/LoadingScreen'
import SpacePlaylistDropdown from '../SpacePlaylistDropdown'
import GoogleAddressSearch from '../../components/GoogleAddressSearch'
import SpaceMediaPlaylistDropdown from '../SpaceMediaPlaylistDropDown'
import './index.css'
import { Space, User } from '../../types'
import { useNavigate } from 'react-router-dom'

interface CreateOrEditSpaceProps {
  existingSpace?: Space | null;
  openedFromAdmin?: boolean;
  featureTypes: {
    feed: string;
    events: string;
    courses: string;
  };
  setActiveSpaceFeature?: (feature: string) => void;
}

function ManageSpaceSettings ({
  existingSpace,
  featureTypes,
  setActiveSpaceFeature,
  openedFromAdmin
}: CreateOrEditSpaceProps) {
  const { currentUser } = useAuthContext()
  const { clearModal } = useModal()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const addSpace = useAddSpaceMutation()
  const editSpace = useEditSpaceMutation()
  const buttonText = existingSpace ? 'Save' : 'Create Space'
  const hasPreviousPlaylist = !!existingSpace?.programVersionId

  const activities = {
    course: true,
    notes: true,
    agreement: false,
    actions: true,
    goals: true,
    attendance: true,
    reflect: false
  }

  const features = {
    feed: true,
    events: true,
    courses: false,
    media: false
  }

  const defaultNewSpace = {
    id: 0,
    organizationId: currentUser?.currentOrganizationId,
    name: '',
    location: '',
    programVersionId: null,
    courseVersionIds: [],
    programVersionMediaId: null,
    purpose: '',
    activityPreferences: JSON.stringify(activities),
    featurePreferences: JSON.stringify(features),
    spaceRoles: [
      {
        organizationRole: {
          contactId: getContactAndOrganizationRoleId(currentUser)[0]?.contactId
        },
        organizationRoleId: getContactAndOrganizationRoleId(currentUser)[0]?.id,
        title: 'LEADER'
      }
    ]
  } as unknown as Space

  const [space, setSpace] = useState<Space>(existingSpace ?? defaultNewSpace)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { isLoading, isError, data: orgPlaylists, error } = useOrganizationPlaylistQuery({ enabled: !!currentUser })

  if (isLoading) {
    return <LoadingScreen />
  }

  if (isError) {
    logError(error)
  }

  const coursePlaylists = orgPlaylists?.filter(playlist =>
    playlist.programVersion?.program?.courses && playlist.programVersion?.program?.courses.length > 0
  ) ?? []

  const mediaPlaylists = orgPlaylists?.filter(playlist =>
    playlist.programVersion?.program?.media && playlist.programVersion?.program?.media.length > 0
  ) ?? []

  function getContactAndOrganizationRoleId (currentUser: User | null) {
    return (
      currentUser?.organizationRoles?.filter(
        (role) => role?.organization?.id === currentUser?.currentOrganizationId
      ) ?? []
    )
  }

  const handleSubmit = async () => {
    if (space?.name.trim() === '') {
      setErrorMessage('Please enter a space name')
      return
    }

    if (existingSpace) {
      await editSpace.mutateAsync(space)
      await queryClient.invalidateQueries(['connections'])
      await queryClient.invalidateQueries([`organization:${currentUser?.currentOrganizationId}`, 'spaces'])
      setActiveSpaceFeature?.(featureTypes.feed)
      clearModal()
      return
    }

    const response = await addSpace.mutateAsync(space)
    await queryClient.invalidateQueries([
      `organization:${currentUser?.currentOrganizationId}`,
      'spaces'
    ])
    const redirectPath = openedFromAdmin
      ? 'communities/settings/spaces'
      : `spaces/${response.id}?created=true`
    navigate(redirectPath)
  }

  const setAttributeHandling = ({ value, attribute }) => {
    setSpace((previous) => ({ ...previous, [attribute]: value }))
    if (attribute === 'name') {
      setErrorMessage('')
    }

    if (attribute === 'purpose') {
      if (value.length >= 144) {
        setErrorMessage(
          "The spaces's purpose cannot be more than 144 characters"
        )
      } else {
        setErrorMessage('')
      }
    }

    if (attribute === 'programVersionId' && attribute !== null) {
      setSpace((previous) => {
        const currentFeatures = JSON.parse(previous.featurePreferences ?? '{}')
        return {
          ...previous,
          featurePreferences: JSON.stringify({ ...currentFeatures, courses: true })
        }
      })
    }

    if (attribute === 'programVersionMediaId' && attribute !== null) {
      setSpace((previous) => {
        const currentFeatures = JSON.parse(previous.featurePreferences ?? '{}')
        return {
          ...previous,
          featurePreferences: JSON.stringify({ ...currentFeatures, media: value !== '' })
        }
      })
    }
  }

  return (
    <div>
      {errorMessage && (
        <div className="addEdit-space-error">{errorMessage}</div>
      )}
      {!existingSpace && (
        <div className="program-dropdown-container">
          <label>Name</label>
          <input
            type="text"
            placeholder="Space name"
            value={space.name}
            maxLength={50}
            onChange={(e) =>
              setAttributeHandling({ value: e.target.value, attribute: 'name' })
            }
          />
        </div>
      )}
      {!existingSpace && (
        <div className="program-dropdown-container">
          <label>Purpose</label>
          <input
            type="text"
            placeholder="Overarching goal"
            maxLength={144}
            value={space.purpose ?? ''}
            onChange={(e) =>
              setAttributeHandling({
                value: e.target.value,
                attribute: 'purpose'
              })
            }
          />
        </div>
      )}
      <div className="program-dropdown-container">
        <label>Location or video call</label>
        <GoogleAddressSearch
          address={space.location}
          placeholder="Space location or video call link"
          onChange={(e) =>
            setSpace((previous) => ({ ...previous, location: e.target.value }))
          }
          setAddress={(address) =>
            setSpace((previous) => ({ ...previous, location: address }))
          }
        />
      </div>
      {(orgPlaylists?.length ?? 0) > 0
        ? (
            <>
              {coursePlaylists.length > 0 && (
                <div className="program-dropdown-container">
                  <label>Attach Courses</label>
                  <div className="program-dropdown-description">You cannot change courses once added.</div>
                  <div>
                    <SpacePlaylistDropdown
                      orgPlaylists={coursePlaylists}
                      setAttributeHandling={setAttributeHandling}
                      previousPlaylist={hasPreviousPlaylist}
                      value={space?.programVersionId}
                      spaceRoles={space?.spaceRoles}
                    />
                  </div>
                </div>
              )}
              {mediaPlaylists.length > 0 && (
                <div className="program-dropdown-container">
                  <label>Attach Media</label>
                  <div className="program-dropdown-description">You can change media once added.</div>
                  <div>
                    <SpaceMediaPlaylistDropdown
                      orgPlaylists={mediaPlaylists}
                      setAttributeHandling={setAttributeHandling}
                      value={space?.programVersionMediaId}
                    />
                  </div>
                </div>
              )}
            </>
          )
        : null}
      <div className="modal-footer">
        <div className="button secondary" onClick={clearModal}>
          Cancel
        </div>
        <div className="button start-connection-button" onClick={handleSubmit}>
          {addSpace.isLoading || editSpace.isLoading
            ? (
            <PuffLoader color="#fff" size={20} />
              )
            : (
                buttonText
              )}
        </div>
      </div>
    </div>
  )
}

export default ManageSpaceSettings
