import { useRef } from 'react'
import { Paper, MenuList, MenuItem, ListItemText } from '@mui/material'
import DeleteGoal from '../../modals/DeleteGoal'
import CreateOrEditGoal from '../../modals/CreateOrEditGoal'
import { useModal } from '../../context/ModalContext'
import './index.css'
import { DisplayState, OutsideAlerterArgs } from '../../shared/utilities'

interface GoalSettingsMenuItemsProps {
  useOutsideAlerter: (args: OutsideAlerterArgs) => void
  goalId: number
  title: string
  setGoal: DisplayState<number | null>
}

function GoalSettingsMenuItems ({ useOutsideAlerter, goalId, title, setGoal }: GoalSettingsMenuItemsProps) {
  const wrapperRef = useRef(null)
  useOutsideAlerter({ ref: wrapperRef, setDisplayState: setGoal })
  const { makeModal, clearModal } = useModal()

  return (
    <div className='goal-settings dropdown' ref={wrapperRef}>
      <Paper
        sx={{ width: 175 }}
        elevation={24}>
        <MenuList dense ref={wrapperRef}>
          <div>
            <MenuItem
              onClick={() => makeModal({
                modal: <CreateOrEditGoal clearModal={clearModal} goalId={goalId} existingTitle={title} setGoal={setGoal} />,
                title: 'Edit Goal'
              })}>
              <ListItemText>Edit Goal</ListItemText>
            </MenuItem>
            <MenuItem
                onClick={() => makeModal({
                  modal: <DeleteGoal setGoal={setGoal} clearModal={clearModal} goalId={goalId} title={title}/>,
                  title: 'Delete Goal'
                })}>
              <ListItemText>Delete Goal</ListItemText>
            </MenuItem>
          </div>
        </MenuList>
      </Paper>
    </div>
  )
}

export default GoalSettingsMenuItems
