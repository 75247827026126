import {
  useMutation,
  useQueryClient,
  useQuery,
  useInfiniteQuery
} from '@tanstack/react-query'
import { sortUsersByStatusAndName } from './utilities'
import { useModal } from '../context/ModalContext'
import { logError } from './logger'
import { ReactionType, SpaceRole } from '../types'
import * as api from './api'
import { toast } from 'sonner'
import { Entity } from './enums'

export function useOrganizationReportsQuery (
  organizationId: number,
  options: any = {}
) {
  return useQuery(
    ['insights', organizationId],
    () => api.getOrganizationReports(organizationId),
    options
  )
}

export function useOrganizationDailyReportsQuery (
  organizationId: number,
  options: any = {}
) {
  return useQuery(
    ['daily-insights', organizationId],
    () => api.getOrganizationDailyReports(organizationId),
    options
  )
}

export function useWeeklyReportQuery (
  organizationId: number,
  startWeekLocal: number,
  endWeekLocal: number
) {
  return useQuery(['weeklyInsights', organizationId], () =>
    api.getWeeklyReport({ organizationId, startWeekLocal, endWeekLocal })
  )
}

export function useDailyReportQuery (organizationId: number, startDay, nextDay) {
  return useQuery(['dailyInsights', organizationId], () =>
    api.getDailyReport({ startDay, nextDay })
  )
}

export function useOrganizationGoalsQuery (
  organizationId: number,
  options: any = {}
) {
  return useQuery(
    ['goals', organizationId],
    () => api.getOrganizationGoals(organizationId),
    options
  )
}

export function useOrganizationSubscriptionQuery (organizationId: number) {
  return useQuery(['organizations', organizationId, 'subscription'], () =>
    api.getOrganizationSubscription(organizationId)
  )
}

export function useUserQuery (options: any = {}) {
  return useQuery(['user'], api.getUser, options)
}

export function usePublicCoursesQuery () {
  return useQuery(['public_courses'], api.getPublicCourses)
}

export function useCourseQuery (uid: string) {
  return useQuery(['courses', uid], () => api.getCourse(uid))
}

export function usePlansQuery (uid?: string) {
  return useQuery(['courses', uid], api.getPlans)
}

export function useSpacesQuery (organizationId: number, options: any = {}) {
  return useQuery(
    [`organization:${organizationId}`, 'spaces'],
    () =>
      api.getSpaces({
        filter: options?.filter,
        isArchived: options?.isArchived
      }),
    options
  )
}

export function useSpaceQuery (id: number, options: any = {}) {
  return useQuery(['spaces', id], () => api.getSpace(id), options)
}

export function usePeopleQuery (organizationId: number, options: any = {}) {
  return useQuery(
    [`organization:${organizationId}`, 'people'],
    () => api.getPeople(),
    options
  )
}

export function usePeopleGalleryQuery (
  organizationId: number,
  options: any = {}
) {
  return useQuery(
    [`organization:${organizationId}`, 'people-gallery'],
    () => api.getPeopleGallery(),
    options
  )
}

export function usePeopleBySpaceQuery (
  organizationId: number,
  spaceId?: number,
  options: any = {}
) {
  return useQuery(
    [`organization:${organizationId}`, 'people'],
    () => api.getPeopleBySpace(spaceId),
    options
  )
}

export function useOrganizationRoleQuery (
  id: number,
  spaceId?: number,
  options: any = {}
) {
  return useQuery(
    ['organizationRole', id],
    () => api.getOrganizationRole({ id, spaceId }),
    options
  )
}

export function useConnectionQuery (connectionUid: string, options: any = {}) {
  return useQuery(
    ['connections', connectionUid],
    () => api.getConnection(connectionUid),
    options
  )
}

export function useOrganizationUserCoursePresitgeQuery (
  organizationRoleId: number,
  options: any = {}
) {
  return useQuery(
    ['course_prestige'],
    () => api.getCoursePresitge(organizationRoleId),
    options
  )
}

export function useOrganizationUserAchievementsQuery (
  organizationRoleId: number,
  options: any = {}
) {
  return useQuery(
    ['achievements'],
    () => api.getAchievements(organizationRoleId),
    options
  )
}

export function useEventTagsQuery (options: any = {}) {
  return useQuery(['event_tags'], () => api.getEventTags(), options)
}

export function useEventsQuery (options: any = {}) {
  return useQuery(['events'], () => api.getEvents(), options)
}

export function usePlaylistQuery (spaceId: number, type: 'media' | 'courses', options: any = {}) {
  return useQuery(['playlist', spaceId], () => api.getPlaylist({ spaceId, type }), options)
}

export function useExerciseListBySpaceQuery (
  organizationRoleId: number,
  spaceId: number,
  userId?: number,
  options: any = {}
) {
  return useQuery(
    ['exerciseList', organizationRoleId],
    () => api.getExerciseListBySpace({ organizationRoleId, spaceId, userId }),
    options
  )
}

export function useExerciseListByUserQuery (
  organizationRoleId: number,
  userId: number,
  options: any = {}
) {
  return useQuery(
    ['exerciseList', organizationRoleId],
    () => api.getExerciseListByUser({ organizationRoleId, userId }),
    options
  )
}

export function useOrganizationPlaylistQuery (options: any = {}) {
  return useQuery(['playlists'], api.getOrganizationPlaylists, options)
}

export function useOrganizationUserCourseQuery (
  organizationRoleId: number,
  options: any = {}
) {
  return useQuery(
    ['user_courses'],
    () => api.getOrganizationUserCourses(organizationRoleId),
    options
  )
}

export function useOrganizationTagsQuery ({ organizationId, options }) {
  return useQuery(
    ['organization_tags', organizationId],
    api.getOrganizationTags,
    options
  )
}

export function useUsernameSearchQuery (
  searchInput: string,
  spaceId?: number,
  options: any = {}
) {
  return useQuery(
    ['username_search', searchInput],
    () => api.getUsernameSearchResults({ username: searchInput, spaceId }),
    options
  )
}

export function useSearchPeopleByEmailQuery (
  email: string,
  spaceId?: number,
  options: any = {}
) {
  return useQuery(
    ['peopleSearch', email],
    () => api.searchPeopleByEmail({ email, spaceId }),
    options
  )
}

export function useActionsQuery (
  organizationRoleId: number,
  spaceId?: number,
  options: any = {}
) {
  return useQuery(
    ['actions', organizationRoleId],
    () => api.getUserActions({ organizationRoleId, spaceId }),
    options
  )
}

export function useExercisesActionsApprovalQuery (
  courseVersionId: number,
  options: any = {}
) {
  return useQuery(
    ['exercises_action_approval', courseVersionId],
    () => api.getExercisesActionsForApproval(courseVersionId),
    options
  )
}

export function useActiveCourseVersionIdQuery (organizationRoleId: number, spaceId: number, options: any = {}) {
  return useQuery(['active_course_version_id', organizationRoleId, spaceId], () => api.getActiveCourseVersionId(organizationRoleId, spaceId), options)
}

export function useValidateOrganizationInviteCodeQuery (inviteCode: string, options: any = {}) {
  return useQuery(['validate_organization_invite_code', inviteCode], () => api.validateOrganizationInviteCode(inviteCode), options)
}

export function useEditSpaceRolesMutation () {
  const { clearModal } = useModal()
  const queryClient = useQueryClient()
  return useMutation(api.updateSpaceRoles, {
    onSuccess: async (response: any, request: any) => {
      await queryClient.cancelQueries([
        `organization:${request.organizationId}`,
        'people'
      ])
      const previousPeople =
        queryClient.getQueryData<any>([
          `organization:${request.organizationId}`,
          'people'
        ]) || []
      const nextPeople = previousPeople?.map((person) =>
        person?.id === response?.id ? response : person
      )
      queryClient.setQueryData(
        [`organization:${request.organizationId}`, 'people'],
        nextPeople
      )
      clearModal()
      toast.success('Space role updated successfully')
      return { previousPeople }
    },
    onError: (error) => {
      logError(error)
      clearModal()
      toast.error('Failed to update space role')
    }
  })
}

export function useEditContactSpaceRolesMutation () {
  const { clearModal } = useModal()
  const queryClient = useQueryClient()
  return useMutation(api.updateSpaceRoles, {
    onSuccess: async (response, request) => {
      await queryClient.cancelQueries([
        'organizationRole',
        request?.organizationRoleId
      ])
      queryClient.setQueryData(
        ['organizationRole', request?.organizationRoleId],
        response
      )
      clearModal()
      toast.success('Role updated successfully')
    },
    onError: (error) => {
      logError(error)
      clearModal()
      toast.error('Failed to update role')
    }
  })
}

export function useEditPersonMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.updatePerson, {
    onSuccess: async (response: any, request: any) => {
      await queryClient.cancelQueries([
        [`organization:${request.organizationId}`, 'people'],
        ['organizationRole', parseInt(response?.id)]
      ])
      const previousPeople =
        queryClient.getQueryData<any>([
          `organization:${request.organizationId}`,
          'people'
        ]) || []
      const nextPeople = previousPeople?.map((person) =>
        person?.id === response?.id ? response : person
      )
      queryClient.setQueryData(
        [`organization:${request.organizationId}`, 'people'],
        nextPeople
      )
      queryClient.setQueryData(
        ['organizationRole', parseInt(response?.id)],
        response
      )
      await queryClient.invalidateQueries({
        queryKey: ['organizationRole', parseInt(response?.id)]
      })
      toast.success('Person updated successfully')
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to update person')
    }
  })
}

export function useAddPeopleMutation () {
  const { clearModal } = useModal()
  const queryClient = useQueryClient()
  return useMutation(api.addPeople, {
    onSuccess: async (response, request) => {
      const previousPeople =
        queryClient.getQueryData<any>([
          `organization:${request.person.organizationId}`,
          'people'
        ]) || []
      const nextPeople = [...previousPeople, response].sort(
        sortUsersByStatusAndName
      )
      await queryClient.cancelQueries([
        `organization:${request.person.organizationId}`,
        'people'
      ])
      queryClient.setQueryData(
        [`organization:${request.person.organizationId}`, 'people'],
        nextPeople
      )
      clearModal()
      toast.success('Person added successfully')
      return response
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to add person')
    }
  })
}

export function useResendInvitationMutation () {
  const { clearModal } = useModal()
  const queryClient = useQueryClient()
  return useMutation(api.resendInvite, {
    onSuccess: async (response, request: any) => {
      const previousPeople =
        queryClient.getQueryData<any>([
          `organization:${request.organizationId}`,
          'people'
        ]) || []
      const nextPeople = [...previousPeople, response].sort(
        sortUsersByStatusAndName
      )
      await queryClient.cancelQueries([
        `organization:${request.organizationId}`,
        'people'
      ])
      queryClient.setQueryData(
        [`organization:${request.organizationId}`, 'people'],
        nextPeople
      )
      clearModal()
      toast.success('Invitation resent successfully')
    },
    onError: (error) => {
      logError(error)
      clearModal()
      toast.error('Failed to resend invitation')
    }
  })
}

export function useEditPersonStatusMutation () {
  const queryClient = useQueryClient()
  const { clearModal } = useModal()

  return useMutation(api.putPersonStatus, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries([
        `organization:${response.organizationId}`,
        'people'
      ])
      clearModal()
      toast.success('Status updated successfully')
    },
    onError: (error) => {
      logError(error)
      clearModal()
      toast.error('Failed to update status')
    }
  })
}

export function useEditPeopleStatusMutation () {
  const queryClient = useQueryClient()
  const { clearModal } = useModal()

  return useMutation(api.putPeopleStatus, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries([
        `organization:${response.organizationId}`,
        'people'
      ])
      clearModal()
      toast.success('Statuses updated successfully')
    },
    onError: (error) => {
      logError(error)
      clearModal()
      toast.error('Failed to update statuses')
    }
  })
}

export function useEndConnectionMutation () {
  return useMutation(api.endConnection, {
    onError: (error) => {
      logError(error)
      toast.error('Failed to end connection')
    }
  })
}

export function useDeleteConnectionMutation () {
  const queryClient = useQueryClient()
  const { clearModal } = useModal()

  return useMutation(api.deleteConnection, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries(['spaces', response?.spaceId])
      await queryClient.invalidateQueries(['events'])
      clearModal()
      toast.success('Event archived successfully')
    },
    onError: (error) => {
      logError(error)
      clearModal()
      toast.error('Failed to archive event')
    }
  })
}

export function useUpdateConnectionMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.updateConnection, {
    onSuccess: async (response, request) => {
      await queryClient.invalidateQueries(['event_tags'])
      await queryClient.invalidateQueries(['events'])
      await queryClient.invalidateQueries([
        'connections',
        request?.connectionUid
      ])
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to update connection')
    }
  })
}

export function useClaimInviteCodeMutation () {
  return useMutation(api.claimInviteCode, {
    onError: (error) => {
      logError(error)
      toast.error('Failed to claim invite code')
    }
  })
}

export function useAddSpaceMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.postSpaces, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries(['user'])
      toast.success('Space added successfully')
      return response
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to add space')
    }
  })
}

export function useAddContactNoteMutation () {
  const queryClient = useQueryClient()
  const { clearModal } = useModal()

  return useMutation(api.addContactNote, {
    onSuccess: async (response: any) => {
      await queryClient.cancelQueries([
        'organizationRole',
        response.organizationRoleId
      ])
      await queryClient.invalidateQueries([
        'organizationRole',
        response.organizationRoleId
      ])
      clearModal()
      toast.success('Note added successfully')
    },
    onError: (error) => {
      logError(error)
      clearModal()
      toast.error('Failed to add note')
    }
  })
}

export function useEditContactNoteMutation () {
  const queryClient = useQueryClient()
  const { clearModal } = useModal()

  return useMutation(api.editContactNote, {
    onSuccess: async (response: any) => {
      await queryClient.cancelQueries([
        'organizationRole',
        response?.organizationRoleId
      ])
      await queryClient.invalidateQueries([
        'organizationRole',
        response?.organizationRoleId
      ])
      clearModal()
      toast.success('Note updated successfully')
    },
    onError: (error) => {
      logError(error)
      clearModal()
      toast.error('Failed to update note')
    }
  })
}

export function useDeleteContactNoteMutation () {
  const queryClient = useQueryClient()
  const { clearModal } = useModal()

  return useMutation(api.deleteContactNote, {
    onSuccess: async (response: any) => {
      await queryClient.cancelQueries([
        'organizationRole',
        response?.organizationRoleId
      ])
      await queryClient.invalidateQueries([
        'organizationRole',
        response?.organizationRoleId
      ])
      clearModal()
      toast.success('Note deleted successfully')
    },
    onError: (error) => {
      logError(error)
      clearModal()
      toast.error('Failed to delete note')
    }
  })
}

export function useEditSpaceMutation () {
  const { clearModal } = useModal()
  const queryClient = useQueryClient()
  return useMutation(api.putSpaces, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries(['user'])
      await queryClient.cancelQueries(['spaces', response?.id])
      const previousSpace = queryClient.getQueryData(['spaces', response?.id])
      queryClient.setQueryData(['spaces', response?.id], response)
      await queryClient.invalidateQueries(['organizationRole'])
      clearModal()
      toast.success('Space updated successfully')
      return { previousSpace }
    },
    onError: (error) => {
      logError(error)
      clearModal()
      toast.error('Failed to update space')
    }
  })
}

export function useDeleteExerciseProgressMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.deleteExerciseProgress, {
    onMutate: async (request: any) => {
      await queryClient.cancelQueries([
        'exerciseList',
        parseInt(request.organizationRoleId)
      ])
    },
    onSuccess: async (response, request: any) => {
      await queryClient.invalidateQueries([
        'exerciseList',
        parseInt(request.organizationRoleId)
      ])
      await queryClient.invalidateQueries([
        'connections',
        request?.connectionUid
      ])
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to delete exercise progress')
    }
  })
}

export function useCreateExerciseProgressMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.postExerciseProgress, {
    onSuccess: async (response, request: any) => {
      await queryClient.invalidateQueries([
        'exerciseList',
        parseInt(request.organizationRoleId)
      ])
      await queryClient.invalidateQueries([
        'connections',
        request?.connectionUid
      ])
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to create exercise progress')
    }
  })
}

export function useCreateOrganizationCoursesMutation () {
  return useMutation(api.createOrganizationCourse, {
    onError: (error) => {
      logError(error)
      toast.error('Failed to create organization course')
    }
  })
}

export function useCreateOrganizationMutation () {
  return useMutation(api.createOrganization, {
    onSuccess: () => {
      toast.success(`${Entity.Community} successfully created`)
    },
    onError: (error) => {
      logError(error)
      toast.error(`Failed to create ${Entity.Community}`)
    }
  })
}

export function useUpdateOrganizationMutation () {
  return useMutation(api.updateOrganization, {
    onSuccess: () => {
      toast.success(`${Entity.Community} successfully updated`)
    },
    onError: (error) => {
      logError(error)
      toast.error(`Failed to update ${Entity.Community}`)
    }
  })
}

export function useUserMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.putUser, {
    onSuccess: async (response: any) => {
      await queryClient.cancelQueries(['user'])
      const previousUser = queryClient.getQueryData(['user'])
      queryClient.setQueryData(['user'], response)
      return { previousUser }
    }
  })
}

export function useUpdatePromoCodeMutation () {
  const { clearModal } = useModal()
  const queryClient = useQueryClient()
  return useMutation(api.putPromoCode, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries({
        queryKey: ['organizations', response?.appliedBy, 'subscription']
      })
      clearModal()
      toast.success('Promo code updated successfully')
    },
    onError: (error) => {
      logError(error)
      clearModal()
      toast.error('Failed to update promo code')
    }
  })
}

export function useUpdateOrganizationAndCreateSubscriptionMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.unfreezeOrganizationAndUpdatePaymentMethod, {
    onSuccess: async (response, request) => {
      await queryClient.invalidateQueries(['user'])
      await queryClient.invalidateQueries({
        queryKey: ['organizations', request?.organizationId, 'subscription']
      })
    },
    onError: (error) => {
      logError(error)
      toast.error(`Failed to update ${Entity.Community} subscription`)
    }
  })
}

export function useCreateConnectMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.startConnection, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['event_tags'])
      await queryClient.invalidateQueries(['events'])
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to create connect')
    }
  })
}

export function useDeleteOrganizationGoalMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.deleteOrganizationGoal, {
    onMutate: async (request: any) => {
      await queryClient.cancelQueries(['goals', request.organizationId])
    },
    onSuccess: (response, request: any) => {
      const previousOrgGoals = queryClient.getQueryData<any>([
        'goals',
        request.organizationId
      ])
      const updateOrgGoals = previousOrgGoals?.filter(
        (goal) => goal.id !== request.goalId
      )
      queryClient.setQueryData(
        ['goals', request.organizationId],
        updateOrgGoals
      )
      toast.success('Goal deleted successfully')
      return { previousOrgGoals }
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to delete goal')
    }
  })
}

export function useCreateOrganizationGoalMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.createOrganizationGoal, {
    onSuccess: async (response: any) => {
      await queryClient.cancelQueries(['goals', response.organizationId])
      const previousOrgGoals = queryClient.getQueryData<any>([
        'goals',
        response.organizationId
      ])
      queryClient.setQueryData(
        ['goals', response.organizationId],
        [...previousOrgGoals, response]
      )
      toast.success('Goal created successfully')
      return { previousOrgGoals }
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to create goal')
    }
  })
}

export function useUpdateOrganizationGoalMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.updateOrganizationGoal, {
    onSuccess: async (response: any) => {
      await queryClient.cancelQueries(['goals', response.organizationId])
      const previousOrgGoals = queryClient.getQueryData<any>([
        'goals',
        response.organizationId
      ])
      const updateOrgGoals = previousOrgGoals?.map((goal) =>
        goal.id === response.id ? response : goal
      )
      queryClient.setQueryData(
        ['goals', response.organizationId],
        updateOrgGoals
      )
      toast.success('Goal updated successfully')
      return { previousOrgGoals }
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to update goal')
    }
  })
}

export function useDeleteActionMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.deleteAction, {
    onSuccess: async (response, request) => {
      await queryClient.invalidateQueries([
        'actions',
        request?.organizationRoleId
      ])
      await queryClient.invalidateQueries(['connections', request?.uid])
      toast.success('Task deleted successfully')
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to delete action')
    }
  })
}

export function useCreateActionMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.createAction, {
    onSuccess: async (response, request) => {
      await queryClient.invalidateQueries([
        'actions',
        request?.organizationRoleId
      ])
      await queryClient.invalidateQueries(['connections', request?.uid])
      toast.success('Task created successfully')
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to create action')
    }
  })
}

export function useUpdateActionMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.updateAction, {
    onSuccess: async (response, request: any) => {
      await queryClient.cancelQueries(['actions', request?.organizationRoleId])
      await queryClient.invalidateQueries([
        'actions',
        request?.organizationRoleId
      ])
      if (request?.uid) {
        await queryClient.invalidateQueries(['connections', request?.uid])
      }
      toast.success('Task updated successfully')
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to update action')
    }
  })
}

export function useUpdateConnectionAttendanceMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.updateConnectionAttendance, {
    onSuccess: async (response, request) => {
      await queryClient.cancelQueries(['connections', request?.uid])
      await queryClient.invalidateQueries(['connections', request?.uid])
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to update attendance')
    }
  })
}

export function useDeleteConnectionAttendanceMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.deleteConnectionAttendance, {
    onSuccess: async (response, request) => {
      await queryClient.cancelQueries(['connections', request?.uid])
      await queryClient.invalidateQueries(['connections', request?.uid])
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to delete attendance')
    }
  })
}

export function useCreateDailyCheckinMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.createDailyCheckin, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['actions'])
      await queryClient.invalidateQueries(['connections'])
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to create daily checkin')
    }
  })
}

export function useUpdateDailyCheckinMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.putDailycheckins, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['checkin'])
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to update daily checkin')
    }
  })
}

export function useUpdateOrganizationRoleDailyCheckinAccessMutation () {
  return useMutation(api.updateOrganizationRoleDailyCheckinAccess, {
    onSuccess: () => {
      toast.success('Daily checkin access updated successfully')
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to update daily checkin access')
    }
  })
}

export function useUpdateOrganizationPlaylistMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.updateOrganizationPlaylist, {
    onSuccess: async () => {
      await queryClient.cancelQueries(['playlists'])
      await queryClient.invalidateQueries(['playlists'])
      toast.success('Organization content updated successfully')
    },
    onError: (error) => {
      logError(error)
      toast.error(`Failed to update ${Entity.Community} content`)
    }
  })
}

export function useUpdateOrganizationCourseAccessMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.updateOrganizationRoleOrganizationCourseAccess, {
    onSuccess: async () => {
      await queryClient.cancelQueries(['courses'])
      await queryClient.invalidateQueries(['courses'])
      toast.success('Organization course access updated successfully')
    },
    onError: (error) => {
      logError(error)
      toast.error(`Failed to update ${Entity.Community} course access`)
    }
  })
}

export function useAddOrganizationCourseMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.addOrganizationCourse, {
    onSuccess: async () => {
      await queryClient.cancelQueries(['public_courses'])
      await queryClient.invalidateQueries({ queryKey: ['public_courses'] })
      toast.success('Course added successfully')
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to add course')
    }
  })
}

export function useEditPersonCoursePrestigeMutation () {
  const queryClient = useQueryClient()
  const { clearModal } = useModal()

  return useMutation(api.putCoursePresitge, {
    onSuccess: async (response, request) => {
      await queryClient.invalidateQueries(['user_courses'])
      await queryClient.invalidateQueries(['course_prestige'])
      await queryClient.invalidateQueries([
        'exerciseList',
        request.organizationRoleId
      ])
      clearModal()
      toast.success('Course reset successfully')
    },
    onError: (error) => {
      logError(error)
      clearModal()
      toast.error('Failed to reset course')
    }
  })
}

export function useUpdateContactTagsMutation () {
  const queryClient = useQueryClient()
  const { clearModal } = useModal()

  return useMutation(api.putContactTag, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries(['organization_tags'])
      await queryClient.invalidateQueries([
        `organization:${response.organizationId}`,
        'people'
      ])
      clearModal()
      toast.success('Person tags updated successfully')
    },
    onError: (error) => {
      logError(error)
      clearModal()
      toast.error('Failed to update person tags')
    }
  })
}

export function useJoinEventMutation () {
  return useMutation(api.joinEvent, {
    onError: (error) => {
      logError(error)
      toast.error('Failed to join event')
    }
  })
}

export function usePostsQuery (options: any = {}) {
  return useInfiniteQuery(
    ['posts'],
    ({ pageParam = null }) => api.getPosts(pageParam),
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage?.pagination) return undefined
        return lastPage.pagination.nextCursor || undefined
      },
      refetchOnWindowFocus: false,
      select: (data) => ({
        pages: data?.pages || [],
        pageParams: data?.pageParams || []
      }),
      ...options
    }
  )
}

export function usePostsBySpaceQuery (spaceId: number, options: any = {}) {
  return useInfiniteQuery(
    ['space_posts', spaceId],
    ({ pageParam = null }) => api.getPostsBySpace(spaceId, pageParam),
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage?.pagination) return undefined
        return lastPage.pagination.nextCursor || undefined
      },
      enabled: !!spaceId,
      refetchOnWindowFocus: false,
      select: (data) => ({
        pages: data?.pages || [],
        pageParams: data?.pageParams || []
      }),
      ...options
    }
  )
}

export function useCreatePostMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.createPost, {
    onSuccess: (response: any) => {
      const updateQueryData = (queryKey: any) => {
        queryClient.setQueryData(queryKey, (prevPosts: any) => {
          if (!prevPosts) {
            return { pages: [{ posts: [response] }], pageParams: [null] }
          }

          const newPages = prevPosts.pages.map((page: any, index: number) => {
            if (index === 0) {
              return { ...page, posts: [response, ...page.posts] }
            }
            return page
          })

          return {
            ...prevPosts,
            pages: newPages
          }
        })
      }

      if (response?.spaceId) {
        updateQueryData(['space_posts', response.spaceId])
      }

      updateQueryData(['posts'])

      toast.success('Post sent')
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to send post')
    }
  })
}

export function useUpdatePostMutation () {
  const queryClient = useQueryClient()
  const { clearModal } = useModal()
  return useMutation(
    ({ id, content }: { id: number; content: string }) =>
      api.updatePost(id, content),
    {
      onSuccess: (response: any) => {
        const updatePosts = (previousPosts: any) => {
          if (!previousPosts) return previousPosts

          const newPages = previousPosts.pages.map((page: any) => ({
            ...page,
            posts: page.posts.map((post: any) =>
              post.id === response.id ? response : post
            )
          }))

          return {
            ...previousPosts,
            pages: newPages
          }
        }

        if (response.spaceId) {
          queryClient.setQueryData(
            ['space_posts', response.spaceId],
            updatePosts
          )
        }
        queryClient.setQueryData(['posts'], updatePosts)

        clearModal()
        toast.success('Post updated')
      },
      onError: (error) => {
        logError(error)
        toast.error('Failed to update post')
      }
    }
  )
}

export function useDeletePostMutation () {
  const queryClient = useQueryClient()
  const { clearModal } = useModal()

  return useMutation(api.deletePost, {
    onSuccess: (response: { id: number; spaceId?: number }) => {
      const updatePosts = (previousPosts: any) => {
        if (!previousPosts) return previousPosts

        const newPages = previousPosts.pages.map((page: any) => ({
          ...page,
          posts: page.posts.filter((post: any) => post.id !== response.id)
        }))

        return {
          ...previousPosts,
          pages: newPages
        }
      }

      if (response.spaceId) {
        queryClient.setQueryData(
          ['space_posts', response.spaceId],
          updatePosts
        )
      }
      queryClient.setQueryData(['posts'], updatePosts)

      toast.success('Post deleted')
      clearModal()
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to delete post')
      clearModal()
    }
  })
}

export function useReactToPostMutation () {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, type }: { id: number; type: ReactionType }) =>
      api.reactToPost(id, type),
    {
      onSuccess: (response: {
        id: number;
        spaceId?: number;
        reactionId?: number;
        newReaction?: any;
      }) => {
        const updatePosts = (previousPosts: any) => {
          if (!previousPosts || !previousPosts.pages) return previousPosts

          const updatedPages = previousPosts.pages.map((page: any) => ({
            ...page,
            posts: page.posts.map((post: any) => {
              if (post.id === response.id) {
                let updatedReactions = [...post.reactions]
                if (response?.reactionId) {
                  updatedReactions = updatedReactions.filter(
                    (reaction: any) => reaction.id !== response.reactionId
                  )
                } else if (response?.newReaction) {
                  updatedReactions.push(response.newReaction)
                }
                return { ...post, reactions: updatedReactions }
              }
              return post
            })
          }))

          return {
            ...previousPosts,
            pages: updatedPages
          }
        }

        if (response.spaceId) {
          queryClient.setQueryData(
            ['space_posts', response.spaceId],
            updatePosts
          )
        }
        queryClient.setQueryData(['posts'], updatePosts)
      },
      onError: (error) => {
        logError(error)
        toast.error('Failed to react to post')
      }
    }
  )
}

export function usePostCommentsQuery (postId: number) {
  return useQuery(['comments', postId], () => api.getPostComments(postId))
}

export function useCreateCommentMutation () {
  const queryClient = useQueryClient()
  return useMutation(
    ({
      content,
      postId,
      parentCommentId
    }: {
      content: string;
      postId: number;
      parentCommentId?: number;
    }) => api.createComment(content, postId, parentCommentId),
    {
      onSuccess: async (response: any) => {
        await queryClient.invalidateQueries([
          'comments',
          response.newComment.postId
        ])

        if (response?.postId) {
          const updatePosts = (previousPosts: any) => {
            if (!previousPosts) return previousPosts
            return {
              ...previousPosts,
              pages: previousPosts.pages.map((page: any) => ({
                ...page,
                posts: page.posts.map((post: any) =>
                  post.id === response.postId
                    ? { ...post, totalComments: (post.totalComments || 0) + 1 }
                    : post
                )
              }))
            }
          }

          if (response.newComment.post.spaceId) {
            queryClient.setQueryData(
              ['space_posts', response.newComment.post.spaceId],
              updatePosts
            )
          }
          queryClient.setQueryData(['posts'], updatePosts)
        }

        toast.success('Reply posted')
      },
      onError: (error) => {
        logError(error)
        toast.error('Failed to create comment')
      }
    }
  )
}

export function useUpdateCommentMutation () {
  const queryClient = useQueryClient()
  const { clearModal } = useModal()
  return useMutation(
    ({ id, content }: { id: number; content: string }) =>
      api.updateComment(id, content),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['comments'])
        clearModal()
      },
      onError: (error) => {
        logError(error)
        toast.error('Failed to update comment')
      }
    }
  )
}

export function useDeleteCommentMutation () {
  const queryClient = useQueryClient()
  const { clearModal } = useModal()

  return useMutation(api.deleteComment, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries(['comments', response.postId])

      if (response?.postId) {
        const updatePosts = (previousPosts: any) => {
          if (!previousPosts) return previousPosts
          return {
            ...previousPosts,
            pages: previousPosts.pages.map((page: any) => ({
              ...page,
              posts: page.posts.map((post: any) =>
                post.id === response.postId
                  ? {
                      ...post,
                      totalComments: Math.max(0, post.totalComments - 1)
                    }
                  : post
              )
            }))
          }
        }

        if (response.spaceId) {
          queryClient.setQueryData(
            ['space_posts', response.spaceId],
            updatePosts
          )
        }
        queryClient.setQueryData(['posts'], updatePosts)
      }

      toast.success('Reply deleted')
      clearModal()
    },
    onError: (error) => {
      logError(error)
      toast.error('Failed to delete reply')
      clearModal()
    }
  })
}

export function useReactToCommentMutation () {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, type }: { id: number; type: ReactionType }) =>
      api.reactToComment(id, type),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['comments'])
      },
      onError: (error) => {
        logError(error)
        toast.error('Failed to react to comment')
      }
    }
  )
}

export function useUpdateSpaceSpaceRolesMutation () {
  const queryClient = useQueryClient()
  return useMutation(
    ({ spaceId, spaceRoles }: { spaceId: number, spaceRoles: SpaceRole[] }) =>
      api.updateSpaceSpaceRoles(spaceId, spaceRoles),
    {
      onSuccess: async (response: any) => {
        await queryClient.invalidateQueries(['spaces', response?.spaceId])
        await queryClient.invalidateQueries(['spaces', response?.spaceId])
      },
      onError: (error) => {
        logError(error)
        toast.error('Failed to update space roles')
      }
    }
  )
}

export { useQueryClient }
