import { formatReportDate } from '../../shared/utilities'
import { Connection, ConnectionAttendance } from '../../types'

export const getFileDate = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const day = ('0' + date.getDate()).slice(-2)
  return '' + year + month + day
}

export const makeAttendanceCsvRows = (connections: Connection[]): string[][] => {
  return (
    connections.flatMap((connection) =>
      connection.connectionAttendance?.map((attendance) =>
        makeAttendanceCsvRow(connection, attendance)
      ) ?? []
    ) ?? []
  )
}

const makeAttendanceCsvRow = (
  connection: Connection,
  attendance: ConnectionAttendance
): string[] => {
  return [
    String(connection.space?.name),
    makeAttendeeName(attendance),
    formatReportDate(connection.endTime) ?? ''
  ]
}

const makeAttendeeName = (attendance: ConnectionAttendance) => {
  return `${attendance.contact?.firstName} ${attendance.contact?.lastName}`
}
