import { useDeleteContactNoteMutation } from '../../shared/queryHooks'
import { useModal } from '../../context/ModalContext'
import './index.css'

function DeleteContactNotes ({ contactNoteId }: { contactNoteId: number }) {
  const deleteContactNote = useDeleteContactNoteMutation()
  const { clearModal } = useModal()

  return (
    <div>
      <div>
        Are you sure you want to delete this note? Once deleted, it cannot be recovered.
      </div>
      <div className="modal-footer">
        <div className="button secondary" onClick={clearModal}>Cancel</div>
        <div className="button danger" onClick={() => deleteContactNote.mutate({ contactNoteId })}>Delete</div>
      </div>
    </div>
  )
}

export default DeleteContactNotes
