import { Checkbox } from '@mui/material'
import { useState, useEffect } from 'react'
import Avatar from '../Avatar'
import './index.css'
import { Checkin, ExerciseProgress } from '../../types'

export type FormattedExerciseProgress = {
  organizationRoleId?: number;
  exerciseProgress?: ExerciseProgress[];
};

interface ConnectionUserItemProps {
  id: number;
  firstName: string;
  lastName: string;
  attendance: number[];
  avatar: string;
  saveAttendance: (organizationRoleId: number) => void;
  removeAttendance: (organizationRoleId: number) => void;
  readOnly: boolean;
  connectionReviews: Checkin[];
  connectionId: number;
  reviewing: boolean;
  spaceRole: string;
  editAttendance: boolean;
  formattedExerciseProgress: FormattedExerciseProgress[];
  courseVersion: number;
}

function ConnectionUserItem ({
  id,
  firstName,
  lastName,
  attendance,
  avatar,
  saveAttendance,
  removeAttendance,
  readOnly,
  reviewing,
  editAttendance
}: ConnectionUserItemProps) {
  const [checked, setChecked] = useState(attendance?.includes(id))

  const toggleAttendance = (id) => {
    setChecked(!checked)
    if (attendance.includes(id) === false) {
      saveAttendance(id)
    } else {
      removeAttendance(id)
    }
  }

  useEffect(() => {
    setChecked(attendance?.includes(id))
  }, [])

  return (
    <div
      className={
        reviewing ? 'review-connection-user-item' : 'connection-user-item'
      }
      data-testid={`attendance-user-${firstName}-${lastName}`}
    >
      <div>
        <div className="review-connection-wrapper">
          <div className={'connection-user-name-container'}>
            <Avatar
              className="connection-user-item-avatar"
              firstName={firstName}
              lastName={lastName}
              avatar={avatar}
            />
            <div>
              {firstName} {lastName}
            </div>
          </div>
          <div className="connection-user-checkin-checkbox-container">
            <Checkbox
              disabled={!!readOnly && !editAttendance}
              value={id}
              checked={!!checked}
              onChange={(e) => toggleAttendance(parseInt(e.target.value))}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConnectionUserItem
