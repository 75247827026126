import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink
} from '@react-pdf/renderer'
import Button from '../Button'
import { Download } from '@mui/icons-material'
import { getFileDate } from './utils'
import { FreedomBuck } from '../../types'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 30
  },
  metricsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    gap: 10
  },
  metricContainer: {
    flexDirection: 'column',
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    flex: 1,
    borderColor: '#ccc'
  },
  metric: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 5
  },
  metricLabel: {
    fontSize: 12,
    color: 'gray'
  },
  reportTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 5
  },
  reportTimePeriod: {
    fontSize: 14,
    color: 'gray',
    marginBottom: 20
  },
  freedomBuck: {
    marginBottom: 15
  },
  name: {
    fontSize: 12,
    marginBottom: 5
  },
  normal: {
    fontSize: 12,
    marginBottom: 5
  },
  freedomBuckData: {
    fontSize: 11,
    color: 'gray',
    marginBottom: 5
  },
  attendee: {
    marginLeft: 10,
    fontSize: 12,
    marginBottom: 5
  },
  freedomBuckMetaContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    marginBottom: 10
  }
})

type FreedomBuckReport = {
  name: string;
  spaces: string;
};

const makeFreedomBuckReport = (freedomBucks: FreedomBuck[]): FreedomBuckReport[] => {
  return freedomBucks.map((freedomBuck) => {
    return {
      name: freedomBuck?.name ?? '',
      spaces: freedomBuck?.spaces?.join(', ') ?? ''
    }
  })
}

interface ReportProps {
  freedomBucks: FreedomBuck[];
  timePeriod?: string[];
}

const ReportAsPdf = ({
  freedomBucks,
  timePeriod
}: ReportProps) => {
  const freedomBucksData = makeFreedomBuckReport(freedomBucks)

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.reportTitle}>Weekly Freedom Bucks Report</Text>
        <Text style={styles.reportTimePeriod}>{timePeriod}</Text>

        <View style={styles.metricsContainer}>
          <View style={styles.metricContainer}>
            <Text style={styles.metric}>{freedomBucksData?.length ?? 0}</Text>
            <Text style={styles.metricLabel}>Total Freedom Bucks</Text>
          </View>
        </View>

        {freedomBucksData.map((freedomBuck, index) => freedomBuck !== null
          ? (
          <View key={index} style={styles.freedomBuck}>
            <View style={styles.freedomBuckMetaContainer}>
              <Text style={styles.name}>{freedomBuck.name}</Text>
              <Text style={styles.freedomBuckData}>{freedomBuck?.spaces}</Text>
            </View>
          </View>
            )
          : null)}
      </Page>
    </Document>
  )
}

export const DownloadWeeklyReportPdf = (props: ReportProps) => {
  return (
    <PDFDownloadLink
      document={ <ReportAsPdf {...props} /> }
      fileName={`${getFileDate()}_weekly_freedom_bucks_report.pdf`}
    >
      {({ loading }) =>
        loading
          ? null
          : (
          <Button secondary size="sm">
            <Download fontSize="small" /> Download PDF
          </Button>
            )
      }
    </PDFDownloadLink>
  )
}
