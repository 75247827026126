import { Event as EventType } from '../../types/DataTypes'
import Event from '../EventsList/Event'
import { useNavigate } from 'react-router-dom'

type EventProps = {
  content: string;
  connectionUid: string;
  liveEvents: string[];
};

function EventPostCard ({ content, connectionUid, liveEvents }: EventProps) {
  const navigate = useNavigate()
  const parsedContent = JSON.parse(content)

  const event: Partial<EventType> = {
    uid: connectionUid,
    title: parsedContent.connectionTitle,
    startTime: parsedContent.startTime,
    location: parsedContent.location,
    space: {
      id: parsedContent.spaceId,
      name: parsedContent.spaceName,
      location: parsedContent.location
    },
    spaceId: parsedContent.spaceId
  }

  const isEventActive = liveEvents.includes(connectionUid)

  return (
    <Event
      event={event as EventType}
      isActive={isEventActive}
      showSpaceBadge={false}
      showSettings={false}
      onEventClick={() => navigate(`/connections/${connectionUid}`)}
    />
  )
}

export default EventPostCard
