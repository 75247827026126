import { isConnectLive } from '../../shared/utilities'
import { Connection, SpaceRole } from '../../types'
import Avatar from '../Avatar'
import './index.css'
import { format } from 'date-fns'

interface SpaceCardProps {
  spaceName: string;
  spaceRoles?: SpaceRole[] | null;
  upcomingConnections?: Connection[];
}

function SpaceCard ({
  spaceName,
  spaceRoles,
  upcomingConnections
}: SpaceCardProps) {
  const excessRoleDisplayTest =
    spaceRoles && spaceRoles.length > 5 && `+${spaceRoles.length - 5}`

  const checkRoleAndAvatar = ({ role, avatar }) => {
    const noAvatarClass = avatar ? '' : 'no-avatar'
    const roleClass =
      role === 'LEADER'
        ? 'leader-container overlap'
        : 'member-container overlap'
    return `${roleClass} ${noAvatarClass}`
  }

  const contactDisplayAvatar = (role) => {
    return (
      <div
        key={role.id}
        className={checkRoleAndAvatar({
          role: role?.title,
          avatar: role?.organizationRole?.user?.avatar
        })}
      >
        <Avatar
          className={role?.organizationRole?.user?.avatar && 'spaces-avatar'}
          firstName={role?.organizationRole?.contact?.firstName}
          lastName={role?.organizationRole?.contact?.lastName}
          avatar={role?.organizationRole?.user?.avatar}
        />
      </div>
    )
  }

  const contactDisplay = spaceRoles?.slice(0, 5).map((role) => {
    return contactDisplayAvatar(role)
  })

  const excessDisplay = (
    <div className="excess-container overlap no-avatar" key={'excess-roles'}>
      {excessRoleDisplayTest}
    </div>
  )

  const customFormatConnectionDate = (timestamp) => {
    const time = new Date(timestamp)
    const displayOfAmPm = format(time, 'a')
    const month = format(time, 'LLL')
    const hourSeconds = format(time, 'h:mm')

    return `${month} ${time?.getDate()} at ${hourSeconds} ${displayOfAmPm}`
  }

  const hasUpcomingConnections =
    upcomingConnections && upcomingConnections.length > 0

  return (
    <>
      {hasUpcomingConnections && (
        <div className="upcoming-connection-container">
          {isConnectLive(upcomingConnections[0])
            ? (<><div className="live-icon">LIVE</div> Connecting now</>)
            : (<>Next connect {customFormatConnectionDate(upcomingConnections?.[0]?.startTime)}</>)}
        </div>
      )}
      <div className="space-title">{spaceName}</div>
      <div className="avatar-display-container">
        {contactDisplay}
        {spaceRoles && spaceRoles.length > 5 && excessDisplay}
      </div>
    </>
  )
}

export default SpaceCard
