import { getSpaceLeaderPolicy } from '../../shared/policies'
import { useAuthContext } from '../../context/AuthContext'
import { useSpaceQuery, useEventTagsQuery, useCreateConnectMutation, useQueryClient } from '../../shared/queryHooks'
import { isAuthorized } from '../../shared/permissions'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useModal } from '../../context/ModalContext'
import SecondaryNavBar from '../../components/SecondaryNavBar'
import LoadingScreen from '../../components/LoadingScreen'
import SecondaryNavSpaceAvatars from '../../components/SecondaryNavSpaceAvatars'
import AddOrEditSpaceroles from '../../modals/AddOrEditSpaceroles'
import { useEffect, useState } from 'react'
import { getSpace } from '../../shared/api'
import { Connection, NavigationAction } from '../../types'
import ManageSpace from '../../modals/ManageSpace'
import ErrorPage from '../ErrorPage'
import './index.css'
import SpaceNav from '../../components/SpaceNav'
import Connections from './Connections'
import Feed from './Feed'
import Playlist from './Playlist'
import { currentDateTime } from '../../shared/dates'

interface SpaceProps {
  inOrganizationSettings?: boolean;
}

function Space ({ inOrganizationSettings = false }: SpaceProps) {
  const [searchParams] = useSearchParams()
  const paramsCreatedSpace = searchParams.get('created')
  const paramsAction = searchParams.get('action') ?? undefined
  const secondaryNavNavigation = inOrganizationSettings ? '/communities/settings/spaces' : paramsAction ? '/' : '/spaces'
  const secondaryNavTitle = paramsAction ? 'Actions' : ''
  const navigate = useNavigate()
  const { makeModal } = useModal()
  const { id } = useParams()
  const { currentUser } = useAuthContext()
  const idParam = parseInt(id ?? '0')

  const featureTypes = {
    feed: 'Posts',
    events: 'Events',
    courses: 'Courses',
    media: 'Media'
  }

  const [activeSpaceFeature, setActiveSpaceFeature] = useState(featureTypes.feed)

  const createConnect = useCreateConnectMutation()
  const queryClient = useQueryClient()

  useEffect(() => {
    async function handleNewSpaceAndTime () {
      if (paramsCreatedSpace) {
        const space = await getSpace(id)
        makeModal({
          modal: (
            <AddOrEditSpaceroles
              existingSpace={space}
              created={!!paramsCreatedSpace}
            />
          ),
          title: `Space Members for ${space?.name}`,
          className: 'manage-space-modal'
        })
        navigate(`/spaces/${id}`)
      }
    }
    void handleNewSpaceAndTime()
  }, [])

  const { isLoading, isError, data: space, error } = useSpaceQuery(idParam, { enabled: !!currentUser })
  const { isError: isTagsError, data: eventTags, isLoading: isEventTagsLoading, error: tagsError } = useEventTagsQuery({ enabled: !!currentUser && !!space })

  if (isLoading || isEventTagsLoading) {
    return <LoadingScreen />
  }

  if (isError || isTagsError) {
    const e: any = error || tagsError
    const message =
      e?.request?.status === 404
        ? '404, this page does not exist'
        : 'Something went wrong'
    return (
      <>
        <SecondaryNavBar backTo="/" title="Space" />
        <ErrorPage
          message={message}
          action={() => navigate('/')}
          actionTitle="Return home?"
        />
      </>
    )
  }

  if (!space) {
    return (
      <ErrorPage
        message="404, this page does not exist"
        action={() => navigate('/')}
        actionTitle="Return home?"
      />
    )
  }

  const isSpaceLeader = isAuthorized(
    getSpaceLeaderPolicy(idParam, currentUser?.currentOrganizationId),
    currentUser
  )

  const liveEvents = space?.connections
    .filter((connection) => connection.endTime === null && new Date(connection.startTime || '') < new Date())
    ?.sort((a, b) => (new Date(b.startTime || '')).getTime() - (new Date(a.startTime || '')).getTime())
    ?.map((connection) => connection.uid)
    ?.filter((uid): uid is string => uid !== null)

  const getSubnavAction = () => {
    const latestLiveEvent = liveEvents?.[0]

    const actions: NavigationAction[] = []

    if (latestLiveEvent) {
      actions.push({
        action: () => navigate(`/connections/${latestLiveEvent}`),
        actionTitle: 'Join Connect',
        actionClassName: 'secondary'
      })
    } else {
      actions.push({
        action: async () => {
          const quickConnection = {
            location: space?.location || '',
            startTime: new Date(currentDateTime()),
            title: 'Connect',
            connectionTags: [],
            connectionType: 'SECRET',
            spaceId: space?.id,
            postEvent: true
          }

          const newConnection = await createConnect.mutateAsync(quickConnection) as Connection
          await queryClient.invalidateQueries(['spaces'])
          navigate(`/connections/${newConnection.uid}`)
        },
        actionTitle: 'Connect',
        actionClassName: 'secondary connect-button'
      })
    }

    actions.push({
      action: () => {
        makeModal({
          modal: <ManageSpace space={space} featureTypes={featureTypes} setActiveSpaceFeature={setActiveSpaceFeature}/>,
          title: 'Space',
          className: 'manage-space-modal'
        })
      },
      actionTitle: <SecondaryNavSpaceAvatars spaceRoles={space?.spaceRoles} />,
      actionClassName: `secondary icon ${
        isSpaceLeader ? 'avatar-button' : 'avatar-button-member'
      }`
    })

    return actions
  }

  return (
    <div className="space-container">
      <SecondaryNavBar
        backTo={secondaryNavNavigation}
        title={secondaryNavTitle}
        pageTitle={space?.name}
        onClickActions={getSubnavAction()}
      />
      <SpaceNav space={space} activeSpaceFeature={activeSpaceFeature} setActiveSpaceFeature={setActiveSpaceFeature} featureTypes={featureTypes} liveEvents={liveEvents} />
      <div className="space-page-container">
        {activeSpaceFeature === featureTypes.feed && <Feed spaceId={space.id} />}
        {activeSpaceFeature === featureTypes.events && <Connections inOrganizationSettings={inOrganizationSettings} space={space} tags={eventTags} />}
        {activeSpaceFeature === featureTypes.courses && <Playlist spaceId={space.id} type="courses" />}
        {activeSpaceFeature === featureTypes.media && <Playlist spaceId={space.id} type="media" />}
      </div>
    </div>
  )
}

export default Space
