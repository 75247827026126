import PostFeed from '../../components/PostFeed'
import SecondaryNavBar from '../../components/SecondaryNavBar'

function Posts () {
  return (
    <>
      <SecondaryNavBar title="Home" />
      <PostFeed />
    </>
  )
}

export default Posts
