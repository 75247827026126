/* eslint-disable no-use-before-define */
export type Course = {
  id: number
  uid: string
  name: string
  description: string
  program: Program
  logoUrl: string | null
  courseStatus: CourseStatus
  requiresPaidResources: boolean
  isDuplicatable: boolean
  displaySectionHeaders: boolean

  courseVersion?: CourseVersion[]
  publisherOrganization?: Organization
}

export type Media = {
  id: number
  programId: number
  url: string
  name: string
  enabled: boolean
}

export type Program = {
  publisherName: string
  id: number
  uid: string
  name: string
  publisherLogoUrl: string | null
  publisherOrganizationId: number
  programType: ProgramType
  programContentType: ProgramContentType
  courses: Course[]
  media: Media[]
  videos: any[]
}

export type Organization = {
  id: number
  name: string
  domain: string | null
  logoUrl: string | null
  stripeCustomerId: string | null
  stripeSubscriptionId: string | null
  frozen: boolean
  frozenAt: string | null
  studioAccess: boolean
  memberSpaceCreation: boolean
  membersCanInvite: boolean
  inviteUid: string | null
  editConnection: boolean
  dailyCheckin: boolean
  dailyCheckinDaysOfWeek: string | null
  _count?: {
    organizationRoles: number
  }
  defaultSpace: boolean
  shareContactData: boolean

  actions?: Action[] | null
  checkins?: Checkin[] | null
  goals?: Goal[] | null
  organizationCourse?: OrganizationCourse[] | null
  organizationRoles?: OrganizationRole[] | null
  courses?: Course[] | null
  promoCodeAppliedByNavigations?: PromoCode[] | null
  promoCodeCreatedByNavigations?: PromoCode[] | null
  spaces?: Space[] | null
}

export type CourseVersion = {
  id: number
  courseId: number
  version: number

  exercises?: Exercise[] | null
  organizationCourse?: OrganizationCourse[] | null
  course?: Course | null
  space?: Space[] | null
  requiresReview?: boolean | null
  actionsApproved?: boolean | null
}

export type OrganizationPlaylist = {
  programVersion: ProgramVersion
  enabled: boolean
}

export type ProgramVersion = {
  id: number
  programId: number
  program: Program
  version: number
  enabled: boolean
}

export type OrganizationCourse = {
  id: number
  organizationId: number
  courseVersionId: number | null
  organizationRoleAccess: string | null
  enabled: boolean

  organization?: Organization | null
  courseVersion?: CourseVersion | null
}

export type OrganizationRole = {
  id: number
  organizationId: number
  userId: number | null
  contactId: number
  title: OrganizationTitle
  status: Status
  dailyCheckin: boolean
  invitationCode: string | null
  invitationStatus: InvitationStatus | null
  invitationExpiration: string | null

  actions?: Action[] | null
  checkinProgresses?: CheckinProgress[] | null
  connectionAttendances?: ConnectionAttendance[] | null
  contact?: Contact | null
  exerciseProgresses?: ExerciseProgress[] | null
  organization?: Organization | null
  spaceRoles?: SpaceRole[] | null
  user?: User | null

  primaryOwner?: boolean | null
}

export type Space = {
  id: number
  name: string
  location: string | null
  purpose: string | null
  courseVersionId: number | null
  programVersionId: number | null
  programVersionMediaId: number | null
  courseVersionIds: number[] | null
  organizationId: number
  activityPreferences: string | null
  featurePreferences: string | null
  isArchived: boolean
  actions?: Action[] | null
  connections?: Connection[] | null
  organization?: Organization | null
  courseVersion?: CourseVersion | null
  spaceRoles?: SpaceRole[] | null
  spacePlugins?: SpacePlugin[] | null
  defaultSpace: boolean
}

export type AddPersonSpace = {
  id: number
  name: string
}

export type SpaceRole = {
  id: number
  spaceId: number
  organizationRoleId: number | null
  title: SpaceTitle

  organizationRole?: OrganizationRole | null
  space?: Space | AddPersonSpace | null
}

export type User = {
  id: number
  authUid: string | null
  firstName: string | null
  lastName: string | null
  email: string
  avatar: string | null
  shareData: boolean
  createdAt: string | null
  joinCommunityIntent?: string | null
  legalAgreements?: LegalAgreement[] | null
  organizationRoles?: OrganizationRole[] | null

  currentOrganizationId: number | null
  currentOrganizationPrimaryOwner: boolean | null
  daysUntilTrialEndWithOutPaymentMethod: number | null
}

export type Contact = {
  id: number
  firstName: string
  lastName: string
  dob: string | null
  address: string | null
  phone: string | null
  email: string | null
  shareData: boolean
  createdAt: string | null

  tags?: ContactTag[] | null
  organizationRoles?: OrganizationRole[] | null
  contactNotes?: ContactNote[] | null
}

export type ContactTag = {
  id: number,
  tagId: number,
  contactId: number

  tag?: Tag | null
}

export type Tag = {
  id: number,
  name: string
}

export type Section = {
  id: number
  title: string,
  sortBy?: number,
  courseVersionId: number
  courseVersion?: CourseVersion
}

export type Exercise = {
  id: number
  title: string
  parentTitle: string
  url: string | null
  courseVersionId?: number | null

  section: Section
  achievements?: Achievement[] | null
  exerciseProgresses?: ExerciseProgress[] | null
  courseVersion?: CourseVersion | null

  isComplete: boolean,
  exerciseProgressId: number | null
  courseId?: number | null
  connectionId?: number | null
}

export type ExerciseProgress = {
  id: number
  organizationRoleId: number | null
  exerciseId: number
  connectionId: number | null
  timestamp: string | null

  connection?: Connection | null
  exercise?: Exercise | null
  organizationRole?: OrganizationRole | null
}

export type Goal = {
  id: number
  organizationId: number
  title: string

  organization?: Organization | null
}

export type Connection = {
  id: number
  uid: string | null
  title: string | null
  spaceId: number
  notes: Buffer | null
  reflectionNotes: Buffer | null
  agreementNotes: Buffer | null
  startTime: string | null
  endTime: string | null
  location: string | null
  lastActiveAt: string | null
  actions?: Action[] | null
  checkinProgress?: CheckinProgress[] | null
  connectionAttendance?: ConnectionAttendance[] | null
  exerciseProgresses?: ExerciseProgress[] | null
  space?: Space | null
  postEvent?: boolean
  connectionType: 'PUBLIC' | 'SECRET' | 'PRIVATE'
  connectionTags: Array<{
    tag: {
      name: string
    }
  }>
  spaceUserActions?: Action[] | null
}

export type ConnectionAttendance = {
  id: number
  connectionId: number
  organizationRoleId: number | null

  connection?: Connection | null
  organizationRole?: OrganizationRole | null
  exerciseProgress?: ExerciseProgress[] | null
  contact?: Contact | null
}

export type SpacePlugin = {
  id: number
  spaceId: number
  pluginId: number
  plugin: Plugin
  enabled: boolean
}

export type Plugin = {
  id: number
  name: string
  url: string
  courseId: number
}

export type Achievement = {
  id: number
  name: string
  sectionId: number
}

export type ReportAchievement = {
  achievementId: number
  achievementName: string
  spaceId: number
  spaceName: string
  userId: number
  userName: string
}

export type CoursePrestige = {
  prestige: number
  courseVersion: {
    course: Course
  } & CourseVersion
}

export type Document = {
  id: number
  name: string
  version: string

  legalAgreements?: LegalAgreement[] | null
}

export type LegalAgreement = {
  id: number
  userId: number
  documentId: number
  signDate: string

  document?: Document | null
  user?: User | null
}

export type PromoCode = {
  id: number
  code: string
  credit: number
  createdBy: number
  appliedBy: number | null
  status: CreditStatus

  appliedByNavigation?: OrganizationRole | null
  createdByNavigation?: OrganizationRole | null
}

export type Action = {
  id: number
  organizationId: number
  organizationRoleId: number
  connectionId: number | null
  spaceId: number | null
  description: string
  actionType: ActionType | null
  dueBy: string | null
  status: ActionStatus | null
  updatedAt?: string | null

  checkinProgress?: CheckinProgress[] | null
  connection?: Connection | null
  organization?: Organization | null
  organizationRole?: OrganizationRole | null
  space?: Space | null
}

export type CheckinProgress = {
  id: number
  actionId: number | null
  connectionId: number | null
  organizationRoleId: number | null
  response: string
  checkinId: number

  action?: Action | null
  checkin?: Checkin | null
  connection?: Connection | null
  organizationRole?: OrganizationRole | null
}

export type Checkin = {
  id: number
  description: string
  organizationId: number
  responseType: CheckinResponseType
  checkinType: CheckinType

  checkinProgress?: CheckinProgress[] | null
  organization?: Organization | null
}

export type Plan = {
  trialDays: number
}

export type ActionExercises = {
  actions: Action[]
  actionExercises: Exercise[]
}

export type FreedomCheckTracker = {
  connect: {
    id: string;
    organizationId: string;
    startTime: string;
    spaceMembers: SpaceMember[];
  };
}

export type SpaceMember = {
  organizationRoleId: number;
  name: string;
  freedomChecks: number;
  spaces: string[];
}

export type PrestigeCourseProgress = {
  id: number;
  organizationRoleId: number;
  courseVersionId: number;
  prestige: number;
}

export type FreedomBuck = {
  name: string;
  spaces: string[];
}

export type FreedomChecksReport = FreedomCheckTracker[]

export type ActionStatus = 'UNCOMPLETED' | 'REVIEW' | 'COMPLETED'
export type ActionType = 'TASK' | 'DAILY_CHECKIN' | 'CONNECT' | 'EXERCISE'
export type CheckinResponseType = 'TEXT' | 'SCALE' | 'RADIO'
export type CheckinType = 'DAILY' | 'CONNECT'
export type CreditStatus = 'UNAPPLIED' | 'APPLIED' | 'REVOKED'
export type OrganizationTitle = 'OWNER' | 'ADMIN' | 'MEMBER' | 'GUEST'
export type CourseStatus = 'DRAFT' | 'PRIVATE' | 'PUBLIC'
export type SpaceTitle = 'LEADER' | 'MEMBER'
export type Status = 'ACTIVE' | 'INACTIVE'

export enum InvitationStatus {
  Pending = 'PENDING',
  Claimed = 'CLAIMED',
}

export type Event = {
  id: number
  uid: string | null
  title: string | null
  spaceId: number
  date: string | null
  notes: string | null
  reflectionNotes: string | null
  agreementNotes: string | null
  startTime: string
  endTime: string | null
  location: string | null
  lastActiveAt: string | null
  connectionType: 'PUBLIC' | 'SECRET'
  space: {
    id: number
    name: string
    location: string | null
  } | null
  connectionTags: Array<{
    tag: {
      name: string
    }
  }>
}

export type EventTag = {
  id: number;
  connectionId: number;
  tagId: number;
  tag: {
    id: number;
    name: string;
  }
}

export type ContactNote = {
  id: number;
  contactId: number;
  note?: string;
  createdAt: string;
  updatedAt: string;
}

export enum ReactionType {
  LIKE = 'LIKE',
}

export type Post = {
  id: number
  content: string
  organizationRoleId: number
  spaceId?: number
  connectionUid?: string
  reactions: Reaction[]
  totalComments: number
  createdAt: string
  updatedAt: string
  space: {
    name: string
  }
  organizationRole: OrganizationRole
}

export type Comment = {
  id: number
  postId?: number
  content: string
  organizationRoleId: number
  parentCommentId?: number
  reactions: Reaction[]
  replies: Comment[]
  post?: Post
  createdAt: string
  updatedAt: string
  organizationRole: {
    contact: {
      firstName: string
      lastName: string
    }
    user: {
      avatar: string
    }
  }
}

export type Reaction = {
  id: number
  postId?: number
  commentId?: number
  organizationRoleId: number
  reactionType: ReactionType
}

export enum ContentType {
  STRUCTURED = 'STRUCTURED',
  SELF_PACED = 'SELF_PACED',
}

export enum ProgramType {
  COURSE = 'COURSE',
  VIDEO = 'VIDEO',
  MIXED = 'MIXED',
}

export enum ProgramContentType {
  STRUCTURED = 'STRUCTURED',
  SELF_PACED = 'SELF-PACED',
  MIXED = 'MIXED',
}
