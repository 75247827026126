import { useAuthContext } from '../../context/AuthContext'
import { useSpacesQuery } from '../../shared/queryHooks'
import LoadingScreen from '../LoadingScreen'
import './index.css'
import { Checkbox } from '@mui/material'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import { SpaceRole } from '../../types'
import { useEffect } from 'react'
import { roles } from '../../shared/enums'
import { getCurrentOrganization } from '../../shared/utilities'

interface SelectSpacesProps {
  spaceRoles: SpaceRole[]
  updateSpaceRoles: (spaceRole: SpaceRole[]) => void
  role: string
  spaceId?: number
}

function SelectSpaces ({ spaceRoles, updateSpaceRoles, role, spaceId } : SelectSpacesProps) {
  const { currentUser } = useAuthContext()
  const currentOrganization = getCurrentOrganization(currentUser)
  const { isLoading, data: spaces } = useSpacesQuery(currentUser?.currentOrganizationId ?? 0, {
    enabled: !!currentUser,
    filter: spaceId ? 'where-member' : undefined
  })

  useEffect(() => {
    if (currentOrganization?.defaultSpace) {
      const defaultSpace = spaces?.find(space => space.defaultSpace)
      if (defaultSpace) {
        const newSpaceRole: SpaceRole = {
          spaceId: defaultSpace.id,
          title: 'MEMBER',
          space: { id: defaultSpace.id, name: defaultSpace.name },
          organizationRoleId: null,
          id: 0
        }
        updateSpaceRoles([newSpaceRole])
      }
    }

    if (spaceId) {
      updateSpaceRoles([{
        spaceId,
        title: 'MEMBER',
        space: spaces?.find(space => space.id === spaceId),
        organizationRoleId: null,
        id: 0
      }])
    }
  }, [spaces, spaceId])

  useEffect(() => {
    if (role === roles.guest) {
      if (currentOrganization?.defaultSpace) {
        const defaultSpace = spaces?.find(space => space.defaultSpace)
        if (defaultSpace) {
          const newSpaceRole: SpaceRole = {
            spaceId: defaultSpace.id,
            title: 'MEMBER',
            space: { id: defaultSpace.id, name: defaultSpace.name },
            organizationRoleId: null,
            id: 0
          }
          updateSpaceRoles([newSpaceRole])
        }
      } else if (spaceRoles.length > 0) {
        updateSpaceRoles([spaceRoles[0]])
      }
    }
  }, [role])

  if (isLoading) {
    return <LoadingScreen />
  }

  const selectedSpaceDisplay = spaceRoles?.map((role) => (
    <li key={role.spaceId} className="selected-space">
      {role?.space?.name}
    </li>
  ))

  const handleChange = (spaceId: number) => {
    if (role === roles.guest) {
      updateSpaceRoles([{
        spaceId,
        title: 'MEMBER',
        space: spaces?.find(space => space.id === spaceId),
        organizationRoleId: null,
        id: 0
      }])
    } else {
      let updatedPersonSpaces
      if (spaceRoles?.some((role) => role.spaceId === spaceId)) {
        updatedPersonSpaces = spaceRoles?.filter(role => role.spaceId !== spaceId)
      } else {
        updatedPersonSpaces = [...(spaceRoles ?? []), {
          spaceId,
          title: 'MEMBER',
          space: spaces?.find(space => space.id === spaceId)
        }]
      }
      updateSpaceRoles(updatedPersonSpaces)
    }
  }

  if (!spaces || spaces.length === 0) {
    return null
  }

  return (
    <>
      <div style={{ marginTop: '2rem' }} className="permission-subtitle">Add to Spaces</div>
      <div className="space-select">
        {role !== roles.guest && <Select
          multiple
          value={spaceRoles?.map(role => role?.space?.name ?? '') ?? []}
          input={<OutlinedInput />}
          renderValue={(selected) => selected.join(', ')}
          disabled={spaceId !== undefined}
        >
          {spaces?.map((space) => (
            <MenuItem key={space.id} value={space.id} onClick={() => handleChange(space.id)}>
              <Checkbox checked={spaceRoles?.some((role) => role.spaceId === space.id)} value={space.id} />
              <ListItemText primary={space.name} />
            </MenuItem>
          ))}
        </Select>}
        {role === roles.guest && (!spaces?.some(space => space.defaultSpace) || currentOrganization?.defaultSpace === false) && <Select
          value={spaceRoles.length > 0 ? spaceRoles[0]?.spaceId : 0}
          input={<OutlinedInput />}
        >
          {spaces?.map((space) => (
            <MenuItem key={space.id} value={space.id} onClick={() => handleChange(space.id)}>
              <ListItemText primary={space.name} />
            </MenuItem>
          ))}
        </Select>}
        <div className="permission-details">
          <div className="permission-details-title">Selected Default Spaces</div>
          <ul className="permission-details-list">
            {selectedSpaceDisplay}
          </ul>
        </div>
      </div>
    </>
  )
}

export default SelectSpaces
