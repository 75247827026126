import { useAuthContext } from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import Avatar from '../../components/Avatar'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import SecondaryNavBar from '../../components/SecondaryNavBar'
import packageJson from '../../../package.json'
import './index.css'
import { NavigationAction } from '../../types'

function Profile () {
  const { currentUser } = useAuthContext()
  const navigate = useNavigate()

  const getSubnavAction = () => {
    const actions: NavigationAction[] = []

    actions.push({
      action: () => navigate('/profile/settings'),
      actionTitle: <div className="settings-icon"><SettingsRoundedIcon data-testid="profile-settings-button"/></div>,
      actionClassName: 'secondary icon'
    })
    return actions
  }

  return (
    <>
      <SecondaryNavBar title="Profile" onClickActions={getSubnavAction()}/>
      <div className="profile-container">
        <div className="profile-data">
          <Avatar
            onClick={() => navigate('/profile/settings')}
            className="profile-avatar"
            firstName={currentUser?.firstName ?? ''}
            lastName={currentUser?.lastName ?? ''}
            avatar={currentUser?.avatar ?? ''}
          />
          <div className="profile-identity">
            <div className="profile-name">{currentUser?.firstName} {currentUser?.lastName}</div>
            <div>{currentUser?.email}</div>
          </div>
        </div>
      </div>
      <div className="version">v{packageJson.version}</div>
    </>
  )
}

export default Profile
