import { useEffectOnce } from '../../shared/hooks'
import { INVITE_CODE_KEY, useAuthContext } from '../../context/AuthContext'
import { useClaimInviteCodeMutation } from '../../shared/queryHooks'
import LoadingScreen from '../../components/LoadingScreen'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { logError } from '../../shared/logger'
import { useSwitchOrganization } from '../../shared/utilities'

function InviteClaim () {
  const navigate = useNavigate()
  const { mutateAsync: claimInviteCode } = useClaimInviteCodeMutation()
  const { switchOrganization } = useSwitchOrganization()
  const { refreshUser } = useAuthContext()

  const handleClaimInviteCode = async () => {
    const inviteCode = localStorage.getItem(INVITE_CODE_KEY)
    if (!inviteCode) return

    try {
      const data = await claimInviteCode(inviteCode)
      await refreshUser()
      if (data.organizationId) {
        switchOrganization(data.organizationId.toString())
      }
    } catch (error) {
      toast.error('Failed to claim invite code')
      logError(error)
    } finally {
      localStorage.removeItem(INVITE_CODE_KEY)
      navigate('/')
    }
  }

  useEffectOnce(() => {
    void handleClaimInviteCode()
  })

  return <LoadingScreen />
}

export default InviteClaim
