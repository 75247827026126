import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useOrganizationRoleQuery } from '../../../shared/queryHooks'
import { getSpaceLeaderPolicy, getOrganizationAdminPolicy } from '../../../shared/policies'
import { useAuthContext } from '../../../context/AuthContext'
import { isAuthorized } from '../../../shared/permissions'
import { logError } from '../../../shared/logger'
import LoadingScreen from '../../../components/LoadingScreen'
import OrganizationProfile from '../../../components/OrganizationProfile'
import PersonSubNav from '../../../components/PersonSubNav'
import SecondaryNavBar from '../../../components/SecondaryNavBar'
import ErrorPage from '../../ErrorPage'
import { getCurrentOrganization } from '../../../shared/utilities'
import './index.css'

function WorkspaceSettingsPerson () {
  const { id } = useParams()
  const { currentUser } = useAuthContext()
  const currentOrganization = getCurrentOrganization(currentUser)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const spaceId = searchParams.get('spaceId') ? parseInt(searchParams.get('spaceId') ?? '0') : undefined
  const secondaryNavTitle = spaceId ? 'Space' : 'People'
  const secondaryNavNavigation = -1

  const isSpaceLeaderOrAbove = isAuthorized(getSpaceLeaderPolicy(spaceId, currentUser?.currentOrganizationId), currentUser)

  const { isLoading, isError, error, data: person } = useOrganizationRoleQuery(parseInt(id ?? '0'), spaceId, { enabled: !!currentUser && !!isSpaceLeaderOrAbove })

  if (isLoading) {
    return (<LoadingScreen />)
  }

  if (isError) {
    logError(error)
    return (
      <SecondaryNavBar backTo={secondaryNavNavigation} title={secondaryNavTitle} />
    )
  }

  if (!person?.id) {
    return (<ErrorPage message="404, this page does not exist" action={() => navigate('/')} actionTitle="Return home?" />)
  }

  return (
    <>
      <SecondaryNavBar backTo={secondaryNavNavigation} title={secondaryNavTitle} />
      <div className="person-wrapper">
        <div className="person-profile-wrapper" data-testid="person-organization-profile-wrapper">
          <OrganizationProfile person={person} spaceId={spaceId}/>
        </div>
        <div className="person-subnav-wrapper" data-testid="person-organization-subnav-wrapper">
          {isAuthorized(getOrganizationAdminPolicy(currentOrganization?.id), currentUser) && <PersonSubNav person={person} spaceId={spaceId}/>}
        </div>
      </div>
    </>
  )
}

export default WorkspaceSettingsPerson
