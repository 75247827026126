import { useSpacesQuery } from '../../shared/queryHooks'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { getCurrentOrganizationRole, getSortedContent } from '../../shared/utilities'
import SecondaryNavBar from '../../components/SecondaryNavBar'
import LoadingScreen from '../../components/LoadingScreen'
import EmptyHero from '../../components/EmptyHero'
import connectionsIllustration from '../../assets/connections.svg'
import { isAuthorized } from '../../shared/permissions'
import { getOrganizationPaidMemberPolicy, getOrganizationAdminPolicy } from '../../shared/policies'
import { useModal } from '../../context/ModalContext'
import SpaceCard from '../../components/SpaceCard'
import CreateOrEditSpace from '../../modals/CreateOrEditSpace'
import { useAuthContext } from '../../context/AuthContext'
import './index.css'
import { Space, NavigationAction } from '../../types'
import { parseDateTime } from '../../shared/dates'
import { Entity } from '../../shared/enums'

function Spaces () {
  const navigate = useNavigate()
  const { makeModal } = useModal()
  const { currentUser } = useAuthContext()
  const currentOrganizationRole = getCurrentOrganizationRole(currentUser)

  const [searchParams, setSearchParams] = useState('')

  const {
    isLoading,
    isError,
    data: spaces
  } = useSpacesQuery(currentUser?.currentOrganizationId ?? 0, { enabled: !!currentUser, filter: 'where-member' })

  const isPaidMember = isAuthorized(getOrganizationPaidMemberPolicy(currentUser?.currentOrganizationId), currentUser)
  const isAdminOrOwner = isAuthorized(getOrganizationAdminPolicy(currentUser?.currentOrganizationId), currentUser)
  const hasCreateAccess = (isPaidMember && currentOrganizationRole?.organization?.memberSpaceCreation) || isAdminOrOwner

  const getSubnavAction = () => {
    const actions: NavigationAction[] = []

    if (hasCreateAccess) {
      actions.push({
        action: () =>
          makeModal({
            modal: <CreateOrEditSpace />,
            title: 'Create Space',
            disableFocus: true
          }),
        actionTitle: 'Create Space',
        actionClassName: 'secondary'
      })
    }
    return actions
  }

  if (isLoading) {
    return (
      <>
        <SecondaryNavBar title="Spaces" onClickActions={getSubnavAction()} />
        <LoadingScreen />
      </>
    )
  }

  if (isError) {
    return <SecondaryNavBar title="Spaces" onClickActions={getSubnavAction()} />
  }

  const getUpcomingConnections = (space: Space) => {
    return space?.connections
      ?.filter(
        (connection) =>
          !connection?.endTime && parseDateTime(connection?.endTime) < parseDateTime(connection?.startTime)
      )
      .sort((a, b) => parseDateTime(a?.startTime) - parseDateTime(b?.startTime)) ?? []
  }

  const spaceDisplay = (space: Space) => {
    const upcomingConnections = getUpcomingConnections(space)

    return (
      <div
        className="space-card-container"
        data-testid={`space-card-space-${space.id}`}
        onClick={() => navigate(`/spaces/${space.id}`)}
      >
        <SpaceCard spaceName={space.name} spaceRoles={space?.spaceRoles} upcomingConnections={upcomingConnections} />
      </div>
    )
  }

  const sortSpaces = (a: Space, b: Space) => {
    return a.name.localeCompare(b.name)
  }

  const sortSpacesWithConnects = (a: Space, b: Space) => {
    const aConnections = getUpcomingConnections(a)
    const bConnections = getUpcomingConnections(b)

    return parseDateTime(aConnections?.[0]?.startTime) - parseDateTime(bConnections?.[0]?.startTime)
  }

  const spacesAsMember = spaces?.filter((space) =>
    space.spaceRoles?.some((role) => role.organizationRoleId === currentOrganizationRole?.id && space.isArchived === false)
  )

  const spacesWithConnects = spacesAsMember.filter((space) => getUpcomingConnections(space)?.length > 0).sort(sortSpacesWithConnects)
  const spacesWithoutConnects = spacesAsMember.filter((space) => getUpcomingConnections(space)?.length === 0).sort(sortSpaces)

  const sortedSpaces = spacesWithConnects.concat(spacesWithoutConnects)

  const otherSpaces = spaces?.filter((space) =>
    space.spaceRoles?.every((role) => role.organizationRoleId !== currentOrganizationRole?.id && space.isArchived === false)
  ).sort(sortSpaces)

  const action = [
    {
      onClick: () =>
        makeModal({
          modal: <CreateOrEditSpace />,
          title: 'Create Space',
          disableFocus: true
        }),
      actionTitle: 'New Space'
    }
  ]

  function getEmptyHeroProps () {
    if (hasCreateAccess) {
      return {
        description: 'Create a space to start making connections.',
        action
      }
    } else {
      return {
        description: `You aren't currently in any spaces. Contact your ${Entity.Community} admin to get invited to a space.`
      }
    }
  }

  const spaceCardsAsMember = getSortedContent(searchParams, spaceDisplay, sortedSpaces)
  const otherSpaceCards = getSortedContent(searchParams, spaceDisplay, otherSpaces)

  return (
    <>
      <SecondaryNavBar title="Spaces" onClickActions={getSubnavAction()} />
      {spaces.length > 0
        ? (
        <>
          <div className="spaces-search" style={{ marginBottom: '0.5rem' }}>
            <input
              type="text"
              placeholder="Search Spaces..."
              onChange={(e) => setSearchParams(e.target.value)}
              value={searchParams}
            />
          </div>
          {spaceCardsAsMember.length > 0 ? <div className="spaces">{spaceCardsAsMember}</div> : null}
          {isAdminOrOwner && otherSpaceCards.length > 0 && (
            <div className="other-spaces">
              <h4 style={{ marginLeft: '0.5rem', marginTop: 0, opacity: 0.5, fontWeight: 'normal' }}>Spaces you're not a member of</h4>
              {otherSpaceCards}
            </div>
          )}
        </>
          )
        : (
        <EmptyHero image={connectionsIllustration} title="Spaces" {...getEmptyHeroProps()} />
          )}
    </>
  )
}

export default Spaces
