import { useModal } from '../../context/ModalContext'
import { getConnection } from '../../shared/api'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { arraysMatch, formatConnectionDate } from '../../shared/utilities'
import InsightAttendance from '../../modals/InsightAttendance'
import PeopleIcon from '@mui/icons-material/People'
import { Connection } from '../../types'
import { parseDateTime } from '../../shared/dates'
import LoadingScreen from '../LoadingScreen'
import { useNavigate } from 'react-router-dom'
import { DownloadWeeklyReportPdf } from './DownloadWeeklyReportPdf'
import { DownloadWeeklyReportCsv } from './DownloadWeeklyReportCsv'
import Table from '../Table'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import './index.css'
import { Entity } from '../../shared/enums'

const SpaceNameCell = ({ row }) => {
  const navigate = useNavigate()

  return (
    <div
      className="contact-details-cell"
      onClick={() =>
        navigate(`/communities/settings/spaces/${row.original?.id}`)
      }
      style={{ margin: '-0.25rem 0', marginLeft: '-0.5rem' }}
    >
      <div
        style={{
          fontSize: '1rem',
          flex: 1,
          padding: '0.35rem'
        }}
      >
        {row.original.name}
      </div>
    </div>
  )
}

function WeeklyHealth ({ data, timePeriod, isFetching }) {
  const { makeModal, clearModal } = useModal()
  const queryClient = useQueryClient()

  const [connections, setConnections] = useState<Connection[]>([])
  const uids = []?.concat(...data?.map((space) => space?.uids))
  const [lastUids, setLastUids] = useState([])
  const [loading, setLoading] = useState(true)
  const title = 'Weekly Health'

  // TODO this needs to be refactored as a backend endpoint
  useEffect(() => {
    const fetchConnections = async () => {
      if (uids.length === 0 && connections.length > 0) setConnections([])
      if (!arraysMatch(lastUids, uids) && uids.length !== 0) {
        setLoading(true)
        const promises = uids.map((uid) =>
          queryClient.fetchQuery(['connections', uid], () => getConnection(uid))
        )
        const connectionData = await Promise.all(promises)
        setConnections(connectionData)
        setLastUids(uids)
        setLoading(false)
      }
      setLoading(false)
    }
    void fetchConnections()
  }, [uids, lastUids, timePeriod, isFetching])

  const filteredSpacesWithoutConnects = data?.filter((space) => {
    const spaceConnection = connections?.filter((connection) =>
      space?.uids?.includes(connection?.uid) &&
      (connection?.connectionAttendance?.length ?? 0) > 0
    )
    return spaceConnection.length > 0
  })

  const columns = [
    {
      header: Entity.Space,
      accessorKey: 'name',
      cell: ({ row }) => <SpaceNameCell row={row} />
    },
    {
      header: `${Entity.Event}s`,
      accessorKey: 'connections',
      cell: ({ row }) => {
        const spaceConnection = connections
          ?.filter(
            (connection) =>
              row.original?.uids?.includes(connection?.uid) &&
              (connection?.connectionAttendance?.length ?? 0) > 0
          )
          .sort((a, b) => parseDateTime(a?.startTime) - parseDateTime(b?.startTime))

        const hasAttendance = spaceConnection.length > 0

        const totalAttendees = spaceConnection.reduce(
          (acc, connection) => acc + (connection.connectionAttendance?.length || 0),
          0
        )

        return (
          <div
            className={`connects-table-container ${
              hasAttendance ? 'with-attendance' : ''
            }`}
            style={{ opacity: hasAttendance ? 1 : 0.5 }}
          >
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div style={{ margin: '0' }}>
                      Total {Entity.Event}s: {spaceConnection.length}
                    </div>
                    <div className="attendance-table-link">
                      <PeopleIcon />
                      {totalAttendees}
                    </div>
                  </div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {spaceConnection.map((connection, index) => (
                  <div
                    key={index}
                    onClick={() => handleAttendance(connection)}
                    style={{ cursor: 'pointer', padding: '0.5rem 0', display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography>
                      {formatConnectionDate(connection.startTime)}
                    </Typography>
                    <Typography>
                      <div className="attendance-table-link">
                        <PeopleIcon />
                        {connection.connectionAttendance?.length}
                      </div>
                    </Typography>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          </div>
        )
      }
    }
  ]

  function handleAttendance (connection) {
    if (connection) {
      makeModal({
        modal: (
          <InsightAttendance clearModal={clearModal} connection={connection} />
        ),
        title: 'Attendance'
      })
    }
  }

  if (loading) {
    return (
      <div className="weekly-health-container">
        <h2>Weekly Report</h2>
        <h4>{timePeriod}</h4>
        <LoadingScreen />
      </div>
    )
  }

  const totalWeeklyAttendance = connections.reduce((acc, connection) => {
    return acc + (connection?.connectionAttendance?.length ?? 0)
  }, 0)

  const totalSpacesWithConnects = data?.reduce((acc, space) => {
    return acc + (space?.uids?.length > 0 ? 1 : 0)
  }, 0)

  return (
    <div className="weekly-health-container">
      <div className="weekly-report-meta">
        <div>
          <h2 style={{ marginTop: 0 }}>Weekly Report</h2>
          <h4>{timePeriod}</h4>
        </div>
        {connections.length > 0 && (
          <div className="download-buttons">
            <DownloadWeeklyReportPdf
              connections={connections}
              timePeriod={timePeriod}
              totalWeeklyAttendance={totalWeeklyAttendance}
              totalSpacesWithConnects={totalSpacesWithConnects}
            />
            <DownloadWeeklyReportCsv connections={connections} />
          </div>
        )}
      </div>
      <div className="metrics-container">
        <div className="metric-container">
          <div className="metric-data">{totalWeeklyAttendance}</div>
          <div className="metric-label">Total Attendance</div>
        </div>
        <div className="metric-container">
          <div className="metric-data">{totalSpacesWithConnects}</div>
          <div className="metric-label">{Entity.Space}s with {Entity.Event}s</div>
        </div>
      </div>
      <Table
        typeReferenceText={title}
        columns={columns}
        data={filteredSpacesWithoutConnects}
        requiresSearch={false}
        tableWrapperClass="weekly-health-container"
      />
    </div>
  )
}

export default WeeklyHealth
