import { useEffect } from 'react'

function useLayoutDetector () {
  useEffect(() => {
    document.body.classList.add('app-overflow-hidden')

    const isInWebAppiOS = (window.navigator.standalone === true)
    const isInWebAppChrome = (window.matchMedia('(display-mode: standalone)').matches)

    if (isInWebAppiOS || isInWebAppChrome) {
      document.body.classList.add('app-standalone')
    }

    if (isInWebAppiOS) {
      document.body.classList.add('app-standalone-ios')
    }

    return () => {
      document.body.classList.remove('app-overflow-hidden')
      document.body.classList.remove('app-standalone')
      document.body.classList.remove('app-standalone-ios')
    }
  }, [])
}

export default useLayoutDetector
