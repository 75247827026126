import { useState, useEffect } from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Event } from '../../types/DataTypes'
import './index.css'

function EventsCalendar ({
  events,
  handleJoinEvent
}: {
  events: Event[];
  handleJoinEvent: (event: Event) => void;
}) {
  const [currentDate, setCurrentDate] = useState(new Date())
  const [today] = useState(new Date())

  const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

  useEffect(() => {
    document.body.style.margin = '0'
    document.body.style.padding = '0'
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.margin = ''
      document.body.style.padding = ''
      document.body.style.overflow = ''
    }
  }, [])

  const getDaysInMonth = (date: Date) => {
    const year = date.getFullYear()
    const month = date.getMonth()
    const days: any[] = []
    const firstDay = new Date(year, month, 1).getDay()
    const lastDate = new Date(year, month + 1, 0).getDate()

    for (let i = 0; i < firstDay; i++) {
      days.push(null)
    }

    for (let i = 1; i <= lastDate; i++) {
      days.push(new Date(year, month, i))
    }

    while (days.length % 7 !== 0) {
      days.push(null)
    }

    return days
  }

  const navigateMonth = (direction: 'prev' | 'next') => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate)
      newDate.setMonth(newDate.getMonth() + (direction === 'next' ? 1 : -1))
      return newDate
    })
  }

  const renderEvents = (date: Date) => {
    const dayEvents = events.filter((event) => {
      const startTime = new Date(event.startTime)
      return (
        startTime.getDate() === date.getDate() &&
        startTime.getMonth() === date.getMonth() &&
        startTime.getFullYear() === date.getFullYear()
      )
    })
    return dayEvents.map((event, index) => {
      const startTime = new Date(event.startTime)
      const formattedTime = startTime.toLocaleTimeString([], {
        hour: 'numeric',
        minute: '2-digit'
      })

      return (
        <div
          key={index}
          className="event"
          onClick={() => handleJoinEvent(event)}
        >
          <div className="event-content">
            <span className="event-title">
              {event.title && event.title.trim() !== ''
                ? (
                  <>
                    <span className="event-title-text">{event.title}</span>
                    {` - ${formattedTime}`}
                  </>
                  )
                : (
                    formattedTime
                  )
              }
            </span>
          </div>
        </div>
      )
    })
  }

  const days = getDaysInMonth(currentDate)

  const isToday = (date: Date) => {
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    )
  }

  return (
    <div className="calendar">
      <div className="calendar-header">
        <h2 className="calendar-title">
          {currentDate.toLocaleString('default', {
            month: 'long',
            year: 'numeric'
          })}
        </h2>
        <div className="calendar-nav">
          <button onClick={() => navigateMonth('prev')} className="nav-button">
            <ChevronLeftIcon />
          </button>
          <button onClick={() => navigateMonth('next')} className="nav-button">
            <ChevronRightIcon />
          </button>
        </div>
      </div>
      <div className="calendar-body">
        <div className="weekdays">
          {daysOfWeek.map((day) => (
            <div key={day} className="weekday">
              {day}
            </div>
          ))}
        </div>
        <div className="days">
          {days.map((day, index) => (
            <div
              key={index}
              className={`day ${day ? 'day-in-month' : 'day-out-month'}`}
            >
              {day && (
                <>
                  <div className={`day-number ${isToday(day) ? 'today' : ''}`}>
                    {day.getDate()}
                  </div>
                  {day instanceof Date && renderEvents(day)}
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default EventsCalendar
