import { getOrganizationAdminPolicy, getSpaceLeaderPolicy } from '../../shared/policies'
import { useAuthContext } from '../../context/AuthContext'
import { isAuthorized } from '../../shared/permissions'
import { useModal } from '../../context/ModalContext'
import ViewOrganizationProfile from '../../modals/ViewOrganizationProfile'
import ManageSpaceSpaceRoles from '../ManageSpaceSpaceRoles'
import Avatar from '../Avatar'
import { SpaceRole, SpaceTitle } from '../../types'
import './index.css'

interface SpaceUserItemProps {
  spaceRole: SpaceRole
  selectRole: ({ title, spaceRoleId }: { title: SpaceTitle, spaceRoleId: number }) => void
}

function SpaceUserItem ({ spaceRole, selectRole }: SpaceUserItemProps) {
  const { currentUser } = useAuthContext()
  const { makeModal } = useModal()
  const isWorkspaceAdmin = isAuthorized(
    getOrganizationAdminPolicy(currentUser?.currentOrganizationId),
    currentUser
  )
  const isSpaceLeader = isAuthorized(getSpaceLeaderPolicy(spaceRole?.spaceId, currentUser?.currentOrganizationId), currentUser)

  const displayPerson = () => {
    if (!spaceRole?.organizationRole) return

    makeModal({
      modal: <ViewOrganizationProfile person={spaceRole.organizationRole} />,
      title: `${spaceRole?.organizationRole?.contact?.firstName}'s Profile`,
      modalBackgroundClose: true,
      slideOut: true
    })
  }

  return (
    <div
      className='space-user-item'
      data-testid={`${spaceRole?.organizationRole?.contact?.firstName}-${spaceRole?.organizationRole?.contact?.lastName}-avatar`}
    >
      <div className={`space-user-name-container ${isWorkspaceAdmin && 'clickable'}`} onClick={() => displayPerson()}>
        <Avatar className="space-user-item-avatar" firstName={spaceRole?.organizationRole?.contact?.firstName} lastName={spaceRole?.organizationRole?.contact?.lastName} avatar={spaceRole?.organizationRole?.user?.avatar} />
        <div className="name-container">{spaceRole?.organizationRole?.contact?.firstName} {spaceRole?.organizationRole?.contact?.lastName}</div>
      </div>
      {isSpaceLeader && <ManageSpaceSpaceRoles spaceRole={spaceRole} selectRole={selectRole} />}
    </div>
  )
}

export default SpaceUserItem
