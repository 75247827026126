import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import { HocuspocusProvider } from '@hocuspocus/provider'
import Collaboration from '@tiptap/extension-collaboration'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import Highlight from '@tiptap/extension-highlight'
import { Editor } from '@tiptap/react'
import { Dispatch, useEffect, useRef, useState } from 'react'
import { config } from './config'

function useEditor (uid: string, readOnly: boolean, options: any = {}) {
  const [editor, setEditor] = useState<Editor | null>(null)

  useEffectOnce(() => {
    if (!editor) {
      const provider = new HocuspocusProvider({
        url: `${config.WEBSOCKET_HOST}/connections`,
        name: uid,
        onAwarenessUpdate: (data) => {
          options?.onAwarenessUpdate?.(data)
        }
      })
      const extensions: any = [
        StarterKit.configure({
          history: false
        }),
        Placeholder.configure({
          placeholder: 'Write something...'
        }),
        Link,
        Underline,
        Collaboration.configure({
          document: provider.document
        }),
        Highlight
      ]

      if (options?.showCollaborationCursor) {
        extensions.push(CollaborationCursor.configure({
          provider
        }))
      }

      const liveEditor = new Editor({
        editable: !readOnly,
        autofocus: true,
        extensions
      })
      setEditor(liveEditor)

      return () => {
        provider.disconnect()
        liveEditor.destroy()
      }
    }
  })

  return editor
}

const useEffectOnce = (effect) => {
  const destroyFunc = useRef<any>()
  const effectCalled = useRef(false)
  const renderAfterCalled = useRef(false)
  const [, setVal] = useState(0)

  if (effectCalled.current) {
    renderAfterCalled.current = true
  }

  useEffect(() => {
    if (!effectCalled.current) {
      destroyFunc.current = effect()
      effectCalled.current = true
    }

    setVal(val => val + 1)

    return () => {
      if (!renderAfterCalled.current) { return }
      if (destroyFunc.current) { destroyFunc.current() }
    }
  }, [])
}

const useLocalStorage = (key: string): [string | null, Dispatch<string | null>] => {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key)
    return storedValue
  })

  useEffect(() => {
    if (!value) {
      localStorage.removeItem(key)
    } else {
      localStorage.setItem(key, value)
    }
  }, [key, value])

  return [value, setValue]
}

export {
  useEditor,
  useEffectOnce,
  useLocalStorage
}
