import { useState, useCallback } from 'react'
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material'
import { Edit, Delete, MoreHoriz } from '@mui/icons-material'
import { Comment, ReactionType } from '../../types'
import {
  formatTimeAgo,
  getCurrentOrganizationRole
} from '../../shared/utilities'
import {
  usePostCommentsQuery,
  useReactToCommentMutation,
  useCreateCommentMutation,
  useUpdateCommentMutation
} from '../../shared/queryHooks'
import { logError } from '../../shared/logger'
import LoadingScreen from '../LoadingScreen'
import { useAuthContext } from '../../context/AuthContext'
import { useModal } from '../../context/ModalContext'
import DeleteCommentOrPost from '../../modals/DeleteCommentOrPost'
import ContentDisplay from '../ContentDisplay'
import Avatar from '../Avatar'
import ViewOrganizationProfile from '../../modals/ViewOrganizationProfile'
import './index.css'
import Button from '../Button'
import CommentReactions from '../CommentReactions'

function CommentsDisplay ({ postId }: { postId: number }) {
  const { currentUser } = useAuthContext()
  const { makeModal } = useModal()

  const currentOrganizationRole = getCurrentOrganizationRole(currentUser)
  const {
    isLoading,
    isError,
    data: comments,
    error
  } = usePostCommentsQuery(postId)
  const [expandedComments, setExpandedComments] = useState<number[]>([])
  const [replyingTo, setReplyingTo] = useState<number | null>(null)
  const [newComment, setNewComment] = useState('')
  const [replyContents, setReplyContents] = useState<{ [key: number]: string }>(
    {}
  )
  const [editingCommentId, setEditingCommentId] = useState<number | null>(null)
  const [editContent, setEditContent] = useState('')
  const [menuAnchorEl, setMenuAnchorEl] = useState<{
    [key: number]: HTMLElement | null;
  }>({})

  const reactToComment = useReactToCommentMutation()
  const addComment = useCreateCommentMutation()
  const editComment = useUpdateCommentMutation()

  const handleReact = useCallback(
    async (id: number, type: ReactionType) => {
      await reactToComment.mutateAsync({ id, type })
    },
    [reactToComment]
  )

  const handleAddComment = useCallback(
    async (content: string, parentCommentId?: number) => {
      await addComment.mutateAsync({ postId, content, parentCommentId })
      if (!parentCommentId) {
        setNewComment('')
      }
      if (parentCommentId) {
        setReplyContents((prev) => ({
          ...prev,
          [parentCommentId]: ''
        }))
      }
      setReplyingTo(null)
    },
    [addComment, postId]
  )

  const handleCommentSubmit = async () => {
    if (newComment.trim() !== '') {
      await handleAddComment(newComment)
    }
  }

  const handleReplySubmit = async (commentId: number) => {
    if (replyContents[commentId]?.trim() !== '') {
      await handleAddComment(replyContents[commentId], commentId)
      setReplyContents((prev) => ({
        ...prev,
        [commentId]: ''
      }))
    }
  }

  const toggleExpanded = (commentId: number) => {
    setExpandedComments((prev) =>
      prev.includes(commentId)
        ? prev.filter((id) => id !== commentId)
        : [...prev, commentId]
    )
  }

  const handleEditComment = useCallback(async (id: number, content: string) => {
    await editComment.mutateAsync({ id, content })
    setEditingCommentId(null)
    setEditContent('')
  }, [])

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    commentId: number
  ) => {
    setMenuAnchorEl((prev) => ({ ...prev, [commentId]: event.currentTarget }))
  }

  const handleMenuClose = (commentId: number) => {
    setMenuAnchorEl((prev) => ({ ...prev, [commentId]: null }))
  }

  if (isLoading) {
    return <LoadingScreen />
  }

  if (isError) {
    logError(error)
  }

  const renderComment = (comment: Comment, depth: number = 0) => {
    const isExpanded = expandedComments.includes(comment.id)
    const isEditing = editingCommentId === comment.id

    const handleReplyingTo = (commentId: number) => {
      setReplyingTo(commentId === replyingTo ? null : commentId)
      setReplyContents((prev) => ({
        ...prev,
        [commentId]: ''
      }))
      toggleExpanded(comment.id)
    }

    const displayPerson = (person) => {
      makeModal({
        modal: <ViewOrganizationProfile person={person} />,
        title: `${person?.contact?.firstName}'s Profile`,
        modalBackgroundClose: true,
        slideOut: true
      })
    }

    return (
      <Box
        key={comment.id}
        className={`comment ${depth > 0 ? 'comment-nested' : ''}`}
      >
        <Box className="comment-content">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '8px'
              }}
            >
              <Avatar
                onClick={() => displayPerson(comment.organizationRole)}
                className="comment-avatar"
                firstName={comment.organizationRole.contact.firstName}
                lastName={comment.organizationRole.contact.lastName}
                avatar={comment.organizationRole.user.avatar}
              />
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle2" className="comment-author">
                  {comment.organizationRole.contact.firstName}{' '}
                  {comment.organizationRole.contact.lastName}
                </Typography>
                <Typography variant="caption" className="comment-timestamp">
                  {formatTimeAgo(comment.createdAt)}
                </Typography>
              </Box>
            </div>
            <div style={{ marginBottom: '8px' }}>
              {comment.organizationRoleId === currentOrganizationRole?.id && (
                <Box style={{ marginRight: '-0.25rem' }}>
                  <IconButton
                    size="small"
                    className="comment-controls"
                    onClick={(e) => handleMenuClick(e, comment.id)}
                  >
                    <MoreHoriz fontSize="small" />
                  </IconButton>
                  <Menu
                    anchorEl={menuAnchorEl[comment.id]}
                    open={Boolean(menuAnchorEl[comment.id])}
                    onClose={() => handleMenuClose(comment.id)}
                  >
                    <MenuItem
                      onClick={() => {
                        setEditingCommentId(comment.id)
                        setEditContent(comment.content)
                        handleMenuClose(comment.id)
                      }}
                    >
                      <Edit fontSize="small" sx={{ mr: 1 }} />
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        makeModal({
                          modal: <DeleteCommentOrPost commentId={comment.id} />,
                          title: 'Are you sure?'
                        })
                        handleMenuClose(comment.id)
                      }}
                    >
                      <Delete fontSize="small" sx={{ mr: 1 }} />
                      Delete
                    </MenuItem>
                  </Menu>
                </Box>
              )}
            </div>
          </div>
          <Box className="comment-body">
            <Box>
              {isEditing
                ? (
                <div className="comment-edit-form">
                  <TextField
                    fullWidth
                    multiline
                    variant="outlined"
                    size="small"
                    value={editContent}
                    onChange={(e) => setEditContent(e.target.value)}
                    className="edit-input"
                  />
                  <div style={{ display: 'flex', gap: '0.25rem' }}>
                    <Button
                      onClick={() => handleEditComment(comment.id, editContent)}
                      className="comment-edit-form-button"
                      variant="primary"
                      size="sm"
                    >
                      Save
                    </Button>
                    <Button
                      className="comment-edit-form-cancel-button"
                      variant="ghost"
                      size="sm"
                      onClick={() => setEditingCommentId(null)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
                  )
                : (
                <Box
                  sx={{ width: '100%', maxWidth: '100%', overflow: 'hidden' }}
                >
                  <ContentDisplay content={comment.content} />
                </Box>
                  )}
            </Box>
            <CommentReactions
              comment={comment}
              currentOrganizationRoleId={currentOrganizationRole?.id}
              onReaction={handleReact}
              onReply={handleReplyingTo}
            />
          </Box>
        </Box>
        <Box className="comment-replies">
          {isExpanded && (
            <div
              style={{
                position: 'relative',
                marginLeft: '1.5rem',
                marginBottom: '1rem',
                marginTop: '1rem'
              }}
            >
              <TextField
                fullWidth
                multiline
                variant="outlined"
                placeholder="Post your reply"
                value={replyContents[comment.id] || ''}
                onChange={(e) =>
                  setReplyContents((prev) => ({
                    ...prev,
                    [comment.id]: e.target.value
                  }))
                }
                className="new-comment-input"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
                    void handleReplySubmit(comment.id)
                  }
                }}
              />
              <Button
                onClick={() => handleReplySubmit(comment.id)}
                className="submit-comment-button secondary"
                isDisabled={!replyContents[comment.id]?.trim()}
                size="sm"
              >
                Reply
              </Button>
            </div>
          )}
          {isExpanded && comment.replies && comment.replies.length > 0 && (
            <Box className="comment-replies-list">
              {comment.replies
                .sort(
                  (a, b) =>
                    new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime()
                )
                .map((reply) => renderComment(reply, depth + 1))}
            </Box>
          )}
        </Box>
      </Box>
    )
  }

  return (
    <Box className="comments-display">
      <div style={{ position: 'relative' }}>
        <TextField
          fullWidth
          variant="outlined"
          multiline
          placeholder="Post your reply"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          className="new-comment-input"
          onKeyDown={(e) => {
            if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
              void handleCommentSubmit()
            }
          }}
        />
        <Button
          onClick={handleCommentSubmit}
          className="submit-comment-button secondary"
          isDisabled={newComment.trim() === ''}
          size="sm"
        >
          Reply
        </Button>
      </div>
      <div className="comments-list">
        {comments
          ?.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
          .map((comment) => renderComment(comment))}
        {(!comments || comments?.length === 0) && (
          <div style={{ marginTop: '-0.5rem', marginBottom: '1rem' }}>
            No replies...yet!
          </div>
        )}
      </div>
    </Box>
  )
}

export default CommentsDisplay
