import AccordionDetails from '@mui/material/AccordionDetails'
import './index.css'

interface CheckinDisplayProps {
  formattedCheckins: any
  handleChange: (checkin: any) => void
  readOnly?: boolean
  reviewing?: boolean
  editAttendance?: boolean
}

function CheckinDisplay ({ formattedCheckins, handleChange, readOnly, reviewing, editAttendance }: CheckinDisplayProps) {
  const scaleRange = ['Stuggling', 'Poor', 'Fair', 'Good', 'Great']
  const radioRange = ['1', '2', '3', '4', '5']

  const organizationCheckinDisplay = formattedCheckins?.map(checkin => {
    if (checkin.responseType === 'TEXT') {
      return (
        <AccordionDetails key={checkin?.checkinId}>
          <div >
            <label>{checkin.description}</label>
            <input
              type="text"
              placeholder='Response...'
              value={checkin.response}
              onChange={(e) => handleChange({ id: checkin?.checkinId, response: e.target.value })}
            />
          </div>
        </AccordionDetails>
      )
    }
    if (checkin.responseType === 'SCALE') {
      return (
        <AccordionDetails key={checkin?.checkinId}>
          <div>
            <label>{checkin.description}</label>
            <div className="scale-range-container">
              {scaleRange?.map((option, index) => {
                return (
                  <div className={checkin.response === `${index + 1}` ? 'scale-item-selected' : 'scale-item'} key={index} onClick={() => handleChange({ id: checkin?.checkinId, response: `${index + 1}` })}>{option}</div>
                )
              })}
            </div>
          </div>
        </AccordionDetails>
      )
    }
    if (checkin.responseType === 'RADIO') {
      return (
        <AccordionDetails key={checkin?.checkinId}>
          <div>
            <label>{checkin.description}</label>
            <div className="radio-range-container">
              {readOnly && !editAttendance
                ? <div>{checkin?.response || 'Not Submitted'}</div>
                : radioRange?.map((range, index) => {
                  return (
                    <div key={index}>
                      <label className="radio-label">{range}</label>
                      <input
                        key={range}
                        name={`checkin-${checkin?.checkinId}`}
                        type="radio"
                        value={range}
                        checked={checkin.response === range}
                        onChange={(e) => handleChange({ id: checkin?.checkinId, response: e.target.value })}
                      />
                    </div>
                  )
                })}
            </div>
          </div>
        </AccordionDetails>
      )
    }
    return null
  })

  const reviewDisplay = formattedCheckins?.map((data, index) => {
    return (
      <div key={index} className="response-container-item">
        <AccordionDetails>
          <label className="description">{data?.description}</label>
          <div className="response">{data?.response || 'Not Submitted'}</div>
        </AccordionDetails>
      </div>
    )
  })

  return (
    <>
      {!reviewing && <div>
        {organizationCheckinDisplay}
      </div>}
      {reviewing && <div>
        {reviewDisplay}
      </div>}
    </>
  )
}

export default CheckinDisplay
