import { useSpacesQuery } from '../../shared/queryHooks'
import { useAuthContext } from '../../context/AuthContext'
import { logError } from '../../shared/logger'
import LoadingScreen from '../LoadingScreen'

function SpaceDropdown ({ setAction, value }) {
  const removeNullValue = value === null ? '' : value
  const { currentUser } = useAuthContext()
  const { isLoading, isError, error, data: spaces } = useSpacesQuery(currentUser?.currentOrganizationId ?? 0, { enabled: !!currentUser, filter: 'where-member' })

  if (isLoading) {
    return (
    <>
      <LoadingScreen />
    </>
    )
  }

  if (isError) {
    logError(error)
  }

  return (
    <select value={removeNullValue} onChange={(e) => setAction(prev => ({ ...prev, spaceId: e.target.value }))}>
      <option value=''>Select Space...</option>
      {spaces?.map(space => (
            <option key={space?.id} value={space?.id}>{space?.name}</option>
      ))}
    </select>
  )
}

export default SpaceDropdown
