import { useState, useRef } from 'react'
import { MenuItem, Checkbox, ListItemText } from '@mui/material'
import { useOutsideAlerter } from '../../shared/utilities'
import './index.css'

interface FilterEventsProps {
  tags?: string[]
  handleSort?: (selectedTags: string[]) => void
}

function FilterEvents ({ tags, handleSort }: FilterEventsProps) {
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [displayTagSortDropDown, setDisplayTagSortDropDown] = useState(false)
  const wrapperRef = useRef(null)
  useOutsideAlerter({ ref: wrapperRef, setDisplayState: setDisplayTagSortDropDown })

  const handleChange = (value) => {
    const updatedSelectedTags = selectedTags?.includes(value) ? selectedTags?.filter((tag) => tag !== value) : [...selectedTags, value]
    setSelectedTags(updatedSelectedTags)
    if (handleSort !== undefined) {
      handleSort(updatedSelectedTags)
    }
  }

  return (
    <div ref={wrapperRef} style={{ position: 'relative' }}>
      <div
        className="filter-events-container"
        onClick={() => setDisplayTagSortDropDown(!displayTagSortDropDown)}>
        Filter By Tags
      </div>
      {displayTagSortDropDown && <div className="event-tag-dropdown">
        {tags?.map((tag) => (
          <MenuItem key={tag} value={tag} onClick={() => handleChange(tag)}>
            <Checkbox checked={selectedTags?.some((selectedTag) => selectedTag === tag)} value={tag} className='filter-checkbox' />
            <ListItemText primary={tag} />
          </MenuItem>
        ))}
      </div>}
    </div>
  )
}

export default FilterEvents
