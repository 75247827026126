import './index.css'

const Register = () => {
  return (
    <div className="register-container-wrapper">
      <div className="register-container">
        <div className="title">Welcome!</div>
        <div className='register-container-text'>Current ConnectBetter is by invitation only.</div>
        <div className='register-container-text'>Please check back later.</div>
      </div>
    </div>
  )
}

export default Register
