import FormatBoldRoundedIcon from '@mui/icons-material/FormatBoldRounded'
import FormatItalicRoundedIcon from '@mui/icons-material/FormatItalicRounded'
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded'
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded'
import FormatUnderlinedRoundedIcon from '@mui/icons-material/FormatUnderlinedRounded'
import { EditorContent } from '@tiptap/react'
import { useAuthContext } from '../../../context/AuthContext'
import { getCurrentOrganizationRole } from '../../../shared/utilities'
import { useEffectOnce } from '../../../shared/hooks'
import { getOrganizationPolicy } from '../../../shared/policies'
import { isAuthorized } from '../../../shared/permissions'
import Addon from '../Addon'
import './index.css'
import { Connection } from '../../../types'

interface EditorAddonProps {
  type: string
  pinned: boolean
  setAsPinned: () => void
  editor: any
  connection: Connection
}

function EditorAddon ({ type, pinned, setAsPinned, editor, connection }: EditorAddonProps) {
  const { currentUser } = useAuthContext()
  const currentUserOrgRole = getCurrentOrganizationRole(currentUser)
  const isMember = isAuthorized(getOrganizationPolicy(currentUser?.currentOrganizationId), currentUser)

  const titleMap = {
    notes: 'Notes'
  }

  const title = titleMap[type]

  useEffectOnce(() => {
    editor?.commands?.updateUser({
      name: `${currentUser?.firstName} ${currentUser?.lastName}`,
      color: '#FFAE10',
      organizationRoleId: currentUserOrgRole?.id,
      uid: connection?.uid
    })
    editor?.setEditable(!connection.endTime)
  })

  const toolbar = (
    <div className="toolbar">
      {isMember && <div className="toolbar-icons">
        <div className={`icon ${editor?.isActive('bold') ? 'active' : ''}`} onClick={() => editor.chain().focus().toggleBold().run()}><FormatBoldRoundedIcon /></div>
        <div className={`icon ${editor?.isActive('italic') ? 'active' : ''}`} onClick={() => editor.chain().focus().toggleItalic().run()}><FormatItalicRoundedIcon /></div>
        <div className={`icon ${editor?.isActive('underline') ? 'active' : ''}`} onClick={() => editor.chain().focus().toggleUnderline().run()}><FormatUnderlinedRoundedIcon style={{ marginBottom: '-0.05rem' }} /></div>
        <div className={`icon ${editor?.isActive('bulletList') ? 'active' : ''}`} onClick={() => editor.chain().focus().toggleBulletList().run()} style={{ marginLeft: '0.5rem' }}><FormatListBulletedRoundedIcon /></div>
        <div className={`icon ${editor?.isActive('orderedList') ? 'active' : ''}`} onClick={() => editor.chain().focus().toggleOrderedList().run()}><FormatListNumberedRoundedIcon /></div>
      </div>}
    </div>
  )

  return (
    <Addon title={title} className="notes" footer={toolbar} pinned={pinned} setAsPinned={setAsPinned}>
      <EditorContent className="editor" editor={editor} />
    </Addon>
  )
}

export default EditorAddon
