import { Outlet } from 'react-router-dom'
import OrganizationSettingsNav from '../../components/OrganizationSettingsNav'
import useLayoutDetector from '../../shared/useLayoutDetector'
import TrialEndingBanner from '../../components/TrialEndingBanner'
import './index.css'

function OrganizationSettingsLayout ({
  currentUser,
  displayTrialBanner,
  setDisplayTrialBanner
}) {
  useLayoutDetector()

  return (
    <div className="app app-settings">
      <TrialEndingBanner
        currentUser={currentUser}
        displayTrialBanner={displayTrialBanner}
        setDisplayTrialBanner={setDisplayTrialBanner}
      />
      <OrganizationSettingsNav />
      <div className="app-content app-settings-content">
        <Outlet />
      </div>
    </div>
  )
}

export default OrganizationSettingsLayout
