import {
  getSpaceLeaderPolicy,
  getOrganizationAdminPolicy
} from '../../shared/policies'
import { useAuthContext } from '../../context/AuthContext'
import { isAuthorized } from '../../shared/permissions'
import CheckIcon from '@mui/icons-material/CheckRounded'
import {
  getCurrentOrganization,
  getCurrentOrganizationRole
} from '../../shared/utilities'
import { rolesDisplayMap } from '../../shared/enums'
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded'
import { Chip } from '@mui/material'
import Avatar from '../Avatar'
import { OrganizationRole } from '../../types'
import { parseDate } from '../../shared/dates'
import './index.css'
import { AccessTime } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useModal } from '../../context/ModalContext'
interface PublicOrganizationProfileProps {
  person: OrganizationRole;
  spaceId?: number;
}

function PublicOrganizationProfile ({ person, spaceId }: PublicOrganizationProfileProps) {
  const navigate = useNavigate()
  const { clearModal } = useModal()
  const { currentUser } = useAuthContext()
  const currentOrganization = getCurrentOrganization(currentUser)
  const currentOrganizationRole = getCurrentOrganizationRole(currentUser)
  const spaceLeaderRoles = currentOrganizationRole?.spaceRoles?.filter(
    (role) => role.title === 'LEADER'
  )
  const isSpaceLeaderOfPerson = spaceLeaderRoles?.some((role) =>
    person?.spaceRoles?.some(
      (personRole) => personRole.spaceId === role.spaceId
    )
  )
  const isSpaceLeaderOrAbove = isAuthorized(
    getSpaceLeaderPolicy(spaceId, currentUser?.currentOrganizationId),
    currentUser
  )
  const hasContactAccess =
    spaceId !== null || spaceId !== undefined
      ? isSpaceLeaderOfPerson
      : isSpaceLeaderOrAbove

  const isAdminOrOwner = isAuthorized(
    getOrganizationAdminPolicy(currentUser?.currentOrganizationId),
    currentUser
  )

  const activeChip =
    person?.status === 'ACTIVE'
      ? { icon: <CheckIcon />, label: 'Active' }
      : { label: 'Inactive' }

  function formatCreatedAt (createdAt?: string | null) {
    if (createdAt) {
      return `Date Joined ${parseDate(createdAt)?.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      })}`
    }
  }
  const email = person?.user?.email || person?.contact?.email

  const personFieldName = [
    {
      title: <MailOutlineRoundedIcon />,
      testid: 'person-email-field',
      metaData: person?.user?.shareData && email && <a href={`mailto:${email}`}>{email}</a>
    },
    {
      title: <AccessTime />,
      testid: 'person-created-field',
      metaData: formatCreatedAt(person?.user?.createdAt || person?.contact?.createdAt)
    }
  ]

  const personFields = personFieldName.filter(field => field.metaData).map((field, index) => (
    <div
      className="organization-profile-field"
      key={index}
      data-testid={field.testid}
    >
      {field.title}
      <div className="organization-profile-field-metadata">
        {field.metaData}
      </div>
    </div>
  ))

  function handleManagePerson () {
    clearModal()
    navigate(`/communities/settings/people/${person?.id}`)
  }

  return (
    <div className="organization-profile-wrapper" data-testid="profile-wrapper">
      <div className="organization-profile-header">
        <Avatar
          className="organization-profile-avatar"
          firstName={person?.contact?.firstName}
          lastName={person?.contact?.lastName}
          avatar={person?.user?.avatar}
        />
      </div>
      <div
        className="organization-profile-name-field"
        data-testid="organization-profile-name-field"
      >
        {person?.contact?.firstName} {person?.contact?.lastName}
      </div>
      <Chip
        {...activeChip}
        size="small"
        variant="outlined"
        sx={{ marginRight: '0.5rem' }}
      />
      <Chip
        label={rolesDisplayMap[person?.title]}
        size="small"
        variant="outlined"
      />
      {(isAdminOrOwner ||
        (hasContactAccess &&
          (currentOrganization?.shareContactData ||
            person?.user?.shareData))) && (
        <>
          <hr />
          <div>{personFields}</div>
          {isAdminOrOwner && <div className="button" style={{ marginTop: '1rem' }} onClick={() => handleManagePerson()}>Manage Person</div>}
        </>
      )}
    </div>
  )
}

export default PublicOrganizationProfile
