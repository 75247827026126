import { Download } from '@mui/icons-material'
import { FreedomBuck } from '../../types'
import Button from '../Button'
import { getFileDate, makeFreedomBuckCsvRows } from './utils'

const downloadAsCsv = (freedomBucks) => {
  const headerRow = ['Recipient', 'Spaces']
  const csvRows = [headerRow, ...makeFreedomBuckCsvRows(freedomBucks)]
  const csvContent = csvRows.map(e => e?.join(',')).join('\n')
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  const url = URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.href = url
  link.download = `${getFileDate()}_weekly_freedom_bucks_report.csv`
  link.target = '_blank'
  link.click()
}

export const DownloadWeeklyReportCsv = ({
  freedomBucks
}: {
  freedomBucks: FreedomBuck[];
}) => {
  const combineSpacesFreedomBucks = freedomBucks.map((freedomBuck) => {
    return {
      name: freedomBuck?.name ?? '',
      spaces: Array.isArray(freedomBuck?.spaces) ? freedomBuck.spaces.join(', ') : ''
    }
  })

  return (
    <Button className='csv-download' secondary size="sm" onClick={() => downloadAsCsv(combineSpacesFreedomBucks)}>
      <Download fontSize="small" /> Download CSV
    </Button>
  )
}
