import { useState, createContext, useContext, useCallback } from 'react'
import CloseIcon from '@mui/icons-material/CloseRounded'

interface ErrorProps {
  message?: string
  action?: () => void
  disableTimer?: boolean
  buttonText?: string
}

interface ErrorContextProps {
  createError: (props: ErrorProps) => void
}

export const ErrorContext = createContext<ErrorContextProps>({
  createError: () => {}
})

const ErrorProvider = (props) => {
  const [errorProps, setErrorProps] = useState<ErrorProps>({})
  const [timeOutId, setTimeOutId] = useState<NodeJS.Timeout | null>(null)

  const handleTimer = () => {
    if (timeOutId) {
      clearTimeout(timeOutId)
    }
    const newTimerId = setTimeout(unSetError, 5000)
    setTimeOutId(newTimerId)
  }

  const createError = (props) => {
    setErrorProps({
      message: props?.message || 'Something went wrong',
      action: props?.action,
      disableTimer: props?.disableTimer || false,
      buttonText: props?.buttonText || 'Retry'
    })
    if (!props.disableTimer) {
      handleTimer()
    }
  }

  const unSetError = useCallback(() => {
    setErrorProps({})
  }, [setErrorProps])

  const handleRetry = () => {
    if (errorProps.action) errorProps.action()
    if (timeOutId) {
      clearTimeout(timeOutId)
    }
    unSetError()
  }

  return (
    <ErrorContext.Provider value={{ createError }}>
      {props?.children}
      {errorProps?.message &&
        <div className='error-message-outer'>
            <CloseIcon className="error-close-button" onClick={unSetError}/>
            {errorProps?.message}
            {errorProps?.action &&
              <button
                className='error-button button'
                onClick={handleRetry}>
                  {errorProps?.buttonText}
              </button>
            }
        </div>
      }
    </ErrorContext.Provider>
  )
}

export const useErrorContext = () => useContext(ErrorContext)

export default ErrorProvider
