import { useParams, useSearchParams } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthContext'
import SecondaryNavBar from '../../components/SecondaryNavBar'
import ListExercises from '../../components/ListExercises'
import './index.css'

function PersonCourseProgress () {
  const { currentUser } = useAuthContext()
  const { id, courseVersionId } = useParams()

  const [searchParams] = useSearchParams()
  const spaceId = searchParams.get('spaceId') ?? undefined
  const secondaryNavNavigation = spaceId ? `/communities/settings/people/${id}/?spaceId=${spaceId}` : `/communities/settings/people/${id}`

  return (
    <>
      <SecondaryNavBar title="Person" backTo={secondaryNavNavigation}/>
      <div className="exercise-list-container">
        <ListExercises currentUser={currentUser} organizationRoleId={parseInt(id ?? '0')} courseVersionId={courseVersionId ? parseInt(courseVersionId) : undefined} readOnly={true}/>
      </div>
    </>
  )
}

export default PersonCourseProgress
