import { OrganizationRole } from '../../types'
import PublicOrganizationProfile from '../../components/PublicOrganizationProfile'

interface ViewOrganizationProfileProps {
  person: OrganizationRole
}

function ViewOrganizationProfile ({ person }: ViewOrganizationProfileProps) {
  return (
    <div className="modal-content">
      <PublicOrganizationProfile person={person}/>
    </div>
  )
}

export default ViewOrganizationProfile
