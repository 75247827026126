import { config } from './config'
import { loadStripe } from '@stripe/stripe-js'

async function setupStripe () {
  try {
    return loadStripe(config.STRIPE_API_KEY)
  } catch (error) {
    console.error(error)
  }
}

const stripeElementsOptions = {
  fonts: [
    { cssSrc: 'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap' }
  ]
}

export {
  setupStripe,
  stripeElementsOptions
}
