import { Event as EventType } from '../../types/DataTypes'
import {
  formatConnectionDate,
  formatConnectionTime,
  isVirtualMeeting
} from '../../shared/utilities'
import { useAuthContext } from '../../context/AuthContext'
import { isAuthorized } from '../../shared/permissions'
import { getSpaceLeaderPolicy } from '../../shared/policies'
import ArchiveEvent from '../ArchiveEvent'
import PlaceIcon from '@mui/icons-material/Place'
import GridViewRounded from '@mui/icons-material/GridViewRounded'
import VideocamIcon from '@mui/icons-material/Videocam'
import GoogleMeetIcon from '../../assets/google-meet.svg'
import ZoomIcon from '../../assets/zoom.svg'
import Button from '../Button'
import { useNavigate } from 'react-router-dom'
import { useJoinEventMutation } from '../../shared/queryHooks'
interface EventProps {
  event: EventType | null;
  isActive: boolean;
  showSettings: boolean;
  showSpaceBadge: boolean;
  onEventClick: (event: EventType) => void;
}

function Event ({
  event,
  isActive,
  showSettings,
  showSpaceBadge
}: EventProps) {
  const { currentUser } = useAuthContext()
  const navigate = useNavigate()
  const joinEvent = useJoinEventMutation()

  function handleSubmit () {
    joinEvent.mutate({ eventUid: event?.uid })
    navigate(`/connections/${event?.uid}`)
  }

  const getLocationInfo = (location: string) => {
    if (!location) {
      return {
        icon: (
          <div style={{ marginBottom: '-0.3rem', marginLeft: '-0.1rem' }}>
            <VideocamIcon />
          </div>
        ),
        text: 'Virtual Meeting'
      }
    }

    const lowercaseLocation = location.toLowerCase()

    if (lowercaseLocation.includes('meet.google.com')) {
      return {
        icon: (
          <img src={GoogleMeetIcon} alt="Google Meet" className="event-icon" />
        ),
        text: 'Google Meet'
      }
    }

    if (lowercaseLocation.includes('zoom.us')) {
      return {
        icon: <img src={ZoomIcon} alt="Zoom" className="event-icon" />,
        text: 'Zoom'
      }
    }

    if (isVirtualMeeting(location)) {
      return {
        icon: (
          <div style={{ marginBottom: '-0.3rem', marginLeft: '-0.1rem' }}>
            <VideocamIcon />
          </div>
        ),
        text: 'Virtual Meeting'
      }
    }

    return {
      icon: (
        <div style={{ marginBottom: '-0.3rem', marginLeft: '-0.3rem' }}>
          <PlaceIcon />
        </div>
      ),
      text: location
    }
  }

  const hasTitle = (event: EventType) => {
    return event.title && event.title.trim() !== ''
  }

  const locationInfo = getLocationInfo(
    event?.location || event?.space?.location || ''
  )

  const isHostAndAbove = isAuthorized(
    getSpaceLeaderPolicy(event?.spaceId, currentUser?.currentOrganizationId),
    currentUser
  )

  const handleSpaceClick = () => {
    if (!location.pathname.includes('spaces')) {
      navigate(`/spaces/${event?.spaceId}`)
    }
  }

  if (!event) return null

  return (
    <div className="event-item">
      <div style={{ width: '100%' }}>
        <div
          className="event-meta"
          style={{
            gap: '0.25rem',
            fontSize: '0.85rem',
            marginBottom: '0.5rem'
          }}
        >
          <div className="event-meta-item">
            {formatConnectionDate(event.startTime)}
          </div>
          <div className="meta-separator">•</div>
          <div className="event-meta-item">
            {formatConnectionTime(event.startTime)}
          </div>
        </div>
        <div className="event-info">
          {isActive && <div className="connection-live">Live</div>}
          <div className="event-title">
            {hasTitle(event) ? event.title : 'Event'}
          </div>
          <div className="event-buttons">
            {isActive
              ? (
              <Button onClick={handleSubmit}>Join</Button>
                )
              : (
              <Button className="secondary" onClick={handleSubmit}>
                View
              </Button>
                )}
            {isHostAndAbove && showSettings && (
              <ArchiveEvent uid={event.uid ?? ''} />
            )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '0.5rem',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div className="event-meta">
            <div className="event-location">
              <div>{locationInfo.icon}</div>
              <div>{locationInfo.text}</div>
            </div>
          </div>
          {showSpaceBadge && (
            <div className="event-space" onClick={handleSpaceClick}>
              <GridViewRounded
                fontSize="small"
                style={{ fontSize: '0.8rem', marginRight: '0.25rem' }}
              />
              {event.space?.name}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Event
