import { Download } from '@mui/icons-material'
import { Connection } from '../../types'
import Button from '../Button'
import { getFileDate, makeAttendanceCsvRows } from './utils'

const downloadAsCsv = (connections: Connection[]) => {
  const headerRow = ['Space Name', 'Attendee Name', 'Connected On']
  const csvRows = [headerRow, ...makeAttendanceCsvRows(connections)]
  const csvContent = csvRows.map(e => e?.join(',')).join('\n')
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  const url = URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.href = url
  link.download = `${getFileDate()}_weekly_attendance_report.csv`
  link.target = '_blank'
  link.click()
}

export const DownloadWeeklyReportCsv = ({
  connections
}: {
  connections: Connection[];
}) => {
  return (
    <Button className='csv-download' secondary size="sm" onClick={() => downloadAsCsv(connections)}>
      <Download fontSize="small" /> Download CSV
    </Button>
  )
}
