import { getSpaceLeaderPolicy } from '../../shared/policies'
import { useAuthContext } from '../../context/AuthContext'
import { isAuthorized } from '../../shared/permissions'
import SpaceMembersDisplay from '../../components/SpaceMembersDisplay'
import ManageSpaceSettings from '../../components/ManageSpaceSettings'
import ManageActivities from '../../components/ManageActivites'
import ManageAbout from '../../components/ManageAbout'
import { useState } from 'react'
import { Space } from '../../types'
import './index.css'

interface PersonSubNavProps {
  space: Space;
  featureTypes: {
    feed: string;
    events: string;
    courses: string;
    media: string;
  };
  setActiveSpaceFeature: (feature: string) => void;
}

function ManageSpace ({
  space,
  featureTypes,
  setActiveSpaceFeature
}: PersonSubNavProps) {
  const { currentUser } = useAuthContext()
  const isHostAndAbove = isAuthorized(
    getSpaceLeaderPolicy(space.id, currentUser?.currentOrganizationId),
    currentUser
  )

  const subNavKey = {
    about: 'about',
    members: 'members',
    activities: 'activities',
    settings: 'settings'
  }

  const [selectedNav, setSelectedNav] = useState<string>(subNavKey.members)

  const subNavHeaderContent = [
    {
      title: 'About',
      action: () => setSelectedNav(subNavKey.about),
      key: subNavKey.about
    },
    {
      title: `Members (${space?.spaceRoles?.length})`,
      action: () => setSelectedNav(subNavKey.members),
      key: subNavKey.members
    },
    {
      title: 'Features',
      action: () => setSelectedNav(subNavKey.activities),
      key: subNavKey.activities
    },
    {
      title: 'Settings',
      action: () => setSelectedNav(subNavKey.settings),
      key: subNavKey.settings
    }
  ]

  const subNavHeaders = subNavHeaderContent.map((header) => {
    if (
      !isHostAndAbove &&
      (header.key === subNavKey.settings ||
        header.key === subNavKey.activities ||
        header.key === subNavKey.about)
    ) {
      return null
    }

    return (
      <div
        key={header.key}
        className={
          selectedNav === header.key
            ? 'organization-profile-subnav-header-selected'
            : 'organization-profile-subnav-header-item'
        }
        onClick={header.action}
      >
        {header.title}
      </div>
    )
  })

  return (
    <div className="organization-profile-subnav-wrapper">
      <div className="space-subnav-headers">{subNavHeaders}</div>
      {selectedNav === subNavKey.about && <ManageAbout existingSpace={space} />}
      {selectedNav === subNavKey.members && (
        <SpaceMembersDisplay space={space} />
      )}
      {selectedNav === subNavKey.activities && (
        <ManageActivities
          space={space}
          setActiveSpaceFeature={setActiveSpaceFeature}
          featureTypes={featureTypes}
        />
      )}
      {isHostAndAbove && selectedNav === subNavKey.settings && (
        <ManageSpaceSettings
          existingSpace={space}
          featureTypes={featureTypes}
          setActiveSpaceFeature={setActiveSpaceFeature}
          openedFromAdmin
        />
      )}
    </div>
  )
}

export default ManageSpace
