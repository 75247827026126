import {
  sortSpaceRolesByFirstName,
  getCurrentOrganization
} from '../../shared/utilities'
import SpaceUserItem from '../SpaceUserItem'
import { useAuthContext } from '../../context/AuthContext'
import AddOrEditPerson from '../../modals/AddOrEditPerson'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { useModal } from '../../context/ModalContext'
import { useUpdateSpaceSpaceRolesMutation } from '../../shared/queryHooks'
import { useState, useEffect } from 'react'
import './index.css'
import { Space, SpaceRole, SpaceTitle } from '../../types'
import { PuffLoader } from 'react-spinners'

function SpaceMembersDisplay ({ space }: { space: Space }) {
  const { makeModal, clearModal } = useModal()
  const { currentUser } = useAuthContext()
  const currentOrganization = getCurrentOrganization(currentUser)
  const updateSpaceRoles = useUpdateSpaceSpaceRolesMutation()
  const [currentSpaceRoles, setCurrentSpaceRoles] = useState<SpaceRole[]>([])

  useEffect(() => {
    setCurrentSpaceRoles(space?.spaceRoles?.sort(sortSpaceRolesByFirstName) || [])
  }, [space])

  function selectRole ({ title, spaceRoleId }: { title: SpaceTitle, spaceRoleId: number }) {
    setCurrentSpaceRoles(currentSpaceRoles.map((spaceRole) => {
      if (spaceRole?.id === spaceRoleId) {
        return { ...spaceRole, title }
      }
      return spaceRole
    }))
  }

  function handleSave () {
    updateSpaceRoles.mutate({ spaceId: space.id, spaceRoles: currentSpaceRoles })
    clearModal()
  }

  return (
    <>
      <div className="space-members-container">
        {currentOrganization?.membersCanInvite && (
          <div
            className="invite-member-button"
            onClick={() =>
              makeModal({
                modal: <AddOrEditPerson spaceId={space.id} memberInviteOnly={true} />,
                title: 'Invite Member'
              })
            }
          >
            <PersonAddIcon />
            Invite
          </div>
        )}
        <div className="space-members-list">
          {currentSpaceRoles.map((spaceRole) => (
            <SpaceUserItem
              key={spaceRole?.organizationRoleId}
              spaceRole={spaceRole}
              selectRole={selectRole}
            />
          ))}
        </div>
      </div>
      <div className="modal-footer">
        <div className="button" onClick={handleSave}>{updateSpaceRoles.isLoading ? <PuffLoader /> : 'Save'}</div>
      </div>
    </>
  )
}

export default SpaceMembersDisplay
