import { useModal } from '../../context/ModalContext'
import DeleteConnection from '../../modals/DeleteConnection'
import Button from '../Button'

interface ArchiveEventProps {
  uid: string;
}

function ArchiveEvent ({ uid }: ArchiveEventProps) {
  const { makeModal } = useModal()

  const handleArchiveConnection = () => {
    makeModal({
      modal: <DeleteConnection uid={uid} />,
      title: 'Archive Event'
    })
  }

  return (
    <Button className="secondary" onClick={handleArchiveConnection}>
      Archive
    </Button>
  )
}

export default ArchiveEvent
