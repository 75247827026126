import { useAuthContext } from '../../context/AuthContext'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import './index.css'

interface PlaylistItemSelectorProps {
  playlist: any
  activePlaylistItemId: string
  setActivePlaylistItemId: (item: string) => void
  publisherName: string
  type: 'courses' | 'media'
  spaceId: number
}

function PlaylistItemSelector ({ playlist, activePlaylistItemId, setActivePlaylistItemId, publisherName, type, spaceId }: PlaylistItemSelectorProps) {
  const { currentUser } = useAuthContext()

  const handleSelectPlaylistItem = (itemId: string) => {
    const lastActiveContent = `${type === 'courses' ? 'lastActiveCourse' : 'lastActiveMedia'}_${currentUser?.currentOrganizationId}_space_${spaceId}`
    localStorage.setItem(lastActiveContent, itemId)
    setActivePlaylistItemId(itemId)
  }

  return (
    <div className="playlist-selector">
      {playlist.map((item: any) => {
        return (
          <div
            className={`playlist-card 
              ${activePlaylistItemId === item.id ? 'active' : ''} `}
            key={item.id}
            style={item?.image ? { backgroundImage: `url(${item.image})` } : {}}
            onClick={() => handleSelectPlaylistItem(item.id)}
          >
            <div>
              <div style={{ display: 'flex' }}>
                <div className="playlist-item-logo">{item.name[0]}</div>
                <div>
                  <div className="playlist-item-name">
                    {item.name}
                  </div>
                  <div className="publisher-name">{publisherName}</div>
                </div>
              </div>
              {type === 'courses' && (
                <div className="program-subscribed">
                  <CheckRoundedIcon /> Subscribed v{item.courseVersion?.[0]?.version}.0.0
                </div>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default PlaylistItemSelector
