import { useEditPeopleStatusMutation } from '../../shared/queryHooks'
import { useModal } from '../../context/ModalContext'
import './index.css'
import { PuffLoader } from 'react-spinners'
import { OrganizationRole } from '../../types'
import { toast } from 'sonner'

interface PeopleStatusesProps {
  organizationRoles: OrganizationRole[]
  activate: boolean
}

function ManagePeopleStatuses ({ organizationRoles, activate }: PeopleStatusesProps) {
  const { clearModal } = useModal()

  const editPeopleStatus = useEditPeopleStatusMutation()

  const updatedStatus = activate ? 'ACTIVE' : 'INACTIVE'
  const actionStatus = activate ? 'Activate' : 'Deactivate'

  const handleSave = async () => {
    try {
      await editPeopleStatus.mutateAsync({
        organizationRoles,
        status: updatedStatus
      })
      toast.success(`People ${actionStatus.toLowerCase()}d successfully.`)
    } catch (error) {
      toast.error('An error occurred. Please try again.')
    }
  }

  return (
  <>
    {activate
      ? <div className="person-settings-modal-content">
          <p>Are you sure you want to activate <span className="modal-username">{organizationRoles.length} {organizationRoles.length > 1 ? 'people' : 'person'}</span>?</p>
        </div>
      : <div className="person-settings-modal-content">
          <p>Are you sure you want to deactivate <span className="modal-username">{organizationRoles.length} {organizationRoles.length > 1 ? 'people' : 'person'}</span>?</p>
          <p>If you change your mind, you can reactivate them at any time.</p>
        </div>
    }
    <div className="modal-footer">
      <div className="button secondary" onClick={() => clearModal()}>Cancel</div>
      <div data-testid="toggle-activation-button" className={`button ${!activate ? 'danger' : ''}`} onClick={handleSave}>{editPeopleStatus.isLoading ? <PuffLoader color='#fff' size={21} /> : actionStatus}</div>
    </div>
  </>
  )
}

export default ManagePeopleStatuses
