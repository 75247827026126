import { PropsOf } from '@emotion/react'
import ColorHash from 'color-hash'
import './index.css'

interface AvatarProps {
  firstName?: string | null
  lastName?: string | null
  fullName?: string | null
  avatar?: string | null
  asBackground?: boolean
}

function stringToColor (str: string): string {
  const colorHash = new ColorHash({ lightness: 0.5, saturation: 0.3, hue: { min: 50, max: 360 } })
  return colorHash.hex(str)
}

function Avatar ({
  firstName = '',
  lastName = '',
  fullName = '',
  avatar,
  asBackground,
  ...props
}: AvatarProps & PropsOf<'div'>) {
  return (
    <>
      {avatar
        ? (
        <div {...props}>
          {asBackground
            ? (
            <div
              style={{
                backgroundImage: `url(${avatar})`,
                width: '100%',
                height: '100%',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
              }}
            />
              )
            : (
            <img className="image" src={avatar} alt="user-avatar" data-testid="profile-settings-avatar" />
              )}
        </div>
          )
        : (
        <div
          {...props}
          data-testid="profile-settings-default-avatar"
          style={{ backgroundColor: stringToColor(`${fullName?.[0] || firstName?.[0]}${lastName?.[0]}`), textTransform: 'uppercase' }}
        >
          {fullName?.[0] || firstName?.[0]}
          {lastName?.[0]}
        </div>
          )}
    </>
  )
}

export default Avatar
