function postAvatarUrl ({ avatarUploadUrl, editor }) {
  const croppedImageCanvas = editor.current.getImage()

  croppedImageCanvas.toBlob(async (blob) => {
    const url = URL.createObjectURL(blob)
    const image = await fetch(url)

    await fetch(avatarUploadUrl, {
      method: 'put',
      body: await image.blob()
    })
  })
}

export {
  postAvatarUrl
}
