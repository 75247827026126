import EmptyHero from '../../components/EmptyHero'
import SecondaryNavBar from '../../components/SecondaryNavBar'
import insightsIllustration from '../../assets/insights.svg'
import { useNavigate } from 'react-router-dom'

function Insights () {
  const navigate = useNavigate()

  const action = [{
    onClick: () => navigate('/'),
    actionTitle: 'View Spaces'
  }]

  return (
    <>
      <SecondaryNavBar title="Insights"/>
      <EmptyHero
          image={insightsIllustration}
          title="Insights"
          data-testid="insights-empty-hero"
          action={action}
          description="You don't have any insights yet. Once you've had some activity in your spaces, analytics will appear here." />
    </>
  )
}

export default Insights
