import { useState, useEffect } from 'react'
import useDebounce from '../../shared/useDebounce'

function SearchInput ({ onSearchChanged, placeholder, defaultValue = '' }) {
  const [searchText, setSearchText] = useState(defaultValue)
  const debouncerDelay = 350
  const debouncedSearchInput = useDebounce(searchText, debouncerDelay)

  useEffect(() => {
    onSearchChanged(searchText)
  }, [debouncedSearchInput])

  const onTextChange = (e) => {
    setSearchText(e.target.value)
  }

  return (<input
    value={searchText}
    onChange={onTextChange}
    placeholder={placeholder}
  />)
}

export default SearchInput
