import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink
} from '@react-pdf/renderer'
import Button from '../Button'
import { Download } from '@mui/icons-material'
import { getFileDate } from './utils'
import { ReportAchievement } from '../../types'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 30
  },
  metricsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    gap: 10
  },
  metricContainer: {
    flexDirection: 'column',
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    flex: 1,
    borderColor: '#ccc'
  },
  metric: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 5
  },
  metricLabel: {
    fontSize: 12,
    color: 'gray'
  },
  reportTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 5
  },
  reportTimePeriod: {
    fontSize: 14,
    color: 'gray',
    marginBottom: 20
  },
  achievement: {
    marginBottom: 15
  },
  userName: {
    fontSize: 12,
    marginBottom: 5
  },
  normal: {
    fontSize: 12,
    marginBottom: 5
  },
  achievementData: {
    fontSize: 11,
    color: 'gray',
    marginBottom: 5
  },
  attendee: {
    marginLeft: 10,
    fontSize: 12,
    marginBottom: 5
  },
  achievementMetaContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    marginBottom: 10
  }
})

type AchievementReport = {
  name: string;
  achievementName: string;
  space: string;
};

const makeAchievementReport = (achievements: ReportAchievement[]): AchievementReport[] => {
  return achievements.map((achievement) => {
    return {
      name: achievement?.userName ?? '',
      achievementName: achievement?.achievementName ?? '',
      space: achievement?.spaceName ?? ''
    }
  })
}

interface ReportProps {
  achievements: ReportAchievement[];
  timePeriod?: string;
}

const ReportAsPdf = ({
  achievements,
  timePeriod
}: ReportProps) => {
  const achievementsData = makeAchievementReport(achievements)
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.reportTitle}>Weekly Achievement Report</Text>
        <Text style={styles.reportTimePeriod}>{timePeriod}</Text>

        <View style={styles.metricsContainer}>
          <View style={styles.metricContainer}>
            <Text style={styles.metric}>{achievements?.length ?? 0}</Text>
            <Text style={styles.metricLabel}>Total Achievements</Text>
          </View>
        </View>

        {achievementsData.map((achievement, index) => achievement !== null
          ? (
          <View key={index} style={styles.achievement}>
            <View style={styles.achievementMetaContainer}>
              <Text style={styles.userName}>{achievement.name}</Text>
              <Text style={styles.achievementData}>{achievement.achievementName}</Text>
              <Text style={styles.achievementData}>{achievement.space}</Text>
            </View>
          </View>
            )
          : null)}
      </Page>
    </Document>
  )
}

export const DownloadWeeklyReportPdf = (props: ReportProps) => {
  return (
    <PDFDownloadLink
      document={ <ReportAsPdf {...props} /> }
      fileName={`${getFileDate()}_weekly_achievements_report.pdf`}
    >
      {({ loading }) =>
        loading
          ? null
          : (
          <Button secondary size="sm">
            <Download fontSize="small" /> Download PDF
          </Button>
            )
      }
    </PDFDownloadLink>
  )
}
