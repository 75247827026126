import { Space } from '../../types'
import ManageSpaceSettings from '../../components/ManageSpaceSettings'

interface CreateOrEditSpaceProps {
  existingSpace?: Space | null;
  openedFromAdmin?: boolean;
}

function CreateOrEditSpace ({
  existingSpace,
  openedFromAdmin
}: CreateOrEditSpaceProps) {
  const featureTypes = {
    feed: 'feed',
    events: 'events',
    courses: 'courses',
    media: 'media'
  }

  return (
    <ManageSpaceSettings
      existingSpace={existingSpace}
      openedFromAdmin={openedFromAdmin}
      featureTypes={featureTypes}
    />
  )
}

export default CreateOrEditSpace
