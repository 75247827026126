import { sortOrgPlaylists } from '../../shared/utilities'
import './index.css'

function SpacePlaylistMediaDropdown ({ orgPlaylists, setAttributeHandling, value }) {
  const removeNullValue = value === null ? '' : value

  return (
    <select value={removeNullValue} onChange={(e) => setAttributeHandling({ value: e.target.value, attribute: 'programVersionMediaId' })}>
      <option value=''>Select Media...</option>
      {sortOrgPlaylists(orgPlaylists)
        ?.filter(playlist => {
          return playlist?.enabled === true
        })
        ?.map((playlist) => {
          const media = playlist?.programVersion?.program?.media || []
          const displayName = media.length > 1
            ? `${playlist?.programVersion?.program?.name} v${playlist?.programVersion?.version}.0.0 (Playlist)`
            : `${media[0]?.name}`

          return (
            <option
              key={playlist?.programVersion?.id}
              value={playlist?.programVersion?.id}
            >
              {displayName}
            </option>
          )
        })}
    </select>
  )
}

export default SpacePlaylistMediaDropdown
