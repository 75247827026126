import { useState } from 'react'
import Card from '../Card'
import './index.css'
import ManageUserSpaceRoles from '../ManageUserSpaceRoles'
import { OrganizationRole, Space, SpaceRole } from '../../types'
import { DisplayState } from '../../shared/utilities'
import { useUpdateOrganizationCourseAccessMutation } from '../../shared/queryHooks'
import { useAuthContext } from '../../context/AuthContext'
import Info from '@mui/icons-material/Info'

interface ManageSpaceCardProps {
  space: Space
  setUserSpaces: DisplayState<SpaceRole[]>
  userSpaces: SpaceRole[]
  organizationRoleId: number
  person: OrganizationRole
}

function ManageSpaceCard ({ space, setUserSpaces, userSpaces, organizationRoleId, person }: ManageSpaceCardProps) {
  const currentRole = userSpaces?.find((role) => role.spaceId === space.id) ?? {
    id: 0,
    spaceId: space.id,
    organizationRoleId,
    title: 'MEMBER'
  }
  const { currentUser } = useAuthContext()
  const [spaceRole, setSpaceRole] = useState<SpaceRole>(currentRole)
  const updatePersonCourseAccess = useUpdateOrganizationCourseAccessMutation()
  const [showAddToCourse, setShowAddToCourse] = useState<boolean>(false)

  function handleToggle (spaceId) {
    if (userSpaces?.some((role) => role.spaceId === parseInt(spaceId))) {
      const newSpaceRoleList = userSpaces.filter((role) => role.spaceId !== parseInt(spaceId))
      setUserSpaces(newSpaceRoleList)
    } else {
      setUserSpaces((userSpaces) => [...userSpaces, spaceRole])
    }
  }

  function selectRole (title) {
    setSpaceRole((previousRole) => ({ ...previousRole, title }))
    setUserSpaces((previousRoles) =>
      previousRoles.map((role) => (role.spaceId === space.id ? { ...role, title } : role))
    )
  }
  const isSpaceMember = userSpaces?.some((spaceRole) => spaceRole?.spaceId === space?.id)
  const hasSpaceCourse = !!space?.courseVersionId
  const courseName = hasSpaceCourse ? space?.courseVersion?.course?.name : ''
  const hasCourseAccess =
    hasSpaceCourse &&
    JSON?.parse(space?.courseVersion?.organizationCourse?.[0]?.organizationRoleAccess ?? '[]').some(
      (role) => role?.organizationRoleId === organizationRoleId && role?.access === true
    )
  const hasSpaceRoleLimit: boolean = person?.title === 'GUEST' && userSpaces.length > 0

  const addToCourse = async () => {
    const orgCourse = space?.courseVersion?.organizationCourse?.find(
      (op) => op?.organizationId === currentUser?.currentOrganizationId
    )
    if (!orgCourse) return
    const orgRoleAccess = JSON.parse(orgCourse.organizationRoleAccess ?? '{}')
    const personAccess = orgRoleAccess?.find((role) => role?.organizationRoleId === person?.id)
    if (personAccess) {
      personAccess.access = true
    } else {
      orgRoleAccess.push({ organizationRoleId: person?.id, access: true })
    }
    orgCourse.organizationRoleAccess = JSON.stringify(orgRoleAccess)
    await updatePersonCourseAccess.mutateAsync({
      organizationId: currentUser?.currentOrganizationId,
      body: space?.courseVersion?.organizationCourse
    })
    setShowAddToCourse(false)
  }

  const rightColumnContent = () => {
    if (isSpaceMember) {
      return <ManageUserSpaceRoles selectRole={selectRole} spaceRole={spaceRole} person={person} />
    } else if (hasSpaceRoleLimit) {
      return <div className="cannot-add-message">Guest cannot be added</div>
    } else if (hasSpaceCourse && !hasCourseAccess) {
      return (
        <>
          <div className="cannot-add-message" onClick={() => setShowAddToCourse(!showAddToCourse)}>
            No course access <Info fontSize="small" />
          </div>
        </>
      )
    } else {
      return <></>
    }
  }

  return (
    <>
      <div className="space-card-container-modal" data-testid={`space-card-space-${space.id}`}>
        <label className="inner-space-card-container-modal">
          {!((hasSpaceCourse && !hasCourseAccess) || hasSpaceRoleLimit) || isSpaceMember
            ? (
            <input
              value={space.id}
              onChange={(e) => handleToggle(e.target.value)}
              type="checkbox"
              className={'space-checkbox'}
              checked={isSpaceMember}
              data-testid={`space-id-${space.id}`}
            />
              )
            : (
            <div className="space-checkbox-spacer"></div>
              )}
          <div className="card-wrapper">
            <Card name={space.name} peopleCount={space?.spaceRoles?.length ?? 0} courseName={courseName} />
          </div>
        </label>
        {rightColumnContent()}
      </div>

      {showAddToCourse && (
        <div className="action-items">
          This user does not have access to the course tied to this space. If you add them to the space they will
          automatically be given access to the course.
          <span className="action-link" onClick={addToCourse}>
            Add them now?
          </span>
        </div>
      )}
    </>
  )
}

export default ManageSpaceCard
