import { useDeleteOrganizationGoalMutation } from '../../shared/queryHooks'
import { PuffLoader } from 'react-spinners'
import './index.css'
import { Entity } from '../../shared/enums'

function DeleteGoal ({ goalId, title, clearModal, setGoal }) {
  const deleteGoal = useDeleteOrganizationGoalMutation()

  const handleDelete = () => {
    deleteGoal.mutate({ goalId })
    setGoal(null)
    clearModal()
  }

  return (
    <>
      <div className="delete-title">Are you Sure you want to delete: {title}?</div>
      <div>This goal will no longer be available for viewing to anyone inside your {Entity.Community} and cannot be recovered.</div>
      <div className="modal-footer">
        <div className="button secondary" onClick={() => clearModal()}>Cancel</div>
        <div className="button danger" onClick={() => handleDelete()}>{deleteGoal?.isLoading ? <PuffLoader color="#fff" size={21} /> : 'Delete'}</div>
      </div>
    </>
  )
}

export default DeleteGoal
