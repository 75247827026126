import { NavLink } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthContext'
import './index.css'
import Avatar from '../Avatar'
import { useActionsQuery } from '../../shared/queryHooks'
import { getCurrentOrganizationRole, sortByActionType } from '../../shared/utilities'
import { parseDateTime } from '../../shared/dates'
import { addWeeks, startOfWeek } from 'date-fns'

interface ProfileSelectionMenuProps {
  variant: 'public' | 'private';
}

export function ProfileSelectionMenu ({ variant }: ProfileSelectionMenuProps) {
  const { currentUser } = useAuthContext()

  const organizationRoleId = getCurrentOrganizationRole(currentUser)?.id
  const {
    data: userActions
  } = useActionsQuery(organizationRoleId ?? 0, undefined, {
    enabled: !!currentUser && !!organizationRoleId
  })

  const startOfNextWeek = addWeeks(startOfWeek(new Date()), 1)
  const uncompletedActions = userActions?.actions?.filter((action) => action?.status === 'UNCOMPLETED')
  const nowActions = uncompletedActions
    ?.filter((action) => (parseDateTime(action?.dueBy) < startOfNextWeek.getTime()))
    .sort(sortByActionType)

  const hasActions = (nowActions?.length ?? 0) > 0

  return (
    <div
      style={{ width: '100%' }}
      className={variant === 'public' ? 'public' : ''}
    >
      <NavLink className="nav-title nav-profile" to="/profile/settings">
        <div className="avatar-container">
          <Avatar
            className="nav-avatar"
            data-testid="navbar-avatar"
            firstName={currentUser?.firstName}
            lastName={currentUser?.lastName}
            avatar={currentUser?.avatar}
          />
        </div>
        {variant === 'private' && (
          <div className="nav-profile-name" data-testid="navbar-username">
            {currentUser?.firstName}
          </div>
        )}
        {hasActions && (
          <span className="notification-badge">
            {nowActions?.length ?? 0}
          </span>
        )}
      </NavLink>
    </div>
  )
}
