import { getCurrentOrganizationRole } from './utilities'
import { Policy } from './policies'
import { User } from '../types'

const isAuthorized = (policy: Policy, currentUser?: User | null): boolean => {
  if (policy === undefined || currentUser === undefined) {
    return false
  }
  const currentOrgRole = getCurrentOrganizationRole(currentUser)

  const isAuthorizedOrganizationRole = currentUser?.organizationRoles?.some(role =>
    policy.organization?.id === role.organization?.id &&
    policy.organization?.roles.includes(role.title) &&
    role.status === 'ACTIVE') ?? false

  const isAuthorizedSpaceRole = currentOrgRole
    ? currentOrgRole?.spaceRoles?.some(role =>
      policy.space?.id === role.spaceId &&
      policy.space?.roles.includes(role.title)) ?? false
    : false

  const hasOrganizationRole = (currentUser?.organizationRoles?.some(role =>
    policy.organization?.roles.includes(role.title) &&
    role.status === 'ACTIVE') ?? false) &&
    policy.organization?.id === undefined

  const hasSpaceRole = currentOrgRole
    ? (currentOrgRole?.spaceRoles?.some(role =>
        policy.space?.roles.includes(role.title)) ?? false) &&
    policy.space?.id === undefined
    : false

  const isAuthorized =
    isAuthorizedOrganizationRole ||
    isAuthorizedSpaceRole ||
    hasOrganizationRole ||
    hasSpaceRole
  return isAuthorized
}

export { isAuthorized }
