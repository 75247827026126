import LoadingScreen from '../LoadingScreen'
import './index.css'

function ConnectionUserDropdown ({ activePeople, value, onChange }) {
  if (activePeople) {
    return (
      <select value={value} onChange={onChange}>
        {activePeople.map((person) => (
          <option
          key={person.organizationRoleId}
          value={person.organizationRoleId}>
            {person.contact?.firstName} {person.contact?.lastName}
            </option>
        ))}
      </select>
    )
  }
  return (<LoadingScreen />)
}

export default ConnectionUserDropdown
