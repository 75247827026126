import { useOrganizationUserCourseQuery } from '../../shared/queryHooks'
import { useAuthContext } from '../../context/AuthContext'
import { logError } from '../../shared/logger'
import { getCurrentOrganizationRole } from '../../shared/utilities'
import SecondaryNavBar from '../../components/SecondaryNavBar'
import ExerciseProgressDisplay from '../../components/ExerciseProgressDisplayCard'
import EmptyHero from '../../components/EmptyHero'
import programsIllustration from '../../assets/programs.svg'
import './index.css'
import { Entity } from '../../shared/enums'

function Pathway () {
  const { currentUser } = useAuthContext()
  const currentOrganizationRoleId = (getCurrentOrganizationRole(currentUser))?.id ?? 0

  const { isLoading, isError, data: userCourses, error } = useOrganizationUserCourseQuery(currentOrganizationRoleId, { enabled: !!currentUser })
  if (isLoading) {
    return null
  }

  if (isError) {
    logError(error)
  }

  return (
    <>
      <SecondaryNavBar title="Courses" />
      <div className="pathway-container">
        {userCourses && <ExerciseProgressDisplay userId={currentUser?.id ?? 0} id={currentOrganizationRoleId} userCourses={userCourses}/>}
      </div>
      {!userCourses || userCourses.length < 1
        ? <>
            <EmptyHero
              image={programsIllustration}
              title="Courses"
              description={`You current have no courses with this ${Entity.Community}. Create or request to join a space with a course to begin your learning journey!`}
            />
          </>
        : <></>}
    </>
  )
}

export default Pathway
