interface FormattedFreedomBuck {
  name: string;
  spaces: string;

}

export const getFileDate = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const day = ('0' + date.getDate()).slice(-2)
  return '' + year + month + day
}

export const makeFreedomBuckCsvRows = (freedomBucks: FormattedFreedomBuck[]): string[][] => {
  return (
    freedomBucks.map((freedomBuck) =>
      makeFreedomBuckCsvRow(freedomBuck)
    ) ?? []
  )
}

const makeFreedomBuckCsvRow = (
  freedomBuck: FormattedFreedomBuck
): string[] => {
  return [
    freedomBuck.name,
    `"${freedomBuck.spaces}                                                          "`
  ]
}
