import {
  useCreateConnectMutation,
  useQueryClient,
  useUpdateConnectionMutation
} from '../../shared/queryHooks'
import { useState } from 'react'
import { useModal } from '../../context/ModalContext'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Connection, Space } from '../../types'
import { parseDefaultedDate } from '../../shared/dates'
import { Entity } from '../../shared/enums'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import './index.css'

interface StartOrScheduleConnectionProps {
  space?: Space | null;
  connection?: Connection;
  tags?: any[];
  spaces?: Space[];
  isEvent?: boolean;
}

interface ScheduleConnection {
  meetingLocation: string;
  connectionStartTime: Date | null;
  connectionTags: string[];
  connectionType: string;
  title: string;
  spaceId: number | undefined;
  postEvent: boolean;
}

function StartOrScheduleConnection ({
  space,
  connection,
  tags,
  spaces
}: StartOrScheduleConnectionProps) {
  const { clearModal } = useModal()
  const queryClient = useQueryClient()
  const createConnect = useCreateConnectMutation()
  const updateConnect = useUpdateConnectionMutation()

  const connectionTypes = {
    SECRET: 'Secret',
    PUBLIC: 'Public'
  }

  const [errorMessage, setErrorMessage] = useState('')
  const [scheduleConnection, setScheduleConnection] =
    useState<ScheduleConnection>({
      meetingLocation: space?.location || connection?.location || '',
      connectionStartTime: parseDefaultedDate(connection?.startTime),
      connectionTags:
        connection?.connectionTags?.map(
          (connectionTag) => connectionTag?.tag?.name ?? ''
        ) || [],
      connectionType:
        connection?.connectionType || connectionTypes.SECRET.toUpperCase(),
      title: connection?.title || '',
      spaceId: space?.id || connection?.spaceId || spaces?.[0]?.id,
      postEvent: true
    })

  const connectTypeButtonText = 'Schedule'

  function handleChange<K extends keyof ScheduleConnection> (
    key: K,
    value: ScheduleConnection[K]
  ) {
    setScheduleConnection((prevData) => ({
      ...prevData,
      [key]: value
    }))
    setErrorMessage('')
  }

  async function handleStartOrSchedule () {
    if (scheduleConnection.connectionStartTime === null) {
      setErrorMessage('Please select a start time for the connection.')
      return
    }

    if (!scheduleConnection.spaceId) {
      setErrorMessage('Please select a space for the connection.')
      return
    }

    if (
      scheduleConnection.title.trim() === '' &&
      scheduleConnection.connectionType !== connectionTypes.SECRET.toUpperCase()
    ) {
      setErrorMessage('Please enter a title for the connection.')
      return
    }

    if (connection) {
      const updatedScheduleConnection = {
        location: scheduleConnection.meetingLocation,
        startTime: scheduleConnection.connectionStartTime,
        title: scheduleConnection.title,
        connectionTags: scheduleConnection.connectionTags,
        connectionType: scheduleConnection.connectionType,
        connectionUid: connection?.uid ?? '',
        postEvent: scheduleConnection.postEvent
      }
      await updateConnect.mutateAsync(updatedScheduleConnection)
      await handleNavigation()
    } else {
      const createdScheduleConnection = {
        location: scheduleConnection.meetingLocation,
        startTime: scheduleConnection.connectionStartTime,
        title: scheduleConnection.title,
        connectionTags: scheduleConnection.connectionTags,
        connectionType: scheduleConnection.connectionType,
        spaceId: scheduleConnection.spaceId,
        postEvent: scheduleConnection.postEvent
      }
      await createConnect.mutateAsync(createdScheduleConnection)
      await handleNavigation()
    }
  }

  async function handleNavigation () {
    await queryClient.invalidateQueries(['spaces'])
    clearModal()
  }

  return (
    <>
      {errorMessage && (
        <div className="create-connection-error">{errorMessage}</div>
      )}

      <div className="connect-metaData">
        <label>Name</label>
        <input
          value={scheduleConnection.title}
          onChange={(e) => handleChange('title', e.target.value)}
          placeholder="Event name"
        />
      </div>
      <div className="connect-metaData">
        <label>{Entity.Event} start time</label>
        <DatePicker
          selected={scheduleConnection.connectionStartTime}
          placeholderText="Schedule date and time..."
          onChange={(date) => handleChange('connectionStartTime', date)}
          showTimeSelect
          showMonthDropdown
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="MMMM d, yyyy h:mm aa"
          minDate={new Date()}
        />
      </div>
      <div className="connect-metaData">
        <label>Where are you meeting?</label>
        <input
          value={scheduleConnection.meetingLocation}
          onChange={(e) => handleChange('meetingLocation', e.target.value)}
          placeholder="Location, video call link, etc..."
        />
      </div>
      {spaces && !connection && (
        <div className="connect-metaData">
          <label>Associate with {Entity.Space}</label>
          <select
            value={scheduleConnection.spaceId}
            onChange={(e) => handleChange('spaceId', parseInt(e.target.value))}
          >
            {spaces.map((space) => (
              <option key={space.id} value={space.id}>
                {space.name}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className="connect-metaData">
        <label>Event privacy</label>
        <select
          value={scheduleConnection.connectionType}
          onChange={(e) => handleChange('connectionType', e.target.value)}
        >
          {Object.keys(connectionTypes).map((type) => (
            <option key={type} value={type}>
              {connectionTypes[type]}
            </option>
          ))}
        </select>
      </div>
      <div className="connect-metaData">
        <Autocomplete
          multiple
          id="tags-standard"
          options={
            Array.isArray(tags)
              ? tags.map((eventTag) => eventTag?.tag?.name ?? '')
              : []
          }
          freeSolo
          value={scheduleConnection.connectionTags}
          onChange={(event, newValue) => {
            handleChange('connectionTags', newValue)
          }}
          renderInput={(params) => (
            <TextField {...params} variant="standard" placeholder="Tags..." />
          )}
        />
      </div>
      <div className="connect-metaData">
        <label>Post Event</label>
        <Switch
          checked={scheduleConnection.postEvent}
          onChange={(e) => handleChange('postEvent', e.target.checked)}
        />
      </div>
      <div className="modal-footer">
        <div className="button secondary" onClick={clearModal}>
          Cancel
        </div>
        <div
          className="button"
          data-testid="modal-end-connection-button"
          onClick={handleStartOrSchedule}
        >
          {connectTypeButtonText}
        </div>
      </div>
    </>
  )
}

export default StartOrScheduleConnection
