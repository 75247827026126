import { useEffect, useState } from 'react'
import { getSpaceLeaderPolicy } from '../../../shared/policies'
import { isAuthorized } from '../../../shared/permissions'
import { getCurrentOrganizationRole } from '../../../shared/utilities'
import ConnectionUserDropdown from '../../../components/ConnectionUserDropdown'
import ListExercises from '../../../components/ListExercises'
import Addon from '../Addon'
import './index.css'
import { Connection, SpaceRole, User } from '../../../types'
import { getActiveCourseVersionId } from '../../../shared/api'
import { useQueryClient } from '@tanstack/react-query'
import LoadingScreen from '../../../components/LoadingScreen'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

interface CourseAddonProps {
  pinned: boolean
  setAsPinned: () => void
  connection: Connection
  currentUser?: User | null
  editCourse: boolean
}

function CourseAddon ({ pinned, setAsPinned, connection, currentUser, editCourse } : CourseAddonProps) {
  const [dropdownValueUserId, setDropdownValueUserId] = useState<number | null>(null)
  const [activeCourseVersionId, setActiveCourseVersionId] = useState<number>()
  const [activeCourseVersionName, setActiveCourseVersionName] = useState<string>()
  const [courseVersions, setCourseVersions] = useState<{ id: number, name: string }[] | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const currentUserOrgRole = getCurrentOrganizationRole(currentUser)
  const isSpaceLeaderOrAbove = isAuthorized(getSpaceLeaderPolicy(connection?.spaceId, currentUser?.currentOrganizationId), currentUser)

  const queryClient = useQueryClient()

  useEffect(() => {
    if (connection?.space?.spaceRoles && connection?.space?.spaceRoles?.length > 0) {
      setDropdownValueUserId(connection?.space?.spaceRoles[0].organizationRoleId)
      setIsLoading(true)
    }
  }, [])

  useEffect(() => {
    const fetchActiveCourseVersionId = async () => {
      const data = await queryClient.fetchQuery(
        [`active_course_version_id:${dropdownValueUserId}:${connection.spaceId}`],
        () => getActiveCourseVersionId(dropdownValueUserId ?? 0, connection.spaceId)
      )

      setActiveCourseVersionId(data.activeCourseVersionId ?? data.courses[0]?.id)
      setActiveCourseVersionName(data.courses?.find((item: any) => item?.id === activeCourseVersionId)?.name ?? data.courses[0]?.name)
      setCourseVersions(data.courses)
      setIsLoading(false)
    }

    if (dropdownValueUserId) {
      void fetchActiveCourseVersionId()
    }
  }, [dropdownValueUserId])

  const handleChangeDropdown = (event) => {
    setDropdownValueUserId(parseInt(event.target.value))
  }

  const getPeople = (spaceRoles?: SpaceRole[] | null) => {
    const people = spaceRoles?.map(spaceRole => {
      return {
        contact: spaceRole?.organizationRole?.contact,
        organizationRoleId: spaceRole?.organizationRoleId
      }
    })
    return people
  }

  const filterForSpaceMembers = getPeople(connection?.space?.spaceRoles)

  const sortedPeople = filterForSpaceMembers?.sort((a, b) => (
    (`${a.contact?.firstName} ${a.contact?.lastName}`)
      .localeCompare(`${b.contact?.firstName} ${b.contact?.lastName}`)
  ))

  const handleNext = () => {
    const currentIndex = courseVersions?.findIndex((item) => item.id === activeCourseVersionId)
    if (currentIndex !== undefined && currentIndex < (courseVersions?.length ?? 0) - 1) {
      setActiveCourseVersionId(courseVersions?.[currentIndex + 1].id)
      setActiveCourseVersionName(courseVersions?.[currentIndex + 1].name)
    }
  }

  const handlePrevious = () => {
    const currentIndex = courseVersions?.findIndex((item) => item.id === activeCourseVersionId)
    if (currentIndex !== undefined && currentIndex > 0) {
      setActiveCourseVersionId(courseVersions?.[currentIndex - 1].id)
      setActiveCourseVersionName(courseVersions?.[currentIndex - 1].name)
    }
  }

  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <Addon title={connection?.space?.courseVersion?.course?.name ?? ''} className="program" pinned={pinned} setAsPinned={setAsPinned}>
      {isSpaceLeaderOrAbove
        ? <div className="program-content">
            <ConnectionUserDropdown
              value={dropdownValueUserId}
              onChange={handleChangeDropdown}
              activePeople={sortedPeople}
            />
            {dropdownValueUserId && (
              <>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'space-between', marginBottom: '1rem' }}>
                  <div
                    className="course-toggle"
                    onClick={handlePrevious}
                    style={{ visibility: courseVersions?.findIndex((item) => item.id === activeCourseVersionId) === 0 ? 'hidden' : 'visible' }}
                  >
                    <ChevronLeftIcon />
                  </div>
                  <div className="course-name">{activeCourseVersionName}</div>
                  <div
                    className="course-toggle"
                    onClick={handleNext}
                    style={{ visibility: courseVersions?.findIndex((item) => item.id === activeCourseVersionId) === (courseVersions?.length ?? 0) - 1 ? 'hidden' : 'visible' }}
                  >
                    <ChevronRightIcon />
                  </div>
                </div>
                <ListExercises
                  organizationRoleId={dropdownValueUserId}
                  spaceId={connection.spaceId}
                  connectionId={connection.id}
                  courseVersionId={activeCourseVersionId}
                  currentUser={currentUser}
                  connectionUid={connection?.uid}
                  readOnly={!editCourse && connection?.endTime != null}
                  userActions = {connection?.spaceUserActions}
                />
              </>
            )}
          </div>
        : <div className="program-content">
            <div className="course-name">{activeCourseVersionName}</div>
            <ListExercises
              organizationRoleId={currentUserOrgRole?.id}
              spaceId={connection.spaceId}
              connectionId={connection.id}
              currentUser={currentUser}
              userId={currentUser?.id ?? 0}
              readOnly={connection?.endTime != null}
              userActions = {connection?.spaceUserActions}
            />
        </div>
      }
    </Addon>
  )
}

export default CourseAddon
