import { isYouTubeUrl, isVimeoUrl, getYouTubeVideoId, getVimeoVideoId, isValidVideoFileUrl } from '../../shared/utilities'

interface ExerciseVideoPlayerProps {
  url: string
  width?: string | number
  height?: string | number
  autoPlay?: boolean
}

function ExerciseVideoPlayer ({ url, width = '100%', height, autoPlay = false }: ExerciseVideoPlayerProps) {
  if (!url) return null

  if (isYouTubeUrl(url)) {
    return (
      <iframe
        width={width}
        height={height}
        src={`https://www.youtube.com/embed/${getYouTubeVideoId(url)}?autoplay=${autoPlay ? 1 : 0}`}
        allow="encrypted-media; autoplay"
        allowFullScreen
      />
    )
  }

  if (isVimeoUrl(url)) {
    return (
      <iframe
        width={width}
        height={height}
        src={`https://player.vimeo.com/video/${getVimeoVideoId(url)}?autoplay=${autoPlay ? 1 : 0}`}
        allow="fullscreen; autoplay"
        allowFullScreen
      />
    )
  }

  if (!isValidVideoFileUrl(url)) {
    return null
  }

  return (
    <video
      width={width}
      height={height}
      controls
      autoPlay={autoPlay}
      src={url}
    >
      Your browser does not support the video tag.
    </video>
  )
}

export default ExerciseVideoPlayer
