import { Connection } from '../../../types'
import Attendance from '../../../components/Attendance'
import Addon from '../Addon'
import './index.css'

interface AttendanceAddonProps {
  pinned: boolean
  connection: Connection
  setAsPinned: () => void
  editAttendance: boolean
}

function AttendanceAddon ({ pinned, connection, setAsPinned, editAttendance }: AttendanceAddonProps) {
  return (
    <Addon title="Attendance" className="attendance" pinned={pinned} setAsPinned={setAsPinned}>
      <Attendance space={connection.space} connection={connection} editAttendance={editAttendance}/>
    </Addon>
  )
}

export default AttendanceAddon
