import SecondaryNavBar from '../../../components/SecondaryNavBar'
import { logError } from '../../../shared/logger'
import { useSpacesQuery, useUpdateOrganizationMutation, useEditSpaceMutation } from '../../../shared/queryHooks'
import { getCurrentOrganization } from '../../../shared/utilities'
import { useAuthContext } from '../../../context/AuthContext'
import { useQueryClient } from '@tanstack/react-query'
import { PuffLoader } from 'react-spinners'
import { Switch } from '@mui/material'
import { parsedErrorMessage } from '../../../shared/errors'
import { useState, useEffect } from 'react'
import './index.css'
import { Entity } from '../../../shared/enums'

function OrganizationSettingsSpaces () {
  const { currentUser } = useAuthContext()
  const currentOrganization = getCurrentOrganization(currentUser)
  const queryClient = useQueryClient()
  const editOrganization = useUpdateOrganizationMutation()
  const editSpace = useEditSpaceMutation()

  const { isLoading, data: spaces, isError, error } = useSpacesQuery(currentUser?.currentOrganizationId ?? 0, {
    enabled: !!currentUser
  })

  const [workspaceDefaultSpace, setWorkspaceDefaultSpace] = useState(0)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [saveMessage, setSaveMessage] = useState<string>('')

  async function toggleSpaceSettings ({ hasAccess, settingAttribute }) {
    if (settingAttribute === 'memberSpaceCreation') {
      await editOrganization.mutateAsync({
        name: currentOrganization?.name ?? '',
        organizationId: currentUser?.currentOrganizationId ?? 0,
        memberSpaceCreation: hasAccess,
        logoUrl: currentOrganization?.logoUrl ?? '',
        dailyCheckin: currentOrganization?.dailyCheckin,
        dailyCheckinDaysOfWeek: currentOrganization?.dailyCheckinDaysOfWeek ?? '{}',
        editConnection: currentOrganization?.editConnection,
        defaultSpace: currentOrganization?.defaultSpace ?? false,
        shareContactData: currentOrganization?.shareContactData ?? false,
        membersCanInvite: currentOrganization?.membersCanInvite ?? false
      })
    }

    if (settingAttribute === 'editConnection') {
      await editOrganization.mutateAsync({
        name: currentOrganization?.name ?? '',
        organizationId: currentUser?.currentOrganizationId ?? 0,
        memberSpaceCreation: currentOrganization?.memberSpaceCreation ?? false,
        logoUrl: currentOrganization?.logoUrl ?? '',
        dailyCheckin: currentOrganization?.dailyCheckin,
        dailyCheckinDaysOfWeek: currentOrganization?.dailyCheckinDaysOfWeek ?? '{}',
        editConnection: hasAccess,
        defaultSpace: currentOrganization?.defaultSpace ?? false,
        shareContactData: currentOrganization?.shareContactData ?? false,
        membersCanInvite: currentOrganization?.membersCanInvite ?? false
      })
    }

    if (settingAttribute === 'defaultSpace') {
      await editOrganization.mutateAsync({
        name: currentOrganization?.name ?? '',
        organizationId: currentUser?.currentOrganizationId ?? 0,
        memberSpaceCreation: currentOrganization?.memberSpaceCreation ?? false,
        logoUrl: currentOrganization?.logoUrl ?? '',
        dailyCheckin: currentOrganization?.dailyCheckin,
        dailyCheckinDaysOfWeek: currentOrganization?.dailyCheckinDaysOfWeek ?? '{}',
        editConnection: currentOrganization?.editConnection,
        defaultSpace: hasAccess,
        shareContactData: currentOrganization?.shareContactData ?? false,
        membersCanInvite: currentOrganization?.membersCanInvite ?? false
      })
    }

    if (settingAttribute === 'membersCanInvite') {
      await editOrganization.mutateAsync({
        name: currentOrganization?.name ?? '',
        organizationId: currentUser?.currentOrganizationId ?? 0,
        memberSpaceCreation: currentOrganization?.memberSpaceCreation ?? false,
        logoUrl: currentOrganization?.logoUrl ?? '',
        dailyCheckin: currentOrganization?.dailyCheckin,
        dailyCheckinDaysOfWeek: currentOrganization?.dailyCheckinDaysOfWeek ?? '{}',
        editConnection: currentOrganization?.editConnection,
        defaultSpace: currentOrganization?.defaultSpace ?? false,
        shareContactData: currentOrganization?.shareContactData ?? false,
        membersCanInvite: hasAccess
      })
    }
    await queryClient.invalidateQueries({ queryKey: ['user'] })
  }

  async function updateSpaceToDefault () {
    const updatedDefaultSpace = spaces?.find(space => space?.id === workspaceDefaultSpace)
    try {
      await editSpace.mutateAsync({
        ...updatedDefaultSpace,
        defaultSpace: true
      })
      await queryClient.invalidateQueries()
      setSaveMessage('Your changes have been saved')
    } catch (error) {
      logError(error)
      setErrorMessage(parsedErrorMessage(error))
    }
  }

  useEffect(() => {
    const defaultSpace = spaces?.find(space => space?.defaultSpace)
    setWorkspaceDefaultSpace(defaultSpace?.id ?? 0)
  }, [spaces])

  if (isLoading) {
    return null
  }

  if (isError) {
    logError(error)
  }

  return (
    <>
      <SecondaryNavBar title="Spaces" />
      <div className="org-nav-settings">
        <div className="profile-setting-container edit-connection-toggle">
          <div className="component-toggle-container">
            <div className="spaces-toggle-label">Hosts can edit past {Entity.Event}s</div>
            {editOrganization.isLoading
              ? (
              <PuffLoader color="#fff" size={38} />
                )
              : (
              <Switch
                checked={currentOrganization?.editConnection}
                onChange={() =>
                  toggleSpaceSettings({
                    hasAccess: !currentOrganization?.editConnection,
                    settingAttribute: 'editConnection'
                  })
                }
              />)}
          </div>
        </div>
        <div className="profile-setting-container space-toggle-container">
          <div className="component-toggle-container">
            <div className="spaces-toggle-label">Members can create Spaces</div>
            {editOrganization.isLoading
              ? (
              <PuffLoader color="#fff" size={38} />
                )
              : (
              <Switch
                checked={currentOrganization?.memberSpaceCreation}
                onChange={() =>
                  toggleSpaceSettings({
                    hasAccess: !currentOrganization?.memberSpaceCreation,
                    settingAttribute: 'memberSpaceCreation'
                  })
                }
              />
                )}
          </div>
        </div>
        <div className="profile-setting-container space-toggle-container">
          <div className="component-toggle-container">
            <div className="spaces-toggle-label">Members can invite People</div>
            {editOrganization.isLoading
              ? (<PuffLoader color="#fff" size={38} />)
              : (
              <Switch
                checked={currentOrganization?.membersCanInvite}
                onChange={() =>
                  toggleSpaceSettings({
                    hasAccess: !currentOrganization?.membersCanInvite,
                    settingAttribute: 'membersCanInvite'
                  })
                }
              />
                )}
          </div>
        </div>
        <div className="profile-setting-container space-toggle-container">
          <div className="component-toggle-container">
            <div className="spaces-toggle-label">When inviting members, add them to a Space</div>
            {editOrganization.isLoading
              ? (<PuffLoader color="#fff" size={38} />)
              : (
              <Switch
                checked={currentOrganization?.defaultSpace}
                onChange={() =>
                  toggleSpaceSettings({
                    hasAccess: !currentOrganization?.defaultSpace,
                    settingAttribute: 'defaultSpace'
                  })
                }
              />
                )}
          </div>
        </div>
        <div>
        {currentOrganization?.defaultSpace && (
          <div className="profile-setting-container space-select-container">
            <h3>Which space would you like them added to?</h3>
            {errorMessage && <div className="edit-error">{errorMessage}</div>}
            {saveMessage && <div className="save-message">{saveMessage}</div>}
            <select value={workspaceDefaultSpace} onChange={(e) => setWorkspaceDefaultSpace(parseInt(e.target.value))}>
              <option value={0}>Select Space...</option>
              {spaces?.map(space => (
                <option key={space?.id} value={space?.id}>
                  {space?.name}
                </option>
              ))}
            </select>
            {editSpace.isLoading ? <PuffLoader color="#fff" size={38} /> : <div className={`button footer-button ${workspaceDefaultSpace === 0 ? 'disabled' : ''}`} data-testid="edit-save-button" onClick={() => updateSpaceToDefault()}>Save</div> }
          </div>
        )}
        </div>
      </div>
    </>
  )
}

export default OrganizationSettingsSpaces
