import LoadingScreen from '../../components/LoadingScreen'
import ManageSpaceCard from '../../components/ManageSpaceCard'
import { sortSpacesByMostPeople, getSortedContent } from '../../shared/utilities'
import { useEffect, useState } from 'react'
import { useModal } from '../../context/ModalContext'
import { useEditSpaceRolesMutation, useSpacesQuery, useEditContactSpaceRolesMutation } from '../../shared/queryHooks'
import { useAuthContext } from '../../context/AuthContext'
import './index.css'
import { OrganizationRole, Space } from '../../types'
import { Entity } from '../../shared/enums'

interface ManageUserSpacesProps {
  person: OrganizationRole
  isEditingContact?: boolean
}

function ManageUserSpaces ({ person, isEditingContact = false }: ManageUserSpacesProps) {
  useEffect(() => {
    setTitle(`${person?.contact?.firstName}'s Spaces`)
  }, [])

  const { setTitle } = useModal()
  const { currentUser } = useAuthContext()

  const [userSpaces, setUserSpaces] = useState(person?.spaceRoles ?? [])
  const [spaceSearch, setSpaceSearch] = useState('')

  const editSpaceRoles = useEditSpaceRolesMutation()
  const editContactSpaceRoles = useEditContactSpaceRolesMutation()

  const { isLoading, data: unsortedSpaces } = useSpacesQuery(currentUser?.currentOrganizationId ?? 0, {
    enabled: !!currentUser
  })

  function handleSave () {
    if (isEditingContact) {
      editContactSpaceRoles.mutate({
        organizationRoleId: person?.id,
        spaceRoles: userSpaces,
        organizationId: person?.organizationId
      })
    } else {
      editSpaceRoles.mutate({
        organizationRoleId: person?.id,
        spaceRoles: userSpaces,
        organizationId: person?.organizationId
      })
    }
  }

  if (isLoading) {
    return (
      <div>
        <p>Add to {Entity.Space}s</p>
        <div className="modal-header search-bar-container">
          <input
            type="text"
            placeholder="Search..."
            className="search-bar"
            value={spaceSearch}
            onChange={(e) => setSpaceSearch(e.target.value)}
          />
        </div>
        <div className="manage-user-spaces-results">
          <LoadingScreen />
        </div>
        <div className="modal-footer">
          <button className="button" onClick={handleSave}>
            Invite
          </button>
        </div>
      </div>
    )
  }

  const spaces = unsortedSpaces?.sort(sortSpacesByMostPeople)

  const spaceDisplay = (space: Space) => {
    return (
      <div key={space.id}>
        <ManageSpaceCard
          userSpaces={userSpaces}
          setUserSpaces={setUserSpaces}
          space={space}
          organizationRoleId={person?.id}
          person={person}
        />
      </div>
    )
  }

  const spaceCards = spaces
    ?.filter((space) => userSpaces?.some((spaceRole) => spaceRole?.spaceId === space?.id))
    .map((space) => {
      return spaceDisplay(space)
    })

  return (
    <div>
      <p>Add to {Entity.Space}s</p>
      {spaces?.length
        ? (
        <>
          <div className="modal-header search-bar-container" style={{ padding: 0 }}>
            <input
              type="text"
              placeholder="Search..."
              className="search-bar"
              value={spaceSearch}
              onChange={(e) => setSpaceSearch(e.target.value)}
            />
          </div>
          <div className="manage-user-spaces-results">
            {spaceSearch ? getSortedContent(spaceSearch, spaceDisplay, spaces) : spaceCards}
          </div>
        </>
          )
        : (
        <div>No available spaces</div>
          )}
      <div className="modal-footer">
        <button className="button" data-testid="save-button" onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  )
}

export default ManageUserSpaces
