import { PuffLoader } from 'react-spinners'
import { Connection } from '../../types'
import { useModal } from '../../context/ModalContext'
import { useUpdateConnectionMutation } from '../../shared/queryHooks'
import { useState } from 'react'

function EditConnectionTitle ({ connection }: { connection: Connection }) {
  const { clearModal } = useModal()
  const [title, setTitle] = useState(connection.title || '')

  const saveConnection = useUpdateConnectionMutation()

  function handleSave () {
    const updatedConnection = {
      location: connection.location || '',
      startTime: connection.startTime ? new Date(connection.startTime) : new Date(),
      connectionUid: connection?.uid ?? '',
      title,
      connectionTags: connection?.connectionTags?.map(ct => ct?.tag?.name),
      connectionType: connection.connectionType,
      postEvent: connection.postEvent
    }

    saveConnection.mutate(updatedConnection)
    clearModal()
  }

  return (
    <>
      <div>What would you like to call this event?</div>
        <div>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="modal-footer">
          <div className="button secondary" onClick={() => clearModal()}>
            Cancel
          </div>
          <div className="button" onClick={() => handleSave()}>
            {saveConnection?.isLoading
              ? (
              <PuffLoader color="#fff" size={21} />
                )
              : (
                  'Save'
                )}
        </div>
      </div>
    </>
  )
}

export default EditConnectionTitle
