import { Box, Typography, TextField } from '@mui/material'
import { Post as PostData, ReactionType } from '../../types'
import {
  formatTimeAgo,
  getCurrentOrganizationRole
} from '../../shared/utilities'
import CommentsDisplay from '../CommentsDisplay'
import { useState } from 'react'
import { useAuthContext } from '../../context/AuthContext'
import {
  useReactToPostMutation,
  useUpdatePostMutation
} from '../../shared/queryHooks'
import ContentDisplay from '../ContentDisplay'
import Avatar from '../Avatar'
import EventPostCard from '../EventPostCard'
import './index.css'
import PostActions from './PostActions'
import PostReactions from './PostReactions'
import Button from '../Button'
import { useModal } from '../../context/ModalContext'
import ViewOrganizationProfile from '../../modals/ViewOrganizationProfile'

function Post ({
  post,
  liveEvents,
  showSpaceBadge = true
}: {
  post: PostData;
  liveEvents: string[];
  showSpaceBadge?: boolean;
}) {
  const { currentUser } = useAuthContext()
  const { makeModal } = useModal()
  const currentOrganizationRole = getCurrentOrganizationRole(currentUser)
  const [showComments, setShowComments] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [editContent, setEditContent] = useState(post.content)

  const reactToPost = useReactToPostMutation()
  const updatePost = useUpdatePostMutation()

  async function handleReaction (type: ReactionType) {
    await reactToPost.mutateAsync({ id: post.id, type })
  }

  async function handleEditPost () {
    await updatePost.mutateAsync({ id: post.id, content: editContent })
    setIsEditing(false)
  }

  const displayPerson = (person) => {
    makeModal({
      modal: <ViewOrganizationProfile person={person} />,
      title: `${person?.contact?.firstName}'s Profile`,
      modalBackgroundClose: true,
      slideOut: true
    })
  }

  return (
    <div className="post-feed_post">
      <Avatar
        onClick={() => displayPerson(post.organizationRole)}
        className="post-avatar"
        firstName={post.organizationRole?.contact?.firstName}
        lastName={post.organizationRole?.contact?.lastName}
        avatar={post.organizationRole?.user?.avatar}
      />
      <div className="post-feed_post-content">
        <Box className="post-feed_post-header">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Box className="post-feed_post-header-info">
                <Typography
                  className="post-feed_post-header-name"
                  fontWeight="bold"
                >
                  {post.organizationRole?.contact?.firstName}{' '}
                  {post.organizationRole?.contact?.lastName}
                </Typography>
                <Typography className="post-feed_post-time">
                  {formatTimeAgo(post.createdAt)}
                </Typography>
              </Box>
            </div>
            <PostActions
              postId={post.id}
              canEdit={post.organizationRoleId === currentOrganizationRole?.id}
              onEditClick={() => setIsEditing(true)}
              isEvent={post.connectionUid !== null}
            />
          </div>
          <Box>
            {isEditing
              ? (
              <div className="post-edit-form">
                <TextField
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={3}
                  size="small"
                  value={editContent}
                  onChange={(e) => setEditContent(e.target.value)}
                  className="post-edit-input"
                />
                <Box style={{ display: 'flex', gap: '0.25rem' }}>
                  <Button
                    className="post-edit-form-button"
                    onClick={() => handleEditPost()}
                    size="sm"
                  >
                    Save
                  </Button>
                  <Button
                    className="post-edit-form-cancel-button"
                    variant="ghost"
                    onClick={() => setIsEditing(false)}
                    size="sm"
                  >
                    Cancel
                  </Button>
                </Box>
              </div>
                )
              : (
              <Box sx={{ width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
                {post.connectionUid
                  ? (
                  <EventPostCard
                    content={post.content}
                    connectionUid={post.connectionUid}
                    liveEvents={liveEvents}
                  />
                    )
                  : (
                  <ContentDisplay content={post.content} />
                    )}
              </Box>
                )}
            <PostReactions
              post={post}
              showSpaceBadge={showSpaceBadge}
              currentOrganizationRoleId={currentOrganizationRole?.id}
              onReaction={handleReaction}
              onToggleComments={() => setShowComments(!showComments)}
            />
          </Box>
        </Box>
        {showComments && <CommentsDisplay postId={post.id} />}
      </div>
    </div>
  )
}

export default Post
