import { createContext, useContext, useState } from 'react'
import * as Sentry from '@sentry/react'
import { logError, logInfo } from '../shared/logger'
import { config } from '../shared/config'
import { Crisp } from 'crisp-sdk-web'
import { useEffectOnce } from '../shared/hooks'
import { useUserQuery } from '../shared/queryHooks'
import { User } from '../types'
import posthog from 'posthog-js'

export const LAST_ORGANIZATION_ID_KEY = 'lastOrganizationId'
export const INVITE_CODE_KEY = 'inviteCode'

interface AuthContextProps {
  signOutUser: () => Promise<void>;
  isLoading: boolean;
  currentUser: User | null;
  refreshUser: () => Promise<void>;
  isInitialLoading: boolean;
  isSigningOut: boolean;
}

export const AuthContext = createContext<AuthContextProps>({
  signOutUser: () => Promise.resolve(),
  isLoading: true,
  currentUser: null,
  isInitialLoading: true,
  isSigningOut: false,
  refreshUser: () => Promise.resolve()
})

const AuthProvider = (props: any): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [isSigningOut, setIsSigningOut] = useState<boolean>(false)

  function setOrganizationId (organizationId: number) {
    localStorage.setItem(LAST_ORGANIZATION_ID_KEY, organizationId.toString())
  }

  const { isInitialLoading, isError, data, error, refetch } = useUserQuery()

  if (isError) {
    logError(error)
  }

  const refreshUser = async () => {
    await refetch()
  }

  if (data && currentUser && data !== currentUser) {
    setCurrentUser(data)
  }

  if (data && !currentUser) {
    try {
      setOrganizationId(data?.currentOrganizationId ?? 0)
      const fullName = `${data.firstName ?? ''} ${data.lastName ?? ''}`
      Crisp.user.setEmail(data.email)
      Crisp.user.setNickname(fullName)
      Sentry.setUser({
        authUid: data?.authUid,
        email: data?.email,
        name: fullName
      })
      posthog.identify(data.authUid!, { email: data?.email, name: fullName })

      setCurrentUser(data)
    } catch (error) {
      logError(error)
    }
  }

  useEffectOnce(() => {
    setIsLoading(false)
  })

  function signOutUser () {
    setIsSigningOut(true)
    logInfo('Signing out user')
    posthog.reset()
    Sentry.setUser(null)
    setCurrentUser(null)
    window.location.replace(`${config.API_HOST}/auth/logout`)
  }

  return (
    <AuthContext.Provider
      value={{
        signOutUser,
        isLoading,
        currentUser,
        isInitialLoading,
        isSigningOut,
        refreshUser
      }}
      {...props}
    />
  )
}

export const useAuthContext = () => useContext(AuthContext)

export default AuthProvider
