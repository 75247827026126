import EmptyHero from '../../../components/EmptyHero'
import SecondaryNavBar from '../../../components/SecondaryNavBar'
import programsSvg from '../../../assets/programs.svg'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import { useOrganizationPlaylistQuery, useUpdateOrganizationPlaylistMutation } from '../../../shared/queryHooks'
import { Skeleton, Switch } from '@mui/material'
import { PuffLoader } from 'react-spinners'
import { sortOrgPlaylists } from '../../../shared/utilities'
import { useModal } from '../../../context/ModalContext'
import { logError } from '../../../shared/logger'
import { useState } from 'react'
import OrganizationCourseReview from '../../../modals/OrganizationCourseReview'
import './index.css'

interface PlaylistContent {
  programUid: number;
  programName: string;
  publisherName: string;
  enabled: boolean;
  items: Array<{
    id: any;
    enabled: boolean;
    type: string;
    name: any;
    version: any;
    programId: number;
    programEnabled: boolean;
    organizationCourseId?: number;
  }>;
}

interface AccumulatorType {
  playlists: PlaylistContent[];
  courses: any[];
  media: any[];
}

function Library () {
  const { makeModal } = useModal()
  const [searchParms, setSearchParms] = useState('')
  const editOrganizationPlaylist = useUpdateOrganizationPlaylistMutation()
  const { isError, isLoading, data: orgPlaylists, error } = useOrganizationPlaylistQuery()

  async function toggleOrgPlaylist ({
    id,
    courseId,
    enabled,
    requiresReview,
    actionsApproved
  }: {
    id: number;
    courseId?: number;
    enabled: boolean;
    requiresReview?: boolean;
    actionsApproved?: boolean;
  }) {
    return await editOrganizationPlaylist.mutateAsync({
      id,
      courseId,
      enabled,
      access: [],
      requiresReview,
      actionsApproved
    })
  }

  function reviewRequiredCourseActions (organizationCourse) {
    makeModal({
      modal: <OrganizationCourseReview id={organizationCourse?.programId} courseId={organizationCourse?.id} courseVersionId={organizationCourse?.courseVersionId} access={organizationCourse?.organizationRoleAccess} enabled={organizationCourse?.enabled} />,
      title: `Review for ${organizationCourse?.courseVersion?.course?.name ?? 'ConnectBetter'} v${
        organizationCourse?.courseVersion?.version ?? 1
      }.0.0`
    })
  }

  if (isLoading) {
    return (
      <>
        <SecondaryNavBar title="Library" />
        <div className="billing-container-wrapper">
          <div className="program-card" style={{ flexDirection: 'column' }}>
            <Skeleton width="50%" height={22} />
            <Skeleton width="100%" height={20} />
          </div>
        </div>
      </>
    )
  }

  if (isError) {
    logError(error)
  }

  const filteredPlaylists = sortOrgPlaylists(orgPlaylists)
    ?.filter((playlist) =>
      playlist?.programVersion?.program?.name?.toLowerCase().includes(searchParms.toLowerCase())
    )

  const organizedContent = filteredPlaylists?.reduce((acc: AccumulatorType, orgProgram) => {
    const program = orgProgram?.programVersion?.program
    const courses = program?.courses || []
    const media = program?.media || []

    if (courses.length > 1 || media.length > 1 || (courses.length === 1 && media.length === 1)) {
      if (!acc.playlists) acc.playlists = []
      const playlistContent = {
        programUid: program.id,
        programName: program.name,
        publisherName: program.publisherName,
        enabled: orgProgram.enabled,
        items: [
          ...courses.map(course => ({
            id: course.id,
            organizationCourseId: course?.courseVersion?.[0]?.organizationCourse?.[0]?.id,
            enabled: course?.courseVersion?.[0]?.organizationCourse?.[0]?.enabled,
            programEnabled: orgProgram.enabled,
            type: 'course',
            name: course.name,
            version: course.courseVersion?.[0]?.version,
            programId: orgProgram.id
          })),
          ...media.map(media => ({
            id: media.id,
            enabled: media.enabled,
            programEnabled: orgProgram.enabled,
            type: 'media',
            name: media.name,
            programId: orgProgram.id
          }))
        ]
      }

      acc.playlists.push(playlistContent)
    } else {
      courses.forEach(course => {
        if (!acc.courses) acc.courses = []
        const latestCourseVersion = course.courseVersion?.reduce((max, current) =>
          current.version > max.version ? current : max
        , course.courseVersion[0])
        acc.courses.push({
          id: course.id,
          enabled: orgProgram.enabled,
          requiresReview: latestCourseVersion?.requiresReview,
          actionsApproved: latestCourseVersion?.actionsApproved,
          name: course.name,
          publisherName: program.publisherName,
          version: latestCourseVersion?.version,
          programId: orgProgram.id
        })
      })

      media.forEach(media => {
        if (!acc.media) acc.media = []
        acc.media.push({
          id: media.id,
          enabled: orgProgram.enabled,
          name: media.name,
          publisherName: program.publisherName,
          programId: orgProgram.id
        })
      })
    }

    return acc
  }, { playlists: [], courses: [], media: [] }) as AccumulatorType

  return (
    <>
      <SecondaryNavBar title="Library" />
      <div className="billing-container-wrapper org-nav-settings">
        <div className="spaces-search settings-spaces-search" style={{ marginBottom: '1rem' }}>
          <input
            type="text"
            placeholder="Search Content..."
            onChange={(e) => setSearchParms(e.target.value)}
            value={searchParms}
          />
        </div>
        {organizedContent.playlists.length > 0 && (
          <>
            <h2>Playlists</h2>
            {organizedContent.playlists.map(({ programUid, programName, publisherName, enabled, items }) => (
              <div key={programUid}>
                <div className="playlist-card">
                  <div>
                    <div style={{ display: 'flex' }}>
                      <div className="program-logo">{programName[0]}</div>
                      <div>
                        <div className="program-name">
                          {programName}
                        </div>
                        <div className="publisher-name">{publisherName}</div>
                      </div>
                    </div>
                    <div className="program-subscribed">
                      <CheckRoundedIcon /> Subscribed v{items[0].version}.0.0
                    </div>
                  </div>
                  <div className="component-toggle-container">
                      <div className="daily-checkin-settings-container">
                        {editOrganizationPlaylist.isLoading
                          ? (
                            <PuffLoader color="#fff" size={38} />
                            )
                          : (
                              <Switch
                                checked={enabled}
                                onChange={() => toggleOrgPlaylist({
                                  id: items[0].programId,
                                  enabled: !enabled,
                                  requiresReview: false,
                                  actionsApproved: true
                                })}
                              />
                            )}
                      </div>
                    </div>
                </div>
                <hr />
                <div style={{ paddingLeft: '2.5rem', marginTop: '1rem' }}>
                  {items.map((item) => (
                    <div key={`${item.id}-${item.name}`} className="program-card" style={{ backgroundColor: 'var(--primary-background-color)', border: '1px solid var(--primary-font-color)' }}>
                      <div>
                        <div style={{ display: 'flex' }}>
                          <div className="program-logo">{item.name[0]}</div>
                          <div>
                            <div className="program-name">
                              {item.name}
                              <span style={{ fontStyle: 'italic', fontWeight: '400', opacity: '0.6', marginLeft: '4px' }}>
                              - {item.type === 'course' ? 'Course' : 'Media'}
                              </span>
                            </div>
                            <div className="publisher-name">{publisherName}</div>
                          </div>
                        </div>
                        {item.type === 'course' && (
                          <div className="program-subscribed">
                            <CheckRoundedIcon /> Subscribed v{item.version}.0.0 {item.id} {item.programId}
                          </div>
                        )}
                      </div>
                      {item.type === 'course' && (
                        <div className="component-toggle-container">
                          <div className="daily-checkin-settings-container">
                            {editOrganizationPlaylist.isLoading
                              ? (
                                  <PuffLoader color="#fff" size={38} />
                                )
                              : (
                                  <Switch
                                    checked={item.enabled}
                                    disabled={!item.programEnabled}
                                    onChange={() => toggleOrgPlaylist({
                                      id: item.programId,
                                      courseId: item.organizationCourseId,
                                      enabled: !item.enabled,
                                      requiresReview: false,
                                      actionsApproved: true
                                    })}
                                  />
                                )}
                        </div>
                      </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </>
        )}
        {organizedContent.courses.length > 0 && (
          <>
            <h2>Courses</h2>
            {organizedContent.courses.map((course) => (
              <div key={course.id} className="program-card">
                <div>
                  <div style={{ display: 'flex' }}>
                    <div className="program-logo">{course.name[0]}</div>
                    <div>
                      <div className="program-name">{course.name}</div>
                      <div className="publisher-name">{course.publisherName}</div>
                    </div>
                  </div>
                  <div className="program-subscribed">
                    <CheckRoundedIcon /> Subscribed v{course.version}.0.0
                  </div>
                </div>
                <div className="component-toggle-container">
                  <div className="daily-checkin-settings-container">
                    {course.requiresReview && (
                      <div className="button danger" onClick={() => reviewRequiredCourseActions(course)}>
                        Review Required
                      </div>
                    )}
                    {editOrganizationPlaylist.isLoading
                      ? (
                          <PuffLoader color="#fff" size={38} />
                        )
                      : (
                          <Switch
                            checked={course.enabled}
                            onChange={() => toggleOrgPlaylist({
                              id: course.programId,
                              enabled: !course.enabled,
                              requiresReview: course.requiresReview,
                              actionsApproved: course.actionsApproved
                            })}
                          />
                        )}
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
        {organizedContent.media.length > 0 && (
          <>
            <h2>Media</h2>
            {organizedContent.media.map((media) => (
              <div key={media.id} className="program-card">
                <div>
                  <div style={{ display: 'flex' }}>
                    <div className="program-logo">{media.name[0]}</div>
                    <div>
                      <div className="program-name">{media.name}</div>
                      <div className="publisher-name">{media.publisherName}</div>
                    </div>
                  </div>
                </div>
                <div className="component-toggle-container">
                  <div className="daily-checkin-settings-container">
                    {editOrganizationPlaylist.isLoading
                      ? (
                          <PuffLoader color="#fff" size={38} />
                        )
                      : (
                          <Switch
                            checked={media.enabled}
                            onChange={() => toggleOrgPlaylist({
                              id: media.programId,
                              enabled: !media.enabled
                            })}
                          />
                        )}
                  </div>
                </div>
              </div>
            ))}
          </>
        )}

        {filteredPlaylists?.length === 0 && (
          <div>No items found</div>
        )}
      </div>
      {organizedContent.courses.length === 0 && organizedContent.media.length === 0 && organizedContent.playlists.length === 0 && (
        <EmptyHero
          image={programsSvg}
          title="Get Content"
          description={`${
            organizedContent.courses.length === 0 && organizedContent.media.length === 0 && organizedContent.playlists.length === 0 ? 'You are not subscribed to any content yet.' : 'Want to get additional content?'
          } Our marketplace will be available soon for you to browse public content!`}
        />
      )}
    </>
  )
}

export default Library
