import {
  useEditSpaceMutation,
  useQueryClient
} from '../../shared/queryHooks'
import { useAuthContext } from '../../context/AuthContext'
import { useModal } from '../../context/ModalContext'
import { PuffLoader } from 'react-spinners'
import { Space } from '../../types'

interface ActivateOrArchiveSpaceProps {
  space: Space
}

function ActivateOrArchiveSpace ({ space }: ActivateOrArchiveSpaceProps) {
  const { currentUser } = useAuthContext()
  const { clearModal } = useModal()
  const queryClient = useQueryClient()
  const editSpace = useEditSpaceMutation()
  const buttonText = `${space.isArchived ? 'Unarchive' : 'Archive'} space`
  const displayText = `Would you like to ${space.isArchived ? 'unarchive' : 'archive'} this space? You can unarchive this space at any time.`
  const actionClass = !space.isArchived ? 'danger' : ''

  const handleSubmit = async () => {
    space.isArchived = !space.isArchived
    await editSpace.mutateAsync(space)
    await queryClient.invalidateQueries(['connections'])
    await queryClient.invalidateQueries([`organization:${currentUser?.currentOrganizationId}`, 'spaces'])
    clearModal()
  }

  return (
    <div>
      <div className="description">{displayText}</div>
      <div className="modal-footer">
        <div className="button secondary" onClick={clearModal}>
          Cancel
        </div>
        <div className={`button start-connection-button ${actionClass}`} onClick={handleSubmit}>
          {editSpace.isLoading ? <PuffLoader color="#fff" size={21} /> : buttonText}
        </div>
      </div>
    </div>
  )
}

export default ActivateOrArchiveSpace
