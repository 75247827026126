import EmptyHero from '../../components/EmptyHero'
import SecondaryNavBar from '../../components/SecondaryNavBar'
import learnSvg from '../../assets/learn.svg'

function Coaching () {
  const avodaRedirectAction = () => {
    window.open('https://avoda.co/connectbetter-coaching', '_blank')
  }

  const action = [{ onClick: avodaRedirectAction, actionTitle: 'Learn More' }]

  return (
    <>
      <SecondaryNavBar title="Coaching"/>
      <EmptyHero
        image={learnSvg}
        title="Coaching"
        description="Interested in learning how to be an effective coach and mentor?"
        action={action}/>
    </>
  )
}

export default Coaching
