import { Space } from '../../types'
import { useState } from 'react'
import { useEditSpaceMutation, useQueryClient } from '../../shared/queryHooks'
import { useAuthContext } from '../../context/AuthContext'
import { useModal } from '../../context/ModalContext'
import { Switch } from '@mui/material'
import { PuffLoader } from 'react-spinners'

interface FeaturesToggleProps {
  featurePreferences: Record<string, boolean>;
  onToggleFeature: (featurePreference: string) => void;
}

function FeaturesToggle ({
  featurePreferences,
  onToggleFeature
}: FeaturesToggleProps) {
  return (
    <div className="features-toggle-container">
      {Object.keys(featurePreferences).map((feature) => {
        const isDisabled = feature === 'feed'
        return (
          <div key={feature} className="component-toggle-container">
            <div>{feature[0].toUpperCase() + feature.slice(1)}</div>
            <Switch
              checked={featurePreferences[feature]}
              onChange={() => onToggleFeature(feature)}
              disabled={isDisabled}
            />
          </div>
        )
      })}
    </div>
  )
}

function ManageActivities ({ space, setActiveSpaceFeature, featureTypes }: { space: Space, setActiveSpaceFeature?: (nav: string) => void, featureTypes: { feed: string, events: string, courses: string } }) {
  const editSpace = useEditSpaceMutation()
  const queryClient = useQueryClient()
  const { currentUser } = useAuthContext()
  const { clearModal } = useModal()

  const [activities, setActivities] = useState(() => {
    try {
      return JSON.parse(space.activityPreferences ?? '{}')
    } catch {
      return {}
    }
  })

  const toggleActivityPreference = (activityPreference: string) => {
    setActivities((prev) => ({
      ...prev,
      [activityPreference]: !prev[activityPreference]
    }))
  }

  // TODO: Remove reflect, agreement, and goals
  const filteredActivities = Object.keys(activities).filter(
    (activity) =>
      activity !== 'reflect' && activity !== 'agreement' && activity !== 'goals'
  )

  const activityTitles = {
    course: 'Course Verification',
    notes: 'Notes',
    actions: 'Tasks',
    attendance: 'Attendance'
  }

  const activitiesWidgetToggle = filteredActivities.map((activity) => {
    if (activity === 'course' && !space?.programVersionId) return null
    if (activityTitles[activity] === undefined) return null

    return (
      <div key={activity} className="component-toggle-container">
        <div>{activityTitles[activity]}</div>
        <Switch
          checked={activities[activity]}
          onChange={() => toggleActivityPreference(activity)}
        />
      </div>
    )
  })

  const [features, setFeatures] = useState(() => {
    try {
      return JSON.parse(space.featurePreferences ?? '{}')
    } catch {
      return {}
    }
  })

  const toggleFeaturePreference = (featurePreference: string) => {
    setFeatures((prev) => ({
      ...prev,
      [featurePreference]: !prev[featurePreference]
    }))
  }

  const handleSubmit = async () => {
    const updatedSpace = {
      ...space,
      activityPreferences: JSON.stringify(activities),
      featurePreferences: JSON.stringify(features)
    }
    await editSpace.mutateAsync(updatedSpace)
    await queryClient.invalidateQueries(['connections'])
    await queryClient.invalidateQueries([
      `organization:${currentUser?.currentOrganizationId}`,
      'spaces'
    ])

    if (setActiveSpaceFeature) {
      setActiveSpaceFeature(featureTypes.feed)
    }
    clearModal()
  }

  return (
    <>
      {setActiveSpaceFeature && (
        <>
          <div
            className="section-title"
            style={{ fontWeight: 'bold', marginBottom: '0.25rem' }}
          >
            Space Tabs
          </div>
          <FeaturesToggle
            featurePreferences={features}
            onToggleFeature={toggleFeaturePreference}
          />
        </>
      )}
      <div
        className="section-title"
        style={{
          fontWeight: 'bold',
          marginTop: '1rem',
          marginBottom: '0.25rem'
        }}
      >
        Connect Activities
      </div>
      {activitiesWidgetToggle}

      <div className="modal-footer">
        <div className="button secondary" onClick={clearModal}>
          Cancel
        </div>
        <div className="button start-connection-button" onClick={handleSubmit}>
          {editSpace.isLoading ? <PuffLoader color="#fff" size={20} /> : 'Save'}
        </div>
      </div>
    </>
  )
}

export default ManageActivities
