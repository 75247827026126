import { useEditPersonStatusMutation } from '../../shared/queryHooks'
import { useModal } from '../../context/ModalContext'
import './index.css'
import { PuffLoader } from 'react-spinners'
import { OrganizationRole } from '../../types'
import { DisplayState } from '../../shared/utilities'
import { toast } from 'sonner'

interface PersonStatusProps {
  person: OrganizationRole
  personSelection: DisplayState<OrganizationRole | null>
}

function PersonStatus ({ person, personSelection }: PersonStatusProps) {
  const { clearModal } = useModal()

  const editPersonStatus = useEditPersonStatusMutation()

  const updatedStatus = person?.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
  const actionStatus = person?.status === 'ACTIVE' ? 'Deactivate' : 'Activate'

  const handleSave = async () => {
    try {
      await editPersonStatus.mutateAsync({
        organizationRoleId: person?.id,
        status: updatedStatus
      })
      toast.success(`Person ${actionStatus.toLowerCase()}d successfully.`)
      personSelection(null)
    } catch (error) {
      toast.error('An error occurred. Please try again.')
    }
  }

  return (
  <>
    {person?.status === 'ACTIVE'
      ? <div className="person-settings-modal-content">
          <p>Are you sure you want to deactivate <span className="modal-username">{person?.contact?.firstName} {person?.contact?.lastName}</span>?</p>
          <p>If you change your mind, you can reactivate them at any time.</p>
        </div>
      : <div className="person-settings-modal-content">
          <p>Are you sure you want to activate <span className="modal-username">{person?.contact?.firstName} {person?.contact?.lastName}</span>?</p>
        </div>
    }
    <div className="modal-footer">
      <div className="button secondary" onClick={() => clearModal()}>Cancel</div>
      <div data-testid="toggle-activation-button" className={`button ${person.status === 'ACTIVE' ? 'danger' : ''}`} onClick={handleSave}>{editPersonStatus.isLoading ? <PuffLoader color='#fff' size={21} /> : actionStatus}</div>
    </div>
  </>
  )
}

export default PersonStatus
