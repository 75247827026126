import { useCourseQuery } from '../../shared/queryHooks'
import './index.css'

function CourseHighlight ({ courseUid, ...props }) {
  const { data: course, isLoading } = useCourseQuery(courseUid)

  if (isLoading) {
    return null
  }

  if (course === null) {
    return null
  }

  return (
    <div className='program-highlight-container' {...props}>
      <div className='headline'>Course you&apos;re adding</div>
      <div className='program-details-container'>
        <div className='publisher-logo'>{course?.program?.publisherName[0]}</div>
        <div className='program-name'>{course?.name}</div>
      </div>
    </div>
  )
}

export default CourseHighlight
