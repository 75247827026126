import { Entity } from '../../shared/enums'
import { useAddOrganizationCourseMutation } from '../../shared/queryHooks'
import { CourseVersion } from '../../types'
import './index.css'

interface AddOrUpdateOrganizationCourseProps {
  courseName: string
  courseVersionId: number
  courseVersion: number
  clearModal: () => void
  hasOrganizationCourseVersion: CourseVersion[]
}

function AddOrUpdateOrganizationCourse ({ courseName, courseVersionId, courseVersion, clearModal, hasOrganizationCourseVersion }: AddOrUpdateOrganizationCourseProps) {
  const addOrganizationCourse = useAddOrganizationCourseMutation()

  const addOrUpdateDescription = hasOrganizationCourseVersion?.length > 0
    ? `Are you sure you want to update to ${courseName} v${courseVersion}.0.0 for your ${Entity.Community}? Once updated, the previous course version will be still be available for use inside of your ${Entity.Community}'s Spaces.`
    : `Are you sure you want to add ${courseName} v${courseVersion}.0.0 to your ${Entity.Community}? Once added, this course will be available for use inside of your ${Entity.Community}'s Spaces. Once you confirm, access control to this course can be set inside of your ${Entity.Community}'s settings.`

  async function handleConfirm () {
    await addOrganizationCourse.mutateAsync({ courseVersionId })
    clearModal()
  }
  return (
    <>
      <div className="course-modal">{addOrUpdateDescription}</div>
      <div className="modal-footer">
        <div className="button secondary" onClick={() => clearModal()}>Cancel</div>
        <div className="button" onClick={() => handleConfirm()}>Confirm</div>
      </div>
    </>
  )
}

export default AddOrUpdateOrganizationCourse
