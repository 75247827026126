import { useOrganizationUserAchievementsQuery } from '../../shared/queryHooks'
import { logError } from '../../shared/logger'
import { Achievement } from '../../types'
import './index.css'

function AchievementsDisplay ({ id }) {
  const {
    isLoading,
    isError,
    data: achievements,
    error
  } = useOrganizationUserAchievementsQuery(id)

  if (isLoading) {
    return null
  }

  if (isError) {
    logError(error)
  }

  if (achievements === null || achievements?.length === 0) {
    return null
  }

  const getPrestigeColor = (prestige) => {
    switch (prestige) {
      case 1:
        return '#cd7f32'
      case 2:
        return 'silver'
      case 3:
        return 'gold'
      default:
        return '#e5e4e2'
    }
  }

  const calculatePrestige = (achievementId) => {
    return achievements?.filter(a => a.id === achievementId).length || 0
  }

  const uniqueAchievements = achievements?.reduce((acc: Achievement[], achievement) => {
    if (!acc.find((a) => a.id === achievement.id)) {
      acc.push(achievement)
    }
    return acc
  }, [] as Achievement[])

  return (
    <div className="program-prestige-wrapper">
      <div style={{ fontWeight: 'bold', fontSize: '1.25rem', marginTop: '2rem' }}>Achievements</div>
      <div className="program-prestige-display">
       <div className="program-grid">
        {uniqueAchievements?.map((achievement) => {
          const prestige = calculatePrestige(achievement.id)
          return (
            <div key={achievement.id} className="program-item">
              <div style={{ fontWeight: '600' }}>{achievement.name}</div>
              <div className="program-prestige-circle" style={{
                backgroundColor: getPrestigeColor(prestige),
                color: prestige >= 4 ? '#232832' : undefined
              }}>{prestige}
              </div>
            </div>
          )
        })}
      </div>
      </div>
    </div>
  )
}

export default AchievementsDisplay
