import { Box, Typography, IconButton } from '@mui/material'
import { Favorite, ChatBubbleOutline } from '@mui/icons-material'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import { Comment, ReactionType } from '../../types'

interface CommentReactionsProps {
  comment: Comment
  currentOrganizationRoleId?: number
  onReaction: (commentId: number, type: ReactionType) => void
  onReply: (commentId: number) => void
}

function CommentReactions ({
  comment,
  currentOrganizationRoleId,
  onReaction,
  onReply
}: CommentReactionsProps) {
  const isLikedByCurrentUser = comment.reactions.some(
    reaction => reaction.organizationRoleId === currentOrganizationRoleId
  )

  return (
    <Box className="comment-actions">
      <div style={{ display: 'grid', gridTemplateColumns: 'auto auto', gap: '0.3rem' }}>
        <div style={{ display: 'grid', gridTemplateColumns: 'auto auto', alignItems: 'center', gap: '1px' }}>
          <IconButton
            size="small"
            color="inherit"
            className="reaction-comment-icons"
            onClick={() => onReaction(comment.id, ReactionType.LIKE)}
          >
            {isLikedByCurrentUser
              ? (
                <Favorite
                  fontSize="small"
                  style={{ color: '#FF3044', fontSize: '1.1rem' }}
                />
                )
              : (
                <FavoriteBorderIcon
                  fontSize="small"
                  style={{ fontSize: '1.1rem' }}
                />
                )}
          </IconButton>
          <Typography
            className="post-feed_action-count"
            style={{
              color: isLikedByCurrentUser ? '#FF3044' : 'inherit',
              minWidth: '20px'
            }}
          >
            {comment.reactions.length}
          </Typography>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: 'auto auto', alignItems: 'center', gap: '1px' }}>
          <IconButton
            size="small"
            color="inherit"
            className="reaction-comment-icons"
            onClick={() => onReply(comment.id)}
          >
            <ChatBubbleOutline fontSize="small" style={{ fontSize: '1.1rem' }} />
          </IconButton>
          <Typography
            className="post-feed_action-count"
            style={{ minWidth: '20px' }}
          >
            {comment.replies.length}
          </Typography>
        </div>
      </div>
    </Box>
  )
}

export default CommentReactions
