import SecondaryNavBar from '../../components/SecondaryNavBar'
import OrganizationSettingsActions from './SettingsActions'
import OrganizationSettingsGeneral from './SettingsGeneral'
import OrganizationSettingsIntegrations from './SettingsIntegrations'
import OrganizationSettingsSpaces from './SettingsSpaces'
import './index.css'

export function OrganizationSettings () {
  return (
    <div>
      <SecondaryNavBar title="Settings" />

      <div className="organization-settings-container">
        <OrganizationSettingsGeneral />
        <OrganizationSettingsActions />
        <OrganizationSettingsSpaces />
        <OrganizationSettingsIntegrations />
      </div>
    </div>
  )
}
