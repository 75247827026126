import { Outlet } from 'react-router-dom'
import PublicNav from '../../components/PublicNav'

function PublicLayout () {
  return (
    <div>
      <PublicNav />
      <Outlet />
    </div>
  )
}

export default PublicLayout
