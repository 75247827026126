import { useEditPersonCoursePrestigeMutation } from '../../shared/queryHooks'
import { logError } from '../../shared/logger'
import { useModal } from '../../context/ModalContext'
import { PuffLoader } from 'react-spinners'
import './index.css'

function CoursePrestige ({ id, courseVersionId }) {
  const buttonText = 'Prestige Course'
  const displayText = 'Are you sure you like to prestige this course? All exercise progress will be reset, but all awards will be maintained.'
  const { clearModal } = useModal()
  const editCoursePrestige = useEditPersonCoursePrestigeMutation()

  async function handleCoursePrestige ({ id, courseVersionId }) {
    try {
      await editCoursePrestige.mutateAsync({ organizationRoleId: id, courseVersionId })
    } catch (error) {
      logError(error)
    }
  }

  return (
    <div>
        <div className="description">{displayText}</div>
        <div className="modal-footer">
          <div className="button secondary" onClick={clearModal}>
            Cancel
          </div>
          <div className="button" onClick={() => handleCoursePrestige({ id, courseVersionId })}>
            {editCoursePrestige.isLoading ? <PuffLoader color="#fff" size={21} /> : buttonText}
          </div>
        </div>
      </div>
  )
}

export default CoursePrestige
