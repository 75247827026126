import { ReportAchievement } from '../../types'

export const getFileDate = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const day = ('0' + date.getDate()).slice(-2)
  return '' + year + month + day
}

export const makeAchievementCsvRows = (achievements: ReportAchievement[]): string[][] => {
  return (
    achievements.map((achievement) =>
      makeAchievementCsvRow(achievement)
    ) ?? []
  )
}

const makeAchievementCsvRow = (
  achievement: ReportAchievement
): string[] => {
  return [
    achievement.userName,
    achievement.achievementName,
    String(achievement.spaceName)
  ]
}
