import { getOrganizationAdminPolicy } from '../../shared/policies'
import { useModal } from '../../context/ModalContext'
import { isAuthorized } from '../../shared/permissions'
import { useQueryClient } from '@tanstack/react-query'
import { getSpace } from '../../shared/api'
import ManageUserSpaces from '../../modals/ManageUserSpaces'
import AddOrEditSpaceroles from '../../modals/AddOrEditSpaceroles'
import Card from '../Card'
import './index.css'
import { OrganizationRole } from '../../types'
import { useAuthContext } from '../../context/AuthContext'

interface PersonSpacesProps {
  person: OrganizationRole
}

function PersonSpaces ({ person }: PersonSpacesProps) {
  const { makeModal } = useModal()
  const queryClient = useQueryClient()
  const { currentUser } = useAuthContext()
  const IsAdminOrAbove = isAuthorized(getOrganizationAdminPolicy(currentUser?.currentOrganizationId), currentUser)

  async function handleSpaceMemebers (id: number) {
    const space = await queryClient.fetchQuery(['spaces', id], () => getSpace(id))
    makeModal({
      modal: <AddOrEditSpaceroles existingSpace={space} showAllMembers />,
      title: `Space Members for ${space?.name}`
    })
  }

  const spaceCards = person?.spaceRoles?.map((role) => {
    return (
      <div
        onClick={() => handleSpaceMemebers(role?.spaceId)}
        key={role?.space?.name}
        className="organization-profile-space-card-container"
      >
        <Card name={role?.space?.name ?? ''} peopleCount={role?.space?.spaceRoles?.length ?? 0} />
      </div>
    )
  })

  return (
    <>
      <div>{spaceCards}</div>
      <div className="person-spaces-footer">
        {IsAdminOrAbove && (
          <div
            className="button"
            data-testid="organization-profile-edit-spaceroles-button"
            onClick={() =>
              makeModal({
                modal: <ManageUserSpaces person={person} isEditingContact={true} />
              })
            }
          >
            Edit spaces
          </div>
        )}
      </div>
    </>
  )
}

export default PersonSpaces
