import AvatarEditor from 'react-avatar-editor'
import Avatar from '../Avatar'
import ImageUploading, { ImageListType } from 'react-images-uploading'
import './index.css'

interface AddEditAvatarProps {
  setAvatar: (imageList: ImageListType) => void
  setHasChangedAvatar: (value: boolean) => void
  avatar: ImageListType
  editor: React.MutableRefObject<any>
  hasChangedAvatar: boolean
  firstName: string
  lastName?: string
  setSaveMessage?: (message: string) => void
  organizationAvatarFrame?: boolean
}

function AddEditAvatar ({ setAvatar, setHasChangedAvatar, avatar, editor, hasChangedAvatar, firstName, lastName, setSaveMessage, organizationAvatarFrame = false }: AddEditAvatarProps) {
  const clearSaveMessage = () => {
    if (setSaveMessage) {
      setSaveMessage('')
    }
  }

  const onChange = (imageList: ImageListType) => {
    clearSaveMessage()
    setAvatar(imageList)
  }

  const changeAvatar = (onImageUpload) => {
    clearSaveMessage()
    onImageUpload()
    setHasChangedAvatar(true)
  }

  const clearAvatar = () => {
    clearSaveMessage()
    setAvatar([])
  }

  const hasImage = avatar.length > 0

  return (
    <div>
      <label>Avatar</label>
      <div className="avatar-uploader-container">
        <ImageUploading value={avatar} onChange={onChange} dataURLKey="data_url" maxFileSize={10000000} acceptType={['jpeg', 'jpg', 'png']}>
          {({
            onImageUpload,
            isDragging,
            dragProps,
            errors
          }) => (
            <>
            {errors && <div>
              {errors.maxFileSize && <span>Selected file size exceeds the max file size</span>}
              {errors.acceptType && <span>Selected file type is not allowed</span>}
            </div>}
            <div className="upload__image-wrapper">
              {hasChangedAvatar && hasImage
                ? <>
                    <div className={`avatar-uploader ${organizationAvatarFrame ? 'as-organization' : 'as-user'}`}>
                      <AvatarEditor
                        ref={editor}
                        image={avatar[0]?.data_url}
                        width={160}
                        height={160}
                        border={0}
                        borderRadius={organizationAvatarFrame ? 25 : 250}
                        scale={1}
                      />
                    </div>
                  </>
                : <div>
                  <Avatar
                    className={`${organizationAvatarFrame ? 'organization-settings-avatar' : 'profile-settings-avatar'} ${hasImage ? 'with-image' : ''}`}
                    firstName={firstName}
                    lastName={lastName}
                    avatar={avatar[0]?.data_url}
                  />
              </div>}
              <div className="button-space">
                <div className="button secondary" data-testid="add-avatar-button" style={isDragging ? { color: '#2196f3' } : undefined} onClick={() => changeAvatar(onImageUpload)} {...dragProps} >{hasImage ? 'Replace Avatar' : 'Upload Avatar'}</div>
                {hasImage && <div className="button secondary" data-testid="clear-avatar-button" onClick={() => clearAvatar()}>Remove Avatar</div>}
              </div>
            </div>
          </>
          )}
        </ImageUploading>
      </div>
    </div>
  )
}

export default AddEditAvatar
