import { useParams, useSearchParams } from 'react-router-dom'
import { getCurrentOrganizationRole } from '../../shared/utilities'
import { useAuthContext } from '../../context/AuthContext'
import SecondaryNavBar from '../../components/SecondaryNavBar'
import ListExercises from '../../components/ListExercises'
import './index.css'

function CourseProgress () {
  const { currentUser } = useAuthContext()
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const paramsAction = searchParams.get('action') ?? undefined
  const secondaryNavNavigation = paramsAction ? '/' : '/courses'
  const secondaryNavTitle = paramsAction ? 'Actions' : 'Course'

  return (
    <>
      <SecondaryNavBar title={secondaryNavTitle} backTo={secondaryNavNavigation}/>
      <div className="exercise-list-container">
        <ListExercises currentUser={currentUser} userId={currentUser?.id} organizationRoleId={(getCurrentOrganizationRole(currentUser))?.id ?? 0} courseVersionId={id ? parseInt(id) : undefined} readOnly={true}/>
      </div>
    </>
  )
}

export default CourseProgress
