import { useSpring, animated } from '@react-spring/web'
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded'
import CloseFullscreenRoundedIcon from '@mui/icons-material/CloseFullscreenRounded'
import PushPinRoundedIcon from '@mui/icons-material/PushPinRounded'
import { useEffect, useRef, useState } from 'react'

interface AddonProps {
  title: string
  pinned: boolean
  setAsPinned: () => void
  className?: string
  children: JSX.Element | JSX.Element[]
  footer?: JSX.Element | JSX.Element[]
}
function Addon ({ title, pinned, setAsPinned, className, children, footer }: AddonProps) {
  const addonWrapperRef = useRef(null)
  const addonRef = useRef(null)

  const [lastPinned, setLastPinned] = useState(pinned)
  const [shouldAnimate, setShouldAnimate] = useState(false)
  const [fullscreen, setFullscreen] = useState(false)

  function safeRound (value) {
    return Math.round(value) || 0
  }

  const getAnimationCoordsFromRef = (ref) => {
    const { top, left, height, width } = ref.current?.getBoundingClientRect() || {}
    return { top: safeRound(top), left: safeRound(left), height: safeRound(height), width: safeRound(width), position: 'fixed', zIndex: 100002 }
  }

  const getAnimationStartPosition = () => {
    return getAnimationCoordsFromRef(addonWrapperRef)
  }

  const [springs, api] = useSpring(() => ({
    from: getAnimationStartPosition(),
    config: {
      mass: 0.35,
      friction: 15
    },
    onRest: () => {
      setShouldAnimate(false)
    }
  } as any), [shouldAnimate])

  useEffect(() => {
    if (!shouldAnimate) return

    if (fullscreen) {
      api.start({ to: { top: 0, left: 0, height: window.innerHeight, width: window.innerWidth, position: 'fixed', zIndex: 100002 } })
    } else {
      api.start({ to: getAnimationStartPosition() })
    }
  }, [shouldAnimate])

  const toggleFullscreen = () => {
    setShouldAnimate(true)
    setFullscreen(!fullscreen)
  }

  const setAsPinnedWrapper = () => {
    setShouldAnimate(true)
    setLastPinned(true)
    setAsPinned()
  }

  useEffect(() => {
    if (lastPinned && !pinned) {
      setShouldAnimate(true)
      setLastPinned(false)
    }
  }, [pinned])

  return (
    <div ref={addonWrapperRef} className={`addon-wrapper ${pinned || lastPinned ? 'pinned' : ''} ${fullscreen ? 'fullscreen' : ''}`}>
      <animated.div ref={addonRef} className={`addon-container ${pinned ? 'pinned' : ''} ${fullscreen ? 'fullscreen' : ''} ${className}`} style={shouldAnimate ? { ...springs } : {}}>
        <div className='addon-header'>
          <h1 className="title">{title}</h1>
          <div className="actions">
            {!pinned && !fullscreen && <div className="button secondary icon" onClick={setAsPinnedWrapper}><PushPinRoundedIcon /></div>}
            <div className="button secondary icon" onClick={toggleFullscreen}>{fullscreen ? <CloseFullscreenRoundedIcon /> : <OpenInFullRoundedIcon /> }</div>
          </div>
        </div>
        <div className="addon-content">
        {children}
        </div>
        {footer}
      </animated.div>
    </div>
  )
}

export default Addon
