import SecondaryNavBar from '../../components/SecondaryNavBar'
import { usePeopleGalleryQuery } from '../../shared/queryHooks'
import LoadingScreen from '../../components/LoadingScreen'
import { useAuthContext } from '../../context/AuthContext'
import './index.css'
import ErrorPage from '../ErrorPage'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Avatar from '../../components/Avatar'
import { isAuthorized } from '../../shared/permissions'
import { getOrganizationAdminPolicy } from '../../shared/policies'
import { OrganizationRole } from '../../types'
import ViewOrganizationProfile from '../../modals/ViewOrganizationProfile'
import { useModal } from '../../context/ModalContext'
import { getCurrentOrganization } from '../../shared/utilities'
import AddOrEditPerson from '../../modals/AddOrEditPerson'

const PAGE_TITLE = 'People'

function PersonCard ({ person, isYou }: { person: OrganizationRole, isYou?: boolean}) {
  const { makeModal } = useModal()
  const { currentUser } = useAuthContext()

  const avatar = isYou ? currentUser?.avatar : person?.user?.avatar
  const displayPerson = () => {
    makeModal({
      modal: <ViewOrganizationProfile person={person} />,
      title: `${person?.contact?.firstName}'s Profile`,
      modalBackgroundClose: true,
      slideOut: true
    })
  }

  return (
    <div className="person-card" onClick={displayPerson}>
      <div className="person-card-avatar">
        <Avatar
          className="avatar"
          avatar={avatar}
          firstName={person?.contact?.firstName}
          lastName={person?.contact?.lastName}
          asBackground
        />
      </div>
      <div className="person-card-details">
        <div className="person-card-details-title">
          {person?.contact?.firstName} {person?.contact?.lastName} {isYou ? '(You)' : ''}
        </div>
      </div>
    </div>
  )
}

export function PeopleGallery () {
  const { currentUser } = useAuthContext()
  const { makeModal } = useModal()
  const currentOrganization = getCurrentOrganization(currentUser)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useState('')
  const {
    isLoading,
    isError,
    data: people
  } = usePeopleGalleryQuery(currentUser?.currentOrganizationId ?? 0, { enabled: !!currentUser })

  const isAdminOrOwner = isAuthorized(getOrganizationAdminPolicy(currentUser?.currentOrganizationId), currentUser)

  const myPerson = currentUser?.organizationRoles?.find(
    (role) => role?.organization?.id === currentUser?.currentOrganizationId
  )

  const filteredPeople = people?.filter((person) => {
    if (person.id === myPerson?.id) return false
    if (searchParams === '') return true
    const fullName = `${person?.contact?.firstName} ${person?.contact?.lastName}`
    return fullName.toLowerCase().includes(searchParams.toLowerCase())
  })

  const sortedPeople = filteredPeople?.sort((a, b) => {
    const aName = `${a?.contact?.firstName} ${a?.contact?.lastName}`
    const bName = `${b?.contact?.firstName} ${b?.contact?.lastName}`
    return aName.localeCompare(bName)
  })

  function getActions () {
    const actions: { action: () => void, actionTitle: string, actionClassName: string }[] = []
    if (isAdminOrOwner && !(currentOrganization?.defaultSpace && currentOrganization?.membersCanInvite)) {
      actions.push({
        action: () => navigate('/communities/settings/people'),
        actionTitle: 'Manage People',
        actionClassName: 'secondary'
      })
    }

    if (currentOrganization?.defaultSpace && currentOrganization?.membersCanInvite) {
      actions.push({
        action: () => makeModal({
          modal: <AddOrEditPerson memberInviteOnly={true} />,
          title: 'Invite People',
          modalBackgroundClose: true
        }),
        actionTitle: 'Add People',
        actionClassName: 'secondary'
      })
    }
    return actions
  }

  if (isLoading) {
    return (
      <>
        <SecondaryNavBar title={PAGE_TITLE} onClickActions={getActions()} />
        <LoadingScreen />
      </>
    )
  }

  if (isError) {
    return (
      <>
        <SecondaryNavBar title={PAGE_TITLE} onClickActions={getActions()} />
        <ErrorPage
          message="We ran into an issue loading People"
          action={() => window.location.reload()}
          actionTitle="Retry?"
        />
      </>
    )
  }

  return (
    <>
      <SecondaryNavBar title={PAGE_TITLE} onClickActions={getActions()} />
      <div className="people-gallery-container">
        <input
          type="text"
          placeholder="Search People..."
          onChange={(e) => setSearchParams(e.target.value)}
          value={searchParams}
        />
        <div className="people-cards">
          {myPerson && <PersonCard person={myPerson} isYou />}
          {sortedPeople?.map((person) => <PersonCard key={person.id} person={person} />)}
        </div>
      </div>
    </>
  )
}
