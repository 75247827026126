import SecondaryNavBar from '../../../components/SecondaryNavBar'
import LoadingScreen from '../../../components/LoadingScreen'
import { logError } from '../../../shared/logger'
import { useSpacesQuery } from '../../../shared/queryHooks'
import { useAuthContext } from '../../../context/AuthContext'
import './index.css'
import { NavigationAction } from '../../../types'
import { useModal } from '../../../context/ModalContext'
import CreateOrEditSpace from '../../../modals/CreateOrEditSpace'
import { SpacesTable } from '../../../components/SpacesTable'

function WorkspaceSettingsSpaces () {
  const { currentUser } = useAuthContext()
  const { makeModal } = useModal()

  const {
    isLoading,
    isError,
    data: spaces,
    error
  } = useSpacesQuery(currentUser?.currentOrganizationId ?? 0, {
    enabled: !!currentUser, isArchived: 'all'
  })

  if (isLoading) {
    return (
      <>
        <SecondaryNavBar title="Spaces" />
        <LoadingScreen />
      </>
    )
  }

  if (isError) {
    logError(error)
  }

  const sortedSpaces = spaces?.sort((a, b) => {
    if (a.isArchived && !b.isArchived) return 1
    if (!a.isArchived && b.isArchived) return -1
    return a.name.localeCompare(b.name)
  })

  const actions: NavigationAction[] = [
    {
      action: () =>
        makeModal({
          modal: <CreateOrEditSpace openedFromAdmin />,
          title: 'Create Space'
        }),
      actionTitle: 'Create Space',
      actionClassName: 'secondary'
    }
  ]

  return (
    <>
      <SecondaryNavBar title="Spaces" onClickActions={actions} />
      <div className="org-nav-settings">
        <SpacesTable data={sortedSpaces ?? []} />
      </div>
    </>
  )
}

export default WorkspaceSettingsSpaces
