import { useModal } from '../../context/ModalContext'
import AddOrEditContactNotes from '../../modals/AddOrEditContactNotes'
import DeleteContactNotes from '../../modals/DeleteContactNotes'
import { formatConnectionDate } from '../../shared/utilities'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import './index.css'

function PersonNotes ({ person }) {
  const { makeModal } = useModal()

  return (
    <div>
      <div
        style={{ marginBottom: '1rem' }}
        className="button"
        onClick={() =>
          makeModal({
            modal: (
              <AddOrEditContactNotes contactId={person?.contact?.id} />
            ),
            title: 'Add Note'
          })
        }
      >Add Note</div>
      {person?.contact?.contactNotes.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).map((contactNote) => (
        <div key={contactNote.id}>
          <div className="person-notes-container">
            {contactNote.note.split('\n').map((line, i) => (
              <p key={i}>{line}</p>
            ))}
          </div>
          <div className="person-notes-footer">
            <div style={{ display: 'flex' }}>
              <div
                className="person-notes-icon-container"
                onClick={() =>
                  makeModal({
                    modal: (
                      <AddOrEditContactNotes contactNote={contactNote} contactId={person?.contact?.id} />
                    ),
                    title: 'Edit Note'
                  })
                }
              >
                <EditIcon fontSize="small"/>
              </div>
              <div
              className="person-notes-icon-container"
                onClick={() =>
                  makeModal({
                    modal: (
                      <DeleteContactNotes contactNoteId={contactNote.id} />
                    ),
                    title: 'Delete Note'
                  })
                }
              >
                <DeleteIcon fontSize="small"/>
              </div>
            </div>
            <div style={{ fontSize: '12px' }}>Lasted Updated - {formatConnectionDate(contactNote.updatedAt)}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default PersonNotes
