import ManageActivities from '../../components/ManageActivites'
import { Space } from '../../types'

function EditSpaceEventSettings ({ space }: { space: Space }) {
  const featureTypes = {
    feed: 'feed',
    events: 'events',
    courses: 'courses',
    media: 'media'
  }
  return <ManageActivities space={space} featureTypes={featureTypes} />
}

export default EditSpaceEventSettings
