const ErrorMessages = {
  CourseDoesNotExist: 'Course does not exist',
  AccessDenied: 'Access denied',
  OrganizationRoleNotAllowed: 'Organization is not allowed',
  Unknown: 'An unknown error occurred'
}

const parsedErrorMessage = (error: any): string => {
  if (error.response) {
    return error.response.data?.error || error.response?.status || ErrorMessages.Unknown
  } else if (error.request) {
    return error.request
  } else if (error.message) {
    return error.message
  } else {
    return error?.toString() || ErrorMessages.Unknown
  }
}

export { parsedErrorMessage }
