import { useCreateOrganizationGoalMutation, useUpdateOrganizationGoalMutation } from '../../shared/queryHooks'
import { PuffLoader } from 'react-spinners'
import { useState } from 'react'
import './index.css'
import { DisplayState } from '../../shared/utilities'

interface CreateOrEditGoalProps {
  clearModal: () => void
  goalId?: number
  existingTitle?: string
  setGoal?: DisplayState<number | null>
}

function CreateOrEditGoal ({ clearModal, goalId, existingTitle, setGoal }: CreateOrEditGoalProps) {
  const createGoal = useCreateOrganizationGoalMutation()
  const updateGoal = useUpdateOrganizationGoalMutation()
  const [errorMessage, setErrorMessage] = useState('')
  const [title, setTitle] = useState(existingTitle || '')

  const buttonText = existingTitle ? 'Save' : 'Create'

  const handleChange = (value) => {
    setErrorMessage('')
    setTitle(value)
  }

  const handleSubmit = () => {
    if (title.trim() === '') {
      setErrorMessage('Please enter a goal and try again.')
      return
    }

    if (goalId) {
      updateGoal.mutate({ title, goalId })
      if (setGoal) setGoal(null)
      clearModal()
      return
    }
    createGoal.mutate(title)
    clearModal()
  }

  const mutationLoading = createGoal?.isLoading || updateGoal?.isLoading

  return (
    <div>
      {errorMessage && <div className="goal-error">{errorMessage}</div>}
      <div className="goal-content-container">
        <label>Title</label>
        <textarea
          value={title}
          placeholder="Increase course completion by 20%, etc..."
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
      <div className="modal-footer">
        <div className="button secondary" onClick={() => clearModal()}>Cancel</div>
        <div className="button" onClick={() => handleSubmit()}>{mutationLoading ? <PuffLoader color="#fff" size={21} /> : buttonText}</div>
      </div>
    </div>
  )
}

export default CreateOrEditGoal
