import ListExercises from '../ListExercises'
import { useAuthContext } from '../../context/AuthContext'
import { useState } from 'react'
import { ContentType } from '../../types/DataTypes'
import { getCurrentOrganizationRole } from '../../shared/utilities'
import './index.css'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

interface CoursesProps {
  spaceId: number
  courseName: string
  courseType: ContentType
  handlePrevious?: () => void
  handleNext?: () => void
  courseVersionId: number
  hasMultipleContent: boolean
}

function Course ({ spaceId, courseName, courseType, courseVersionId, handlePrevious, handleNext, hasMultipleContent }: CoursesProps) {
  const { currentUser } = useAuthContext()
  const currentOrganizationRole = getCurrentOrganizationRole(currentUser)
  const [isCompleted, setIsCompleted] = useState(false)

  const handleComplete = (value: boolean) => {
    setIsCompleted(value)
  }

  const isNextDisabled = () => {
    return courseType === ContentType.STRUCTURED && !isCompleted
  }

  return (
    <>
      {courseName && <div style={{ fontSize: '18px', opacity: 0.7, marginBottom: '1rem', width: '100%', maxWidth: hasMultipleContent ? '700px' : '1000px', textAlign: 'left' }}>Your Progress</div>}
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'space-between', marginBottom: '1rem', width: '100%', maxWidth: hasMultipleContent ? '700px' : '1000px' }}>
        <div
          className="course-toggle"
          onClick={handlePrevious}
          style={{ visibility: handlePrevious ? 'visible' : 'hidden' }}
        >
          <ChevronLeftIcon />
        </div>
        <div className="course-name">{courseName}</div>
        <div
          className="course-toggle"
          onClick={handleNext}
          style={{ visibility: handleNext ? (isNextDisabled() ? 'hidden' : 'visible') : 'hidden' }}
        >
          <ChevronRightIcon />
        </div>
      </div>
      <ListExercises
        currentUser={currentUser}
        spaceId={spaceId}
        readOnly={false}
        handleCompleted={handleComplete}
        courseVersionId={courseVersionId}
        userId={currentUser?.id}
        playlist={true}
        enableSelfProgress={courseType === ContentType.SELF_PACED}
        organizationRoleId={currentOrganizationRole?.id}
      />
    </>
  )
}

export default Course
