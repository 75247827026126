import { useDeleteConnectionMutation } from '../../shared/queryHooks'
import { useModal } from '../../context/ModalContext'
import { PuffLoader } from 'react-spinners'

function DeleteConnection ({ uid }) {
  const { clearModal } = useModal()
  const deleteConnection = useDeleteConnectionMutation()

  const handleDelete = () => {
    deleteConnection.mutate(uid)
  }

  return (
    <>
      <div> Are you Sure you want to archive this event?</div>
      <div>
        This event will no longer be available for viewing and cannot be updated
        or recovered.
      </div>
      <div className="modal-footer">
        <div className="button secondary" onClick={() => clearModal()}>
          Cancel
        </div>
        <div className="button danger" onClick={() => handleDelete()}>
          {deleteConnection?.isLoading
            ? (
            <PuffLoader color="#fff" size={21} />
              )
            : (
                'Archive'
              )}
        </div>
      </div>
    </>
  )
}

export default DeleteConnection
