import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useState } from 'react'
import GoalSettingsMenuItems from '../GoalSettingsMenuItems'
import { useOutsideAlerter } from '../../shared/utilities'
import './index.css'

interface GoalSettingsMenuProps {
  goalId: number
  title: string
}

function GoalSettingsMenu ({ goalId, title }: GoalSettingsMenuProps) {
  const [goal, setGoal] = useState<number | null>(null)

  const goalSettingsMenuItems = () => {
    return (
      <GoalSettingsMenuItems
        goalId={goalId}
        title={title}
        useOutsideAlerter={useOutsideAlerter}
        setGoal={setGoal}
      />
    )
  }

  return (
    <div>
      <div className="goal-button-container">
        <div className="button transparent goal-button" onClick={goal === goalId ? () => setGoal(null) : () => setGoal(goalId)}>
          <MoreHorizIcon />
        </div>
      </div>
      <div className="settings-items-wrapper">
        { goal === goalId &&
        goalSettingsMenuItems() }
      </div>
    </div>
  )
}

export default GoalSettingsMenu
