import { checkIsValidPromoCode } from './api'
import { isEmpty } from './utilities'
import validator from 'email-validator'
import { roles } from './enums'

function validateFirstName (firstName) {
  if (isEmpty(firstName)) {
    throw Error('Please fill out a first name')
  }
}

function validateLastName (lastName) {
  if (isEmpty(lastName)) {
    throw Error('Please fill out a last name')
  }
}

function validateEmail (email) {
  if (!validator.validate(email) || email.includes('#')) {
    throw Error('Please enter a valid email')
  }
}

function validateRoleRequirements ({ title, email }) {
  if (title !== roles.guest && isEmpty(email)) {
    throw Error('An email is required for this role')
  }
}

async function isValidPromoCode ({ promoCode, organizationId }) {
  if (isEmpty(promoCode) || (await checkIsValidPromoCode({ promoCode, organizationId })).length < 1) {
    throw Error('Please enter a valid promo code')
  }
}

export {
  validateFirstName,
  validateLastName,
  validateEmail,
  validateRoleRequirements,
  isValidPromoCode
}
