import ErrorProvider from './context/ErrorContext'
import AuthProvider from './context/AuthContext'
import React from 'react'
import App from './App'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ModalProvider } from './context/ModalContext'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { setupSentry } from './shared/sentry'
import { logError } from './shared/logger'
import { setupCrisp } from './shared/crisp'
import { Elements } from '@stripe/react-stripe-js'
import { setupStripe, stripeElementsOptions } from './shared/stripe'
import { setupPostHog } from './shared/posthog'
import { Toaster } from 'sonner'
import './index.css'

setupSentry()
setupCrisp()
setupPostHog()

const stripe = setupStripe()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount: number, error: any) => {
        return error?.response?.status >= 500
      }
    }
  },

  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query?.state?.data !== undefined) {
        logError(error)
      }
    }
  })
})

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Elements stripe={stripe} options={stripeElementsOptions}>
        <QueryClientProvider client={queryClient}>
          <ErrorProvider>
            <AuthProvider>
              <ModalProvider>
                <Toaster richColors />
                <App />
              </ModalProvider>
            </AuthProvider>
          </ErrorProvider>
        </QueryClientProvider>
      </Elements>
    </BrowserRouter>
  </React.StrictMode>
)
