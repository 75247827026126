import { ReactSVG } from 'react-svg'
import Button from '../Button'
import './index.css'
import { PropsOf } from '@emotion/react'

interface EmptyHeroProps {
  image: string
  title: string
  description: string
  action?: { actionTitle: string; onClick: () => void }[]
}

function EmptyHero ({ image, title, description, action, ...props }: EmptyHeroProps & PropsOf<'div'>) {
  const actions = action?.map((action, index) => {
    const actionOnClick = action?.onClick
    return (
      <Button key={index} onClick={() => actionOnClick()}>
        {action?.actionTitle}
      </Button>
    )
  })

  return (
    <div className="empty-hero" {...props}>
      <div style={{ display: 'flex' }}>
        <ReactSVG src={image} style={{ minWidth: '8rem' }} />
        <div className="empty-description-container">
          <div className="empty-title">{title}</div>
          <p className="empty-description">{description}</p>
          {action && actions}
        </div>
      </div>
    </div>
  )
}

export default EmptyHero
