import { useState, useEffect } from 'react'
import { isUrl, isImageUrl, isVideoUrl, isYouTubeUrl, isVimeoUrl } from '../../shared/utilities'
import PostPreview from '../PostPreview'
import { useModal } from '../../context/ModalContext'
import MediaDisplay from '../../modals/MediaDisplay'
import './index.css'
import Button from '../Button'

interface ContentDisplayProps {
  content: string;
}

function ContentDisplay ({ content }: ContentDisplayProps) {
  const [contentUrls, setContentUrls] = useState<string[]>([])
  const [displayContent, setDisplayContent] = useState(content)
  const { makeModal } = useModal()

  useEffect(() => {
    const extractUrls = async () => {
      const words = content.split(/(\s+)/)
      const urls = words.filter((word) => isUrl(word))
      const mediaUrls = await Promise.all(
        urls.map(async (url) => {
          if ((await isImageUrl(url)) || (await isVideoUrl(url)) || isYouTubeUrl(url) || isVimeoUrl(url)) {
            return url
          }
          return null
        })
      )
      const filteredMediaUrls = mediaUrls.filter(
        (url) => url !== null
      ) as string[]
      setContentUrls(filteredMediaUrls)

      const newDisplayContent = words
        .filter((word) => !filteredMediaUrls.includes(word))
        .join('')
      setDisplayContent(newDisplayContent)
    }
    void extractUrls()
  }, [content])

  const renderContent = () => {
    const paragraphs = displayContent.split('\n')
    return paragraphs.map((paragraph, paragraphIndex) => {
      const words = paragraph.split(/\s+/).filter(word => word.length > 0)
      if (words.length === 0) return null
      return (
        <p key={paragraphIndex}>
          {words.map((word, index) =>
            isUrl(word)
              ? (
              <a
                key={index}
                href={word}
                target="_blank"
                rel="noopener noreferrer"
                className="contentLink"
              >
                {word}
              </a>
                )
              : (
              `${word} `
                )
          )}
        </p>
      )
    }).filter(Boolean)
  }

  const handleGridItemClick = (index: number) => {
    const videos = document.querySelectorAll('video')
    videos.forEach((video) => video.pause())

    makeModal({
      modalSize: 'LARGE',
      modal: <MediaDisplay contentUrls={contentUrls} initialIndex={index} />,
      modalClose: true
    })
  }

  const renderMediaGrid = () => {
    if (contentUrls.length === 0) return null

    if (contentUrls.length === 1) {
      return (
        <div>
          <PostPreview
            title={null}
            description={null}
            image={contentUrls[0]}
            url={contentUrls[0]}
            type={contentUrls[0].match(/\.(jpeg|jpg|gif|png)$/) ? 'image' : 'video'}
            isPreview={false}
            allUrls={contentUrls}
          />
        </div>
      )
    }

    const displayUrls = contentUrls.slice(0, 4)
    const hasMoreMedia = contentUrls.length > 4

    return (
      <>
        <div className={`media-grid ${displayUrls.length === 3 ? 'three-items' : ''}`}>
          {displayUrls.map((url, index) => (
            <div
              key={index}
              className="media-grid-item"
              onClick={() => handleGridItemClick(index)}
            >
              <PostPreview
                title={null}
                description={null}
                image={url}
                url={url}
                type={url.match(/\.(jpeg|jpg|gif|png)$/) ? 'image' : 'video'}
                isPreview={false}
                allUrls={contentUrls}
                index={index}
              />
            </div>
          ))}
        </div>
        {hasMoreMedia && (
          <Button
            variant="ghost"
            onClick={() => handleGridItemClick(4)}
            size="sm"
            className="view-more-media-button"
          >
            View More
          </Button>
        )}
      </>
    )
  }

  return (
    <>
      {renderContent()}
      {renderMediaGrid()}
    </>
  )
};

export default ContentDisplay
