import { roles } from './enums'

export type Policy = {
  space?: {
    id?: number
    roles: string[]
  }
  organization?: {
    id?: number
    roles: string[]
  }
}

const getLeaderPolicy = (): Policy => ({
  space: {
    roles: [roles.leader]
  },
  organization: {
    roles: [roles.admin, roles.owner]
  }
})

const getAdminPolicy = (): Policy => ({
  organization: {
    roles: [roles.admin, roles.owner]
  }
})

const getOwnerPolicy = (): Policy => ({
  organization: {
    roles: [roles.owner]
  }
})

const getSpacePolicy = (spaceId: number | null | undefined, organizationId: number | null | undefined): Policy => {
  if (!spaceId || !organizationId) return {}
  return {
    space: {
      id: spaceId,
      roles: [roles.member, roles.leader]
    },
    organization: {
      id: organizationId,
      roles: [roles.admin, roles.owner]
    }
  }
}

const getOrganizationPolicy = (organizationId: number | null | undefined): Policy => {
  if (!organizationId) return {}
  return {
    organization: {
      id: organizationId,
      roles: [roles.guest, roles.member, roles.admin, roles.owner]
    }
  }
}

const getOrganizationPaidMemberPolicy = (organizationId: number | null | undefined): Policy => {
  if (!organizationId) return {}
  return {
    organization: {
      id: organizationId,
      roles: [roles.member, roles.admin, roles.owner]
    }
  }
}

const getSpaceLeaderPolicy = (spaceId: number | null | undefined, organizationId: number | null | undefined): Policy => {
  if (!spaceId && !organizationId) return {}
  return {
    space: {
      id: spaceId ?? 0,
      roles: [roles.leader]
    },
    organization: {
      id: organizationId ?? 0,
      roles: [roles.admin, roles.owner]
    }
  }
}

const getOrganizationAdminPolicy = (organizationId: number | null | undefined): Policy => {
  if (!organizationId) return {}
  return {
    organization: {
      id: organizationId,
      roles: [roles.admin, roles.owner]
    }
  }
}

const getOrganizationOwnerPolicy = (organizationId: number | null | undefined): Policy => {
  if (!organizationId) return {}
  return {
    organization: {
      id: organizationId,
      roles: [roles.owner]
    }
  }
}

const getOrganizationPersonPolicy = (organizationId: number | null | undefined): Policy => {
  if (!organizationId) return {}
  return {
    organization: {
      id: organizationId,
      roles: [roles.member, roles.admin, roles.owner]
    }
  }
}

export {
  getSpacePolicy,
  getOrganizationPolicy,
  getSpaceLeaderPolicy,
  getOrganizationAdminPolicy,
  getOrganizationOwnerPolicy,
  getLeaderPolicy,
  getAdminPolicy,
  getOwnerPolicy,
  getOrganizationPersonPolicy,
  getOrganizationPaidMemberPolicy
}
