import { useState, useRef } from 'react'
import './index.css'
import { MenuItem, Checkbox, ListItemText } from '@mui/material'
import { useOutsideAlerter } from '../../shared/utilities'

interface SortPeopleTagsProps {
  tags?: string[]
  handleSort?: (selectedTags: string[]) => void
}

function SortPeopleTags ({ tags, handleSort }: SortPeopleTagsProps) {
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [displayTagSortDropDown, setDisplayTagSortDropDown] = useState(false)
  const wrapperRef = useRef(null)
  useOutsideAlerter({ ref: wrapperRef, setDisplayState: setDisplayTagSortDropDown })

  const handleChange = (value) => {
    const updatedSelectedTags = selectedTags?.includes(value) ? selectedTags?.filter((tag) => tag !== value) : [...selectedTags, value]
    setSelectedTags(updatedSelectedTags)
    if (handleSort !== undefined) {
      handleSort(updatedSelectedTags)
    }
  }

  return (
    <div ref={wrapperRef} style={{ position: 'relative' }}>
        <div className="button transparent"
        onClick={() => setDisplayTagSortDropDown(!displayTagSortDropDown)}>
        Tag Filter
      </div>
      {displayTagSortDropDown && <div className="tag-dropdown">
        {tags?.map((tag) => (
          <MenuItem key={tag} value={tag} onClick={() => handleChange(tag)}>
            <Checkbox checked={selectedTags?.some((selectedTag) => selectedTag === tag)} value={tag}/>
            <ListItemText primary={tag} />
          </MenuItem>
        ))}
      </div>}
    </div>
  )
}

export default SortPeopleTags
