import { useDeletePostMutation, useDeleteCommentMutation } from '../../shared/queryHooks'
import { PuffLoader } from 'react-spinners'
import { useModal } from '../../context/ModalContext'

function DeleteCommentOrPost ({ commentId, postId }: { commentId?: number, postId?: number}) {
  const { clearModal } = useModal()
  const deleteComment = useDeleteCommentMutation()
  const deletePost = useDeletePostMutation()
  const deleteText = `This action cannot be undone. This will permanently delete your ${commentId ? 'comment' : 'post'}, and cannot be recovered.`

  async function handleDelete () {
    if (commentId) {
      await deleteComment.mutateAsync(commentId)
    }

    if (postId) {
      await deletePost.mutateAsync(postId)
    }
    clearModal()
  }

  return (
    <>
      <div>{deleteText}</div>
      <div className="modal-footer">
        <div className="button secondary" onClick={() => clearModal()}>Cancel</div>
        <div className="button danger" onClick={() => handleDelete()}>{deleteComment?.isLoading || deletePost.isLoading ? <PuffLoader color="#fff" size={21} /> : 'Delete'}</div>
      </div>
    </>
  )
}

export default DeleteCommentOrPost
