import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import { Radio } from '@mui/material'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import CourseHighlight from '../../components/CourseHighlight'
import { useAuthContext } from '../../context/AuthContext'
import { logError } from '../../shared/logger'
import { useCreateOrganizationCoursesMutation } from '../../shared/queryHooks'
import {
  getOrganizationsAsAdmin,
  getCourseUidFromSearchParams,
  useSwitchOrganization
} from '../../shared/utilities'
import './index.css'
import { Organization } from '../../types'
import { parsedErrorMessage } from '../../shared/errors'
import { Entity } from '../../shared/enums'
import Button from '../../components/Button'
import { toast } from 'sonner'

function OrganizationConnect () {
  const createOrganizationCourse = useCreateOrganizationCoursesMutation()
  const [searchParams] = useSearchParams()
  const { currentUser } = useAuthContext()
  const courseUid = getCourseUidFromSearchParams(searchParams)
  const navigate = useNavigate()
  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization | null>(null)
  const { switchOrganization } = useSwitchOrganization()
  const organizations = getOrganizationsAsAdmin(currentUser)

  async function connectOrganization (organizationId, courseUid) {
    if (organizationId && courseUid) {
      try {
        await createOrganizationCourse.mutateAsync({
          organizationId,
          courseUid
        })
        toast.success(`Course added to ${selectedOrganization?.name}`)
        switchOrganization(organizationId)
        navigate('/')
      } catch (error) {
        logError(error)
      }
    }
  }

  function isCourseAlreadyAdded (organization) {
    return organization?.organizationCourse?.some((organizationCourse) => {
      return organizationCourse?.courseVersion?.course?.uid === courseUid
    })
  }

  function selectOrganization (organization) {
    if (!isCourseAlreadyAdded(organization)) {
      setSelectedOrganization(organization)
    }
  }

  function isOrganizationSelected (organization) {
    return selectedOrganization?.id === organization.id
  }

  function sortByCourseAdded (organizationA, organizationB) {
    if (
      !isCourseAlreadyAdded(organizationA) &&
      isCourseAlreadyAdded(organizationB)
    ) { return -1 }
    if (
      isCourseAlreadyAdded(organizationA) &&
      !isCourseAlreadyAdded(organizationB)
    ) { return 1 }
    return 0
  }

  useEffect(() => {
    if (organizations?.length === 0) {
      navigate(`/communities/new?course=${courseUid}`)
    }
  }, [organizations])

  return (
    <>
      {courseUid && <CourseHighlight courseUid={courseUid} />}
      <div className="login-container" style={{ marginTop: '1rem' }}>
        <div className="title">
          Which {Entity.Community} would you like to add this course to?
        </div>
        {createOrganizationCourse?.error
          ? (
          <div className="login-error">
            {parsedErrorMessage(createOrganizationCourse.error)}
          </div>
            )
          : null}
        <div className="radio-menu-item-container">
          {organizations?.sort(sortByCourseAdded)?.map((organization) => (
            <div
              key={organization?.id}
              className={`radio-menu-item ${
                isCourseAlreadyAdded(organization) ? 'disabled' : ''
              } ${isOrganizationSelected(organization) ? 'selected' : ''}`}
              onClick={() => selectOrganization(organization)}
            >
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                data-testid={`select-organization-name-${organization?.id}`}
              >
                <Radio checked={isOrganizationSelected(organization)} />{' '}
                {organization?.name}
              </div>
              {isCourseAlreadyAdded(organization) && (
                <div className="program-already-added">
                  <CheckRoundedIcon /> Added
                </div>
              )}
            </div>
          ))}
        </div>
        <Button
          onClick={() => connectOrganization(selectedOrganization?.id, courseUid)}
          isLoading={createOrganizationCourse.isLoading}
          testId="login-button"
        >
          Continue
        </Button>
        <Link
          to={`/communities/new?course=${courseUid}`}
          className="subtitle"
          style={{ marginTop: '1rem', marginBottom: 0 }}
        >
          Or create a new {Entity.Community}
        </Link>
      </div>
      {currentUser?.organizationRoles &&
        currentUser.organizationRoles.length > 0 && (
          <div className="cancel-and-return" onClick={() => navigate('/')}>
            Cancel and return home
          </div>
      )}
    </>
  )
}

export default OrganizationConnect
