import { pluralize } from '../../shared/utilities'
import './index.css'

interface CardProps {
  name: string
  courseName?: string
  peopleCount: number
}

function Card ({ name, courseName, peopleCount }: CardProps) {
  return (
    <div className="card-container">
      <div className="card-header">
        <div className="card-title">{name}</div>
      </div>
      {courseName &&
        <div className="card-subtitle">
          {`Course: ${courseName}`}
        </div>
      }
      <div className="card-subtitle">
        {`${peopleCount} ${pluralize('member', peopleCount)}`}
      </div>
    </div>
  )
}

export default Card
