import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import OrganizationSpaceSettingsMenuItems from '../OrganizationSpaceSettingsMenuItems'
import { useState } from 'react'
import { useOutsideAlerter } from '../../shared/utilities'

function OrganizationSpaceSettingsMenu ({ spaceId, space }) {
  const [spaceSettingMenu, setSpaceSettingMenu] = useState(null)

  const openSpaceSettingsMenuItems = () => {
    return (
      <OrganizationSpaceSettingsMenuItems
        space={space}
        setSpaceSettingMenu={setSpaceSettingMenu}
        useOutsideAlerter={useOutsideAlerter}
      />
    )
  }

  const openMenu = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (spaceSettingMenu === spaceId) {
      setSpaceSettingMenu(null)
    } else {
      setSpaceSettingMenu(spaceId)
    }
  }

  return (
    <div>
      <div className="action-button-container">
        <div className="button transparent action-button" onClick={openMenu}>
          <MoreHorizIcon />
        </div>
      </div>
      <div className="settings-items-wrapper">
        { spaceSettingMenu === spaceId &&
        openSpaceSettingsMenuItems() }
      </div>
    </div>
  )
}

export default OrganizationSpaceSettingsMenu
