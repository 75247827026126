import { useUpdatePromoCodeMutation } from '../../shared/queryHooks'
import { useState } from 'react'
import { isValidPromoCode } from '../../shared/validators'
import { logWarn } from '../../shared/logger'
import './index.css'
import { User } from '../../types'
import { parsedErrorMessage } from '../../shared/errors'

interface ApplyPromoCodeProps {
  currentUser?: User | null;
}

function ApplyPromoCode ({ currentUser }: ApplyPromoCodeProps) {
  const [promoCode, setPromoCode] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const editPromoCodeStatus = useUpdatePromoCodeMutation()

  const handleApply = async () => {
    try {
      await isValidPromoCode({ promoCode, organizationId: currentUser?.currentOrganizationId })
      editPromoCodeStatus.mutate({ organizationId: currentUser?.currentOrganizationId, code: promoCode, isCreatedBy: false })
    } catch (error) {
      logWarn(error)
      setErrorMessage(parsedErrorMessage(error))
    }
  }

  const onChange = (value) => {
    setErrorMessage('')
    setPromoCode(value)
  }

  return (
    <div className="apply-promo-credit-modal" data-testid="apply-promo-code-container">
      {errorMessage && <div className="promotion-apply-error" data-testid="error-message-container">{errorMessage}</div>}
      <input
        placeholder="Promotion code"
        type="Text"
        value={promoCode}
        onChange={(e) => onChange(e.target.value)}
      />
      <div className="modal-footer footer">
        <div className="button" data-testid="submit-promo-code-button" onClick={handleApply}>Apply</div>
      </div>
    </div>
  )
}

export default ApplyPromoCode
