import { Crisp } from 'crisp-sdk-web'
import { config } from './config'
import { logInfo } from './logger'

function setupCrisp () {
  try {
    Crisp.configure(config.CRISP_WEBSITE_ID)
    Crisp.chat.hide()
    Crisp.message.onMessageReceived(() => {
      Crisp.chat.show()
      Crisp.chat.open()
    })
  } catch (error) {
    logInfo(error)
  }
}

export {
  setupCrisp
}
