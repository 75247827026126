import { useEffect, useCallback, useRef } from 'react'
import { config } from '../../shared/config'
import { loadScript } from '../../shared/utilities'
import './index.css'

interface GoogleAddressSearchProps {
  address?: string | null;
  setAddress: (address: string) => void;
  onChange: (e: any) => void;
  userProfile?: boolean;
  placeholder?: string;
}

function GoogleAddressSearch ({ address = '', setAddress, onChange, userProfile, placeholder }: GoogleAddressSearchProps) {
  const isUserChanging = userProfile ? (e : any) => onChange({ attribute: 'address', value: e.target.value }) : (e: any) => onChange(e)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleScriptLoad = useCallback(() => {
    if (window?.google?.maps?.places && inputRef.current) {
      const input = inputRef.current

      const options = {
        types: ['address'],
        componentRestrictions: { country: 'us' },
        fields: ['formatted_address', 'geometry', 'icon', 'name'],
        strictBounds: false
      }

      const autoComplete = new window.google.maps.places.Autocomplete(input, options)
      autoComplete.setFields(['formatted_address'])
      autoComplete.addListener('place_changed', () => {
        const place = autoComplete.getPlace()
        const addressString = place.formatted_address
        setAddress(addressString)
      })
    }
  }, [setAddress])

  useEffect(() => {
    if (config.GOOGLE_API_KEY) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${config.GOOGLE_API_KEY}&libraries=places`,
        () => handleScriptLoad()
      )
    }
  }, [handleScriptLoad])

  return (
    <div>
      <input
        ref={inputRef}
        name='address'
        onChange={isUserChanging}
        placeholder={placeholder || 'Address'}
        value={address ?? ''}
        type='text'
        data-testid='address-input'
        maxLength={250}
      />
    </div>
  )
}

export default GoogleAddressSearch
