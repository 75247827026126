import { useUpdateOrganizationRoleDailyCheckinAccessMutation } from '../../shared/queryHooks'
import { getOrganizationRoleWithDailyCheckin } from '../../shared/api'
import { useQueryClient } from '@tanstack/react-query'
import { useModal } from '../../context/ModalContext'
import { PuffLoader } from 'react-spinners'
import { useState, useEffect } from 'react'
import { Checkbox } from '@mui/material'
import { getSortedContent } from '../../shared/utilities'
import Avatar from '../../components/Avatar'
import './index.css'

type FormattedRole = {
  id: number;
  dailyCheckin: boolean;
  firstName: string;
  lastName: string;
  email: string;
  avatar: string;
};

function EditOrganizationRolesDailyCheckin () {
  const queryClient = useQueryClient()
  const { clearModal } = useModal()
  const updateOrganizationRoleDailyCheckinAccess =
    useUpdateOrganizationRoleDailyCheckinAccessMutation()

  const [organizationRoles, setOrganizationRoles] = useState<FormattedRole[]>(
    []
  )
  const [organizationRoleSearch, setOrganizationRoleSearch] = useState('')

  useEffect(() => {
    const fetchOrganizationRoles = async () => {
      const organizationRoles = await getOrganizationRoles()
      const formattedRoles = organizationRoles?.map((role) => {
        return {
          id: role?.id,
          dailyCheckin: role?.dailyCheckin,
          firstName: role?.contact?.firstName,
          lastName: role?.contact?.lastName,
          email: role?.user?.email,
          avatar: role?.user?.avatar
        } as FormattedRole
      })
      setOrganizationRoles(formattedRoles)
    }
    void fetchOrganizationRoles()
  }, [])

  function handlChange ({ id, dailyCheckin }) {
    setOrganizationRoles((prevState) =>
      prevState.map((state) =>
        state.id === id ? { ...state, dailyCheckin } : state
      )
    )
  }

  async function handleSubmit () {
    await updateOrganizationRoleDailyCheckinAccess.mutateAsync(
      organizationRoles
    )
    clearModal()
  }

  async function getOrganizationRoles () {
    return await queryClient.fetchQuery(['checkin'], () =>
      getOrganizationRoleWithDailyCheckin()
    )
  }

  function organizationRoleDisplay (role) {
    return (
      <div key={role?.id} className="organization-role-daily-checkin-item">
        <div className="daily-checkin-user-item">
          <Avatar
            className="nav-avatar"
            firstName={role?.firstName}
            lastName={role?.lastName}
            avatar={role?.avatar}
          />
          <div>
            <div className="name">
              {role?.firstName} {role?.lastName}
            </div>
            <div className="email">{role?.email}</div>
          </div>
        </div>
        <Checkbox
          checked={role?.dailyCheckin}
          onChange={() =>
            handlChange({ id: role?.id, dailyCheckin: !role?.dailyCheckin })
          }
        ></Checkbox>
      </div>
    )
  }

  const organizationRolesDisplay = organizationRoles?.map((role) => {
    return organizationRoleDisplay(role)
  })

  return (
    <>
      <div className="modal-header search-bar-container">
        <input
          type="text"
          placeholder="Search..."
          className="search-bar"
          value={organizationRoleSearch}
          onChange={(e) => setOrganizationRoleSearch(e.target.value)}
        />
      </div>
      <div className="content-display">
        {organizationRoleDisplay}
        {organizationRoleSearch
          ? getSortedContent(
            organizationRoleSearch,
            organizationRoleDisplay,
            organizationRoles,
            true
          )
          : organizationRolesDisplay}
      </div>
      <div className="modal-footer">
        <div className="button secondary" onClick={() => clearModal()}>
          Cancel
        </div>
        <div className="button" onClick={() => handleSubmit()}>
          {updateOrganizationRoleDailyCheckinAccess?.isLoading
            ? (
            <PuffLoader color="#fff" size={21} />
              )
            : (
                'Save'
              )}
        </div>
      </div>
    </>
  )
}

export default EditOrganizationRolesDailyCheckin
