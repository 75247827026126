import { useParams, useSearchParams } from 'react-router-dom'
import { useValidateOrganizationInviteCodeQuery } from '../../shared/queryHooks'
import LoadingScreen from '../../components/LoadingScreen'
import { logError } from '../../shared/logger'
import { config } from '../../shared/config'
import { generateCSRF } from '../../shared/utilities'
import './index.css'

function OrganizationInvite () {
  const { inviteCode } = useParams()
  const [searchParams] = useSearchParams()
  const organizationName = searchParams.get('organizationName')
  const organizationMembersTotal = searchParams.get('organizationMembersTotal')
  const inviteCodeExists = inviteCode != null && inviteCode !== ''
  const validOrganizationMembersTotal = organizationMembersTotal != null && !isNaN(Number(organizationMembersTotal))

  const { isLoading, isError, data: validatedInviteCode, error } = useValidateOrganizationInviteCodeQuery(inviteCode ?? '', { enabled: !!inviteCodeExists })

  function handleJoinCommunity () {
    localStorage.setItem('joinCommunityIntent', inviteCode ?? '')
    const csrf = generateCSRF()
    const url = `${config.API_HOST}/auth/login?nextUrl=${config.API_HOST}/sso&csrf=${csrf}`
    window.location.replace(url)
  }

  const validInviteCodeDisplay = (
    <div style={{
      height: '90vh',
      display: 'flex',
      alignItems: 'center'
    }}>
      <div style={{
        maxWidth: '400px',
        margin: '0 auto',
        border: '2px solid var(--primary-tint-color)',
        padding: '1rem',
        borderRadius: '10px',
        fontWeight: '500',
        fontSize: '1.25rem'
      }}>
        <div style={{ marginBottom: '2rem', marginTop: '2rem' }}>You are invite to join the {organizationName} community!</div>
        <div style={{ opacity: '0.6', marginBottom: '1rem' }}>{validOrganizationMembersTotal ? `${organizationMembersTotal} members` : '0 members'}</div>
        <div className="button community-join-button" onClick={handleJoinCommunity}>Join Community</div>
      </div>
    </div>
  )

  const invalidInviteCodeDisplay = (
    <div>
      <div>Invalid invite code</div>
    </div>
  )

  if (isLoading) {
    return <LoadingScreen />
  }

  if (isError) {
    logError(error)
    return invalidInviteCodeDisplay
  }

  if (!validatedInviteCode) {
    return invalidInviteCodeDisplay
  }

  return validInviteCodeDisplay
}

export default OrganizationInvite
