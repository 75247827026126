import Addon from '../Addon'
import { Connection } from '../../../types'
import { useEffect, useRef } from 'react'
import './index.css'

interface FreedomBuckTrackerAddonProps {
  pinned: boolean
  connection: Connection
  setAsPinned: () => void
  editFreedomChecks: boolean
}

function FreedomBuckTrackerAddon ({ pinned, connection, setAsPinned, editFreedomChecks }: FreedomBuckTrackerAddonProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const isMobile = window.innerWidth < 1250
    const connectionData = { ...connection, editFreedomChecks, isMobile }
    const postMessageToIframe = () => {
      if (iframeRef.current) {
        iframeRef?.current?.contentWindow?.postMessage({ type: 'connectionData', connection: connectionData }, '*')
      }
    }

    postMessageToIframe()

    window.addEventListener('resize', postMessageToIframe)
    return () => window.removeEventListener('resize', postMessageToIframe)
  }, [connection, editFreedomChecks])

  useEffect(() => {
    const handleConnection = (event) => {
      if (event.data && event.data.type === 'iframeLoaded') {
        const isMobile = window.innerWidth < 750
        const connectionData = { ...connection, isMobile }
        iframeRef?.current?.contentWindow?.postMessage({ type: 'connectionData', connection: connectionData }, '*')
      }
    }

    window.addEventListener('message', handleConnection)
  }, [])

  return (
    <Addon title="Freedom Checks" pinned={pinned} setAsPinned={setAsPinned}>
      <iframe
        className="iframe"
        ref={iframeRef}
        src={connection.space?.spacePlugins?.[0]?.plugin?.url ?? ''}
      />
    </Addon>
  )
}

export default FreedomBuckTrackerAddon
