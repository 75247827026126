import { IconButton, Menu, MenuItem } from '@mui/material'
import { MoreHoriz, Edit, Delete } from '@mui/icons-material'
import { useModal } from '../../context/ModalContext'
import DeleteCommentOrPost from '../../modals/DeleteCommentOrPost'
import { useState } from 'react'

interface PostActionsProps {
  postId: number
  canEdit: boolean
  onEditClick: () => void
  isEvent?: boolean
}

function PostActions ({ postId, canEdit, onEditClick, isEvent }: PostActionsProps) {
  const { makeModal } = useModal()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!canEdit) return null

  return (
    <div style={{ marginRight: '-0.25rem' }}>
      <IconButton
        size="small"
        color="inherit"
        className="post-controls"
        onClick={handleClick}
      >
        <MoreHoriz fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {!isEvent && <MenuItem onClick={() => {
          onEditClick()
          handleClose()
        }}>
          <Edit fontSize="small" sx={{ mr: 1 }} />
          Edit
        </MenuItem>}
        <MenuItem onClick={() => {
          makeModal({
            modal: <DeleteCommentOrPost postId={postId} />,
            title: 'Are you sure?'
          })
          handleClose()
        }}>
          <Delete fontSize="small" sx={{ mr: 1 }} />
          Delete
        </MenuItem>
      </Menu>
    </div>
  )
}

export default PostActions
