import { getCurrentOrganizationRole } from '../../shared/utilities'
import { useModal } from '../../context/ModalContext'
import { useRef } from 'react'
import { useAuthContext } from '../../context/AuthContext'
import { Paper, MenuList, MenuItem, ListItemText } from '@mui/material'
import { roles } from '../../shared/enums'
import PersonStatus from '../../modals/PersonStatus'
import ResendInvite from '../../modals/ResendInvite'
import ManageUserSpaces from '../../modals/ManageUserSpaces'
import AddOrEditPerson from '../../modals/AddOrEditPerson'
import AddOrEditPersonTags from '../../modals/AddOrEditPersonTags'
import './index.css'
import { InvitationStatus, OrganizationRole } from '../../types'

interface PersonSettingsProps {
  person: OrganizationRole
  personSelection: any
  useOutsideAlerter: any
  owners: OrganizationRole[]
}

function PersonSettings ({ person, personSelection, useOutsideAlerter, owners }: PersonSettingsProps) {
  const { makeModal } = useModal()
  const { currentUser } = useAuthContext()
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)
  const actionStatus = person?.status === 'ACTIVE' ? 'Deactivate' : 'Activate'
  const actionClass = person?.status === 'ACTIVE' ? 'danger' : ''

  const isOwner = person?.title === roles.owner
  const loggedInUserOrgRole = getCurrentOrganizationRole(currentUser)
  const isLoggedInUser = loggedInUserOrgRole?.id === person?.id
  const isActiveUser = person?.status === 'ACTIVE'
  const tagDisplayText = person?.contact?.tags !== null && person?.contact?.tags !== undefined && person.contact.tags.length > 0 ? 'Edit tags' : 'Add tag'

  const canEditStatus = !isLoggedInUser && !isOwner
  const canEditPermissions = !isOwner || (isLoggedInUser && owners?.length > 1)

  return (
    <div className="people-settings dropdown" ref={wrapperRef}>
      <Paper
        sx={{ width: 175 }}
        elevation={24}>
        <MenuList dense data-testid="organization-menu" ref={wrapperRef}>
          {isActiveUser && <div>
            {canEditPermissions && <MenuItem
              data-testid="edit-person-button"
              onClick={() => makeModal({
                modal: <AddOrEditPerson existingPerson={person} />,
                title: 'Edit Person',
                disableFocus: true
              })}>
              <ListItemText>Edit person</ListItemText>
            </MenuItem>}
            <MenuItem
              data-testid="edit-member-spaces-button"
              onClick={() => makeModal({
                modalSize: 'LARGE',
                modal: <ManageUserSpaces person={person}/>
              })}>
              <ListItemText>Edit spaces</ListItemText>
            </MenuItem>
            <MenuItem
              data-testid="organization-user-tags-button"
              onClick={() => makeModal({
                modal: <AddOrEditPersonTags contact={person.contact}/>,
                title: tagDisplayText,
                disableFocus: true
              })}>
              <ListItemText>{tagDisplayText}</ListItemText>
            </MenuItem>
            {person.invitationStatus === InvitationStatus.Pending && <MenuItem
              data-testid="organization-user-resend-invite-button"
              onClick={() => makeModal({
                modal: <ResendInvite person={person}/>
              })}>
              <ListItemText>Resend Invite</ListItemText>
            </MenuItem>}
          </div>}
          {canEditStatus && (
            <MenuItem
              data-testid="edit-status-button"
              onClick={() => makeModal({
                modal: <PersonStatus person={person} personSelection={personSelection} />,
                title: 'Are you sure?'
              })}>
              <ListItemText><span className={actionClass}>{actionStatus}</span></ListItemText>
            </MenuItem>)}
        </MenuList>
      </Paper>
    </div>
  )
}

export default PersonSettings
