import ManagePeopleDropDown from '../ManagePeopleDropDown'
import { useState, useRef } from 'react'
import { OrganizationRole } from '../../types'
import { useOutsideAlerter } from '../../shared/utilities'

interface ManagePeopleMenuProps {
  organizationRoles: OrganizationRole[];
}

function ManagePeopleMenu ({ organizationRoles }: ManagePeopleMenuProps) {
  const [displayManagePeopleMenuDropDown, setDisplayManagePeopleMenuDropDown] = useState(false)
  const wrapperRef = useRef(null)
  useOutsideAlerter({ ref: wrapperRef, setDisplayState: setDisplayManagePeopleMenuDropDown })

  return (
    <div ref={wrapperRef}>
      <div className="button transparent"
        style={{ paddingRight: '0' }}
        onClick={() => setDisplayManagePeopleMenuDropDown(!displayManagePeopleMenuDropDown)}>
        Manage People
      </div>
      {displayManagePeopleMenuDropDown && <div className="settings-wrapper" style={{ right: '-27px' }}>
       <ManagePeopleDropDown organizationRoles={organizationRoles}></ManagePeopleDropDown>
      </div>}
    </div>
  )
}

export default ManagePeopleMenu
