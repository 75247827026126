import { Download } from '@mui/icons-material'
import { ReportAchievement } from '../../types'
import Button from '../Button'
import { getFileDate, makeAchievementCsvRows } from './utils'

const downloadAsCsv = (achievements) => {
  const headerRow = ['Recipient', 'Achievement', 'Space']
  const csvRows = [headerRow, ...makeAchievementCsvRows(achievements)]
  const csvContent = csvRows.map(e => e?.join(',')).join('\n')
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  const url = URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.href = url
  link.download = `${getFileDate()}_weekly_achievements.csv`
  link.target = '_blank'
  link.click()
}

export const DownloadWeeklyReportCsv = ({
  achievements
}: {
  achievements: ReportAchievement[];
}) => {
  return (
    <Button className='csv-download' secondary size="sm" onClick={() => downloadAsCsv(achievements)}>
      <Download fontSize="small" /> Download CSV
    </Button>
  )
}
