import './index.css'
import { OrganizationRole, SpaceRole } from '../../types'

interface ManageUserSpaceRolesProps {
  spaceRole: SpaceRole
  selectRole: (title: string) => void,
  person: OrganizationRole
}

function ManageUserSpaceRoles ({ spaceRole, selectRole, person }: ManageUserSpaceRolesProps) {
  const isGuest = person?.title === 'GUEST'
  return (
    <div className="role-container">
      <select value={spaceRole.title} onChange={(e) => selectRole(e.target.value)}>
        <option value="MEMBER">Participant</option>
        {!isGuest && <option value="LEADER">Host</option>}
      </select>
    </div>
  )
}

export default ManageUserSpaceRoles
