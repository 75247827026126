import { useRef } from 'react'
import { Paper, MenuList, MenuItem, ListItemText } from '@mui/material'
import DeleteAction from '../../modals/DeleteAction'
import { useNavigate } from 'react-router-dom'
import CreateOrEditAction from '../../modals/CreateOrEditAction'
import { useModal } from '../../context/ModalContext'
import './index.css'
import { DisplayState, OutsideAlerterArgs } from '../../shared/utilities'
import { Entity } from '../../shared/enums'

interface ActionSettingsMenuItemsProps {
  actionId: number
  currentUser: any
  existingAction: any
  rescheduleConnection?: any
  useOutsideAlerter: (args: OutsideAlerterArgs) => void
  setActionMenu: DisplayState<number | null>
}

function ActionSettingsMenuItems ({ useOutsideAlerter, actionId, currentUser, setActionMenu, existingAction, rescheduleConnection }: ActionSettingsMenuItemsProps) {
  const wrapperRef = useRef(null)
  useOutsideAlerter({ ref: wrapperRef, setDisplayState: setActionMenu })
  const navigate = useNavigate()
  const { makeModal, clearModal } = useModal()

  return (
    <div className='goal-settings dropdown' ref={wrapperRef}>
      <Paper
        sx={{ width: 175 }}
        elevation={24}>
        <MenuList dense ref={wrapperRef}>
          {existingAction?.actionType === 'CONNECT'
            ? <div>
                <MenuItem
                  onClick={() => rescheduleConnection(existingAction?.connection)}>
                  <ListItemText>Reschedule {Entity.Event}</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={() => navigate(`/connections/${existingAction?.connection?.uid}/?action=true`)}>
                  <ListItemText>Prepare {Entity.Event}</ListItemText>
                </MenuItem>
              </div>
            : <div>
                <MenuItem
                  onClick={() => makeModal({
                    modal: <CreateOrEditAction clearModal={clearModal} setActionMenu={setActionMenu} existingAction={existingAction}/>,
                    title: 'Edit Task'
                  })}>
                  <ListItemText>Edit Task</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={() => makeModal({
                      modal: <DeleteAction currentUser={currentUser} setActionMenu={setActionMenu} clearModal={clearModal} id={actionId} description={existingAction?.description}/>,
                      title: 'Delete Task'
                    })}>
                  <ListItemText>Delete Task</ListItemText>
                </MenuItem>
              </div>
            }
        </MenuList>
      </Paper>
    </div>
  )
}

export default ActionSettingsMenuItems
