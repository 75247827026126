import { useEffect, useState } from 'react'
import { config } from '../../shared/config'
import { SpaceMember } from '../../types/DataTypes'
import { getPeople, getFreedomCheckConnection } from '../../shared/api'
import { useQueryClient } from '@tanstack/react-query'
import { DownloadWeeklyReportCsv } from './DownloadWeeklyFreedomChecksCsv'
import { DownloadWeeklyReportPdf } from './DownloadWeeklyFreedomChecksPdf'
import Table from '../Table'

function WeeklyFreedomChecks ({ startTime, endTime, organizationId, timePeriod }) {
  const [data, setData] = useState<SpaceMember[] | null>()
  const queryClient = useQueryClient()
  const title = 'Freedom Checks'

  async function getFreedomCheckReport (startTime: string, endTime: string) {
    try {
      const response = await fetch(`${config.FREEDOM_CHECK_API_HOST}/report?startTime=${startTime}&endTime=${endTime}&organizationId=${organizationId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      })
      return await response.json()
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  useEffect(() => {
    const fetchFreedomCheckReport = async () => {
      if (startTime && endTime) {
        try {
          const report = await getFreedomCheckReport(startTime, endTime)
          const organizationRoles = await queryClient.fetchQuery([`organization:${organizationId}`, 'people'], () =>
            getPeople()
          )

          const nameToReport = await Promise.all(report.map(async spaceMember => {
            const matchingRole = organizationRoles.find(role => role.id === spaceMember.organizationRoleId)
            if (matchingRole) {
              const promises = spaceMember.connectIds.map((id) =>
                queryClient.fetchQuery(['freedomCheckConnection', id], () => getFreedomCheckConnection(id))
              )
              const roleConnections = await Promise.all(promises)
              const spaces = [...new Set(roleConnections.filter(connection => connection?.spaceName).map(connection => connection.spaceName))]
              return {
                ...spaceMember,
                name: `${matchingRole?.contact?.firstName} ${matchingRole?.contact?.lastName}`,
                spaces
              }
            }
            return spaceMember
          }))

          nameToReport.sort((a, b) => {
            const spaceA = a.spaces.join(', ')
            const spaceB = b.spaces.join(', ')
            if (spaceA === spaceB) {
              return 0
            }
            return spaceA < spaceB ? -1 : 1
          })

          setData(nameToReport)
        } catch (error) {
          console.error('Failed to fetch report:', error)
        }
      }
    }
    void fetchFreedomCheckReport()
  }, [startTime, endTime])

  const columns = [
    {
      header: 'Name',
      accessorKey: 'name',
      cell: ({ row }) => (<div style={{ padding: '10px 0px' }}>{row.original.name}</div>)
    },
    {
      header: 'Spaces',
      accessorKey: 'spaces',
      cell: ({ row }) => (<div style={{ padding: '10px 0px' }}>{row.original.spaces.join(', ')}</div>)
    }
  ]

  if (data === null || data === undefined) {
    return null
  }

  return (
    <div className="weekly-achievements-container">
      <div className="weekly-report-meta">
        <div>
          <h2 style={{ marginTop: 0 }}>Freedom Bucks</h2>
          <h4>{timePeriod}</h4>
        </div>
        {data.length > 0 && (
          <div className="download-buttons">
            <DownloadWeeklyReportPdf
              freedomBucks={data}
              timePeriod={timePeriod}
            />
            <DownloadWeeklyReportCsv freedomBucks={data} />
          </div>
        )}
      </div>
      <Table
        columns={columns}
        data={data}
        typeReferenceText={title}
        requiresSearch={false}
        tableWrapperClass='weekly-achievements-container'
      />
    </div>
  )
}

export default WeeklyFreedomChecks
