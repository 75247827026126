import {
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate
} from 'react-router-dom'
import { INVITE_CODE_KEY, useAuthContext } from '../context/AuthContext'
import Layout from '../pages/Layout'
import Login from '../pages/Login'
import Pathway from '../pages/Pathway'
import Profile from '../pages/Profile'
import Spaces from '../pages/Spaces'
import Space from '../pages/Space'
import Events from '../pages/Events'
import ActionsPage from '../pages/ActionsPage'
import ErrorPage from '../pages/ErrorPage'
import Connection from '../pages/Connection'
import Insights from '../pages/Insights'
import WorkspaceSettingsPeople from '../pages/OrganizationSettings/People'
import WorkspaceSettingsPerson from '../pages/OrganizationSettings/Person'
import LoadingScreen from '../components/LoadingScreen'
import CourseDetail from '../pages/CourseDetail'
import PublicLayout from '../pages/PublicLayout'
import OrganizationConnect from '../pages/OrganizationConnect'
import OrganizationNew from '../pages/OrganizationNew'
import OrganizationSettingsLayout from '../pages/OrganizationSettingsLayout'
import ProfileSettingsGeneral from '../pages/ProfileSettingsGeneral'
import PersonCourseProgress from '../pages/PersonCourseProgress'
import ReportsWeeklyDetail from '../pages/OrganizationSettings/ReportsWeeklyDetail'
import Coaching from '../pages/Coaching'
import Posts from '../pages/Posts'
import {
  getActiveOrganizations,
  getCurrentOrganization,
  isNewUserWithOrganization
} from '../shared/utilities'
import { isAuthorized } from '../shared/permissions'
import { getOrganizationAdminPolicy } from '../shared/policies'
import OrganizationSettingsBilling from '../pages/OrganizationSettings/Billing'
import Library from '../pages/OrganizationSettings/Library'
import OrganizationSettingsIntegrations from '../pages/OrganizationSettings/SettingsIntegrations'
import OrganizationSettingsActions from '../pages/OrganizationSettings/SettingsActions'
import MarketPlace from '../pages/MarketPlace'
import Learn from '../pages/Learn'
import { useModal } from '../context/ModalContext'
import { useEffect, useState } from 'react'
import ActivateOrganization from '../modals/ActivateOrganization'
import Goals from '../pages/Goals'
import CourseProgress from '../pages/CourseProgress'
import ReportsWeeklyOverview from '../pages/OrganizationSettings/ReportsWeeklyOverview'
import './index.css'
import Register from '../pages/Register'
import SSO from '../pages/SSO'
import { Feature } from '../shared/features'
import { isFeatureEnabled } from '../shared/posthog'
import Invite from '../pages/Invite'
import { OrganizationSettings } from '../pages/OrganizationSettings'
import WorkspaceSettingsSpaces from '../pages/OrganizationSettings/Spaces'
import { PeopleGallery } from '../pages/PeopleGallery'
import ClearStorage from '../pages/ClearStorage'
import InviteClaim from '../pages/InviteClaim'
import OrganizationInvite from '../pages/OrganizationInvite'
import OrganizationInviteClaim from '../pages/OrganizationInviteClaim'

function RedirectPersonDetails () {
  const { id } = useParams<{ id: string }>()
  return <Navigate to={`/communities/settings/people/${id}`} replace />
}

function RedirectCourseDetails () {
  const { id, courseVersionId } = useParams<{
    id: string;
    courseVersionId: string;
  }>()
  return (
    <Navigate
      to={`/communities/settings/people/${id}/course/${courseVersionId}`}
      replace
    />
  )
}

function App () {
  const {
    isLoading: isAuthLoading,
    currentUser,
    isInitialLoading: isCurrentUserLoading,
    isSigningOut
  } = useAuthContext()
  const { makeModal } = useModal()

  const currentOrganization = getCurrentOrganization(currentUser)
  const activeOrganizationRoles = currentUser?.organizationRoles?.filter(
    (role) => role.status !== 'INACTIVE'
  )
  const [displayTrialBanner, setDisplayTrialBanner] = useState(true)
  const navigate = useNavigate()
  const inviteCode = localStorage.getItem(INVITE_CODE_KEY) ?? ''
  const shouldFinishInviteClaim =
    inviteCode &&
    currentUser &&
    !window.location.pathname.startsWith('/invite')
  const shouldSetupNewCommunity =
    currentUser &&
    (!activeOrganizationRoles || activeOrganizationRoles.length < 1)
  const shouldReactivateOrganization =
    currentUser && currentOrganization?.frozen
  const isJoinIntent = localStorage.getItem('joinCommunityIntent') ?? ''

  useEffect(() => {
    if (isJoinIntent && isJoinIntent !== ' ') {
      navigate('/communities/invite/claim')
    } else if (shouldReactivateOrganization) {
      makeModal({
        modal: <ActivateOrganization />,
        modalClose: false
      })
    } else if (shouldFinishInviteClaim) {
      navigate('/finish-invite-claim')
    } else if (shouldSetupNewCommunity) {
      navigate('/communities/new')
    }
  }, [currentOrganization, currentUser, inviteCode, navigate])

  const isAdminOrOwner =
    !!currentUser &&
    isAuthorized(
      getOrganizationAdminPolicy(currentUser?.currentOrganizationId),
      currentUser
    )
  const isNormalUser = !!currentUser
  const isUserWithoutOrganizations =
    !!currentUser &&
    getActiveOrganizations(currentUser).length === 0 &&
    !isNewUserWithOrganization(currentUser)

  if (isAuthLoading || isCurrentUserLoading) {
    return <LoadingScreen />
  }

  if (isUserWithoutOrganizations) {
    return (
      <Routes>
        <Route path="/clear-storage" element={<ClearStorage />} />
        <Route path="/" element={<PublicLayout />}>
          <Route path="/communities/invite/claim" element={<OrganizationInviteClaim />} />
          <Route path="/course/:uid" element={<CourseDetail />} />
          <Route
            path="/communities/connect"
            element={<OrganizationConnect />}
          />
          <Route path="/communities/new" element={<OrganizationNew />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/invite/:inviteCode" element={<Invite />} />
          <Route path="/finish-invite-claim" element={<InviteClaim />} />
          <Route path="*" element={<Register />} />
        </Route>
      </Routes>
    )
  }

  if (isAdminOrOwner) {
    return (
      <Routes>
        <Route
          path="/"
          element={
            <Layout
              currentUser={currentUser}
              displayTrialBanner={displayTrialBanner}
              setDisplayTrialBanner={setDisplayTrialBanner}
            />
          }
        >
          <Route path="/" element={<Posts />} />
          <Route path="/sso" element={<Navigate to="/" replace />} />
          <Route path="/login" element={<Navigate to="/" replace />} />
          <Route path="/courses" element={<Pathway />} />
          <Route path="/spaces" element={<Spaces />} />
          <Route path="/events" element={<Events />} />
          <Route path="/actions" element={<ActionsPage />} />
          <Route path="/spaces/:id" element={<Space />} />
          <Route path="/courses/:id" element={<CourseProgress />} />
          <Route path="/profile" element={<Profile />} />
          <Route
            path="/profile/settings"
            element={<ProfileSettingsGeneral />}
          />
          {isFeatureEnabled(Feature.Insights) && (
            <Route path="/insights" element={<Insights />} />
          )}
          <Route path="/people/:id" element={<RedirectPersonDetails />} />
          <Route
            path="/people/:id/course/:courseVersionId"
            element={<RedirectCourseDetails />}
          />
          <Route path="/people" element={<PeopleGallery />} />
          {isFeatureEnabled(Feature.Learn) && (
            <Route path="/learn" element={<Learn />} />
          )}
          {isFeatureEnabled(Feature.Coaching) && (
            <Route path="/coaching" element={<Coaching />} />
          )}
          <Route
            path="*"
            element={<ErrorPage message={'404, this page does not exist'} />}
          />
        </Route>
        <Route path="/connections/:uid" element={<Connection />} />
        <Route
          path="/"
          element={
            <OrganizationSettingsLayout
              currentUser={currentUser}
              displayTrialBanner={displayTrialBanner}
              setDisplayTrialBanner={setDisplayTrialBanner}
            />
          }
        >
          <Route
            path="/communities/settings"
            element={<OrganizationSettings />}
          />
          <Route
            path="/communities/settings/billing"
            element={<OrganizationSettingsBilling />}
          />
          <Route path="/communities/settings/goals" element={<Goals />} />
          <Route
            path="/communities/settings/reports"
            element={<ReportsWeeklyOverview />}
          />
          <Route
            path="/communities/settings/integrations"
            element={<OrganizationSettingsIntegrations />}
          />
          <Route
            path="/communities/settings/actions"
            element={<OrganizationSettingsActions />}
          />
          <Route
            path="/communities/settings/spaces"
            element={<WorkspaceSettingsSpaces />}
          />
          <Route
            path="/communities/settings/spaces/:id"
            element={<Space inOrganizationSettings />}
          />
          <Route path="/communities/settings/library" element={<Library />} />
          <Route
            path="/communities/settings/courses/marketplace"
            element={<MarketPlace />}
          />
          <Route
            path="/communities/settings/people"
            element={<WorkspaceSettingsPeople />}
          />
          <Route
            path="/communities/settings/people/:id"
            element={<WorkspaceSettingsPerson />}
          />
          <Route
            path="/communities/settings/people/:id/course/:courseVersionId"
            element={<PersonCourseProgress />}
          />
          <Route
            path="/communities/settings/reports/weekly"
            element={<ReportsWeeklyDetail />}
          />
        </Route>
        <Route path="/clear-storage" element={<ClearStorage />} />
        <Route path="/" element={<PublicLayout />}>
          <Route path="/invite/:inviteCode" element={<Invite />} />
          <Route path="/finish-invite-claim" element={<InviteClaim />} />
          <Route path="/course/:uid" element={<CourseDetail />} />
          <Route
            path="/communities/connect"
            element={<OrganizationConnect />}
          />
          <Route path="/communities/new" element={<OrganizationNew />} />
        </Route>
      </Routes>
    )
  }

  if (isNormalUser) {
    return (
      <Routes>
        <Route
          path="/"
          element={
            <Layout
              currentUser={currentUser}
              displayTrialBanner={displayTrialBanner}
              setDisplayTrialBanner={setDisplayTrialBanner}
            />
          }
        >
          <Route path="/" element={<Posts />} />
          <Route path="/login" element={<Navigate to="/" replace />} />
          <Route path="/sso" element={<Navigate to="/" replace />} />
          <Route path="/courses" element={<Pathway />} />
          <Route path="/events" element={<Events />} />
          <Route path="/actions" element={<ActionsPage />} />
          <Route path="/spaces" element={<Spaces />} />
          <Route path="/spaces/:id" element={<Space />} />
          <Route path="/courses/:id" element={<CourseProgress />} />
          <Route path="/profile" element={<Profile />} />
          <Route
            path="/profile/settings"
            element={<ProfileSettingsGeneral />}
          />
          <Route path="/people/:id" element={<WorkspaceSettingsPerson />} />
          <Route path="/people" element={<PeopleGallery />} />
          {isFeatureEnabled(Feature.Learn) && (
            <Route path="/learn" element={<Learn />} />
          )}
          {isFeatureEnabled(Feature.Coaching) && (
            <Route path="/coaching" element={<Coaching />} />
          )}
          <Route
            path="*"
            element={<ErrorPage message={'404, this page does not exist'} />}
          />
        </Route>
        <Route path="/connections/:uid" element={<Connection />} />
        <Route path="/clear-storage" element={<ClearStorage />} />
        <Route path="/" element={<PublicLayout />}>
          <Route path="/invite/:inviteCode" element={<Invite />} />
          <Route path="/finish-invite-claim" element={<InviteClaim />} />
          <Route path="/course/:uid" element={<CourseDetail />} />
          <Route
            path="/communities/connect"
            element={<OrganizationConnect />}
          />
          <Route path="/communities/new" element={<OrganizationNew />} />
        </Route>
      </Routes>
    )
  }

  if (!isSigningOut) {
    return (
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sso" element={<SSO />} />
        <Route path="/clear-storage" element={<ClearStorage />} />
        <Route path="/" element={<PublicLayout />}>
          <Route path="/invite/:inviteCode" element={<Invite />} />
          <Route path="/finish-invite-claim" element={<InviteClaim />} />
          <Route path="/communities/invite/:inviteCode" element={<OrganizationInvite />} />
          <Route path="/course/:uid" element={<CourseDetail />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Route>
      </Routes>
    )
  }

  return <></>
}

export default App
