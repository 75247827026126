import { useState } from 'react'
import ManageUserSpaceRoles from '../ManageUserSpaceRoles'
import { DisplayState } from '../../shared/utilities'
import './index.css'
import { OrganizationRole, Space, SpaceRole } from '../../types'
import Info from '@mui/icons-material/Info'
import { useAuthContext } from '../../context/AuthContext'
import { useOrganizationSubscriptionQuery, useEditPersonMutation, useUpdateOrganizationCourseAccessMutation } from '../../shared/queryHooks'

interface PersonItemProps {
  person: OrganizationRole
  space: Space
  setSpace: DisplayState<Space>
}

function PersonItem ({ person, space, setSpace }: PersonItemProps) {
  const currentSpaceRole = space?.spaceRoles?.find((spaceRole) => spaceRole?.organizationRoleId === person?.id)
  const { currentUser } = useAuthContext()
  const defaultSpaceRole: SpaceRole = {
    id: 0,
    spaceId: space?.id ?? 0,
    organizationRole: { ...person, contactId: person?.contact?.id ?? 0, title: 'MEMBER' },
    organizationRoleId: person?.id,
    title: 'MEMBER'
  }
  const hasCourse = space?.courseVersionId != null
  const hasCourseAccess: boolean =
    hasCourse &&
    JSON.parse(space?.courseVersion?.organizationCourse?.[0]?.organizationRoleAccess ?? '{}')?.filter(
      (role) => role?.organizationRoleId === person?.id && role?.access === true
    )?.length > 0
  const hasSpaceRoleLimit: boolean =
    person?.title === 'GUEST' && person?.spaceRoles?.length === 1 && person?.spaceRoles[0]?.spaceId !== space?.id
  const { data: billingPlan } = useOrganizationSubscriptionQuery(currentUser?.currentOrganizationId ?? 0)
  const editPerson = useEditPersonMutation()
  const updatePersonCourseAccess = useUpdateOrganizationCourseAccessMutation()

  const [checked, setChecked] = useState<boolean>(currentSpaceRole != null)
  const [spaceRole, setSpaceRole] = useState(currentSpaceRole || defaultSpaceRole)
  const [showGuestConvert, setShowGuestConvert] = useState<boolean>(false)
  const [showAddToCourse, setShowAddToCourse] = useState<boolean>(false)

  const togglePerson = (organizationRoleId) => {
    if (checked) {
      const newList = space?.spaceRoles?.filter((spaceRole) => spaceRole?.organizationRoleId !== organizationRoleId)
      setSpace((prev) => ({ ...prev, spaceRoles: newList }))
    } else {
      setSpace((prev) => ({ ...prev, spaceRoles: [...(prev?.spaceRoles ?? []), { ...spaceRole }] }))
    }
    setChecked((prev) => !prev)
  }

  const getBillableIncreaseAmount = () => {
    const isBilledMonthly = billingPlan?.interval === 'month'
    return `${isBilledMonthly ? '$4' : '$40'} per ${isBilledMonthly ? 'month' : 'year'}`
  }

  const toggleShowGuestConvert = () => {
    setShowGuestConvert((prev) => !prev)
  }

  const toggleAddToCourse = () => {
    setShowAddToCourse((prev) => !prev)
  }

  const convertGuest = async () => {
    person.title = 'MEMBER'
    await editPerson.mutateAsync(person)
    await addToCourse()
  }

  const addToCourse = async () => {
    const orgCourse = space?.courseVersion?.organizationCourse?.find(op => op?.organizationId === currentUser?.currentOrganizationId)
    if (!orgCourse) return
    const orgRoleAccess = JSON.parse(orgCourse.organizationRoleAccess ?? '{}')
    const personAccess = orgRoleAccess?.find(role => role?.organizationRoleId === person?.id)
    if (personAccess) {
      personAccess.access = true
    } else {
      orgRoleAccess.push({ organizationRoleId: person?.id, access: true })
    }
    orgCourse.organizationRoleAccess = JSON.stringify(orgRoleAccess)
    await updatePersonCourseAccess.mutateAsync({ organizationId: currentUser?.currentOrganizationId, body: space?.courseVersion?.organizationCourse })
    togglePerson(person?.id)
  }

  const rightColumnContent = () => {
    if (hasSpaceRoleLimit) {
      return (
        <div className="cannot-add-message" onClick={toggleShowGuestConvert}>
          Guest cannot be added
          <Info fontSize="small" />
        </div>
      )
    } else if (hasCourse && !hasCourseAccess) {
      return (
        <div className="cannot-add-message" onClick={toggleAddToCourse}>
          User cannot be added
          <Info fontSize="small" />
        </div>
      )
    } else if (checked) {
      return <ManageUserSpaceRoles selectRole={selectRole} spaceRole={spaceRole} person={person} />
    } else {
      return <></>
    }
  }

  function selectRole (title) {
    setSpaceRole((prev) => ({ ...prev, title }))
    setSpace((prev) => ({
      ...prev,
      spaceRoles: prev?.spaceRoles?.map((spaceRole) =>
        spaceRole?.organizationRoleId === person?.id ? { ...spaceRole, title } : spaceRole
      )
    }))
  }

  return (
    <div>
      <div className="person-card-container">
        <div className="inner-person-card">
          <label style={{ display: 'flex', alignItems: 'center' }}>
            <input
              value={person?.id}
              checked={checked}
              onChange={(e) => !hasSpaceRoleLimit && (hasCourseAccess || !hasCourse) && togglePerson(parseInt(e.target.value))}
              type="checkbox"
              className={hasSpaceRoleLimit || (hasCourse && !hasCourseAccess) ? 'person-checkbox visibility' : 'person-checkbox'}
            />
            <div className="person-name">
              {person?.contact?.firstName} {person?.contact?.lastName}
            </div>
          </label>
        </div>
        {rightColumnContent()}
      </div>
      {hasSpaceRoleLimit && showGuestConvert && (
        <div className="action-items">
          This guest account is already a member of another space. You can convert this user to a member to add them to
          this space. This will increase your monthly subscription cost by {getBillableIncreaseAmount()}.
          <span className="action-link" onClick={convertGuest}>
            Convert them now?
          </span>
        </div>
      )}
      {(hasCourse && !hasCourseAccess) && showAddToCourse && (
        <div className="action-items">
          This user does not have access to the course tied to this space. If you add them to the space they will
          automatically be given access to the course.
          <span className="action-link" onClick={addToCourse}>
            Add them now?
          </span>
        </div>
      )}
    </div>
  )
}

export default PersonItem
