import { Box } from '@mui/material'
import './index.css'
import { useModal } from '../../context/ModalContext'
import MediaDisplay from '../../modals/MediaDisplay'
import { useRef } from 'react'
import {
  isYouTubeUrl,
  getYouTubeVideoId,
  isVimeoUrl,
  getVimeoVideoId
} from '../../shared/utilities'

interface PostPreviewData {
  title: string | null;
  description: string | null;
  image: string | null;
  url: string | null;
  type: 'link' | 'image' | 'video';
  isPreview?: boolean;
  allUrls: string[];
  index?: number;
}

function PostPreview ({
  title,
  description,
  image,
  url,
  type,
  isPreview = false,
  allUrls,
  index
}: PostPreviewData) {
  const { makeModal } = useModal()
  const videoRef = useRef<HTMLVideoElement>(null)

  const previewStyles = isPreview ? 'preview-styles' : 'post-media-styles'

  const makeBorderRadius = () => {
    if (allUrls.length <= 1) return '1rem'

    const corners = {
      2: ['1rem 0 0 1rem', '0 1rem 1rem 0'],
      3: ['1rem 0 0 1rem', '0 0 0 0', '0 1rem 1rem 0'],
      4: ['1rem 0 0 0', '0 1rem 0 0', '0 0 0 1rem', '0 0 1rem 0']
    }

    const layoutSize = Math.min(allUrls.length, 4)
    return corners[layoutSize as keyof typeof corners][index ?? 0]
  }

  const mediaStyles = {
    width: '100%',
    height: isPreview ? '120px' : '100%',
    objectFit: 'cover' as const,
    maxHeight: !isPreview && allUrls.length === 1 ? '100%' : undefined,
    borderRadius: makeBorderRadius(),
    border: 'none',
    backgroundColor: 'var(--primary-background-color)'
  }

  const handleClick = (event: React.MouseEvent) => {
    if (!isPreview) {
      event.preventDefault()
      if (videoRef.current) {
        videoRef.current.pause()
      }
      makeModal({
        modalSize: 'LARGE',
        modal: (
          <MediaDisplay
            contentUrls={allUrls}
            initialIndex={allUrls.indexOf(url ?? '')}
          />
        ),
        modalClose: true
      })
    }
  }

  const handleVideoClick = (event: React.MouseEvent<HTMLVideoElement>) => {
    const target = event.target as HTMLVideoElement
    const rect = target.getBoundingClientRect()
    const clickY = event.clientY - rect.top

    if (clickY > rect.height - 40) {
      event.stopPropagation()
    } else {
      handleClick(event)
    }
  }

  switch (type) {
    case 'image':
      return (
        <Box
          className={previewStyles}
          onClick={handleClick}
          style={{
            cursor: isPreview ? 'default' : 'pointer',
            height: !isPreview && allUrls.length === 1 ? '100%' : undefined
          }}
        >
          <div
            style={{
              ...mediaStyles,
              backgroundImage: `url(${image ?? ''})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              aspectRatio: allUrls.length === 1 ? '1/1' : undefined
            }}
          />
        </Box>
      )
    case 'video': {
      const isYoutube = isYouTubeUrl(url ?? '')
      const isVimeo = isVimeoUrl(url ?? '')
      return (
        <Box
          className={previewStyles}
          style={{
            cursor: isPreview ? 'default' : 'pointer',
            height: !isPreview && allUrls.length === 1 ? '100%' : undefined,
            aspectRatio: allUrls.length === 1 ? '16/9' : undefined,
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          {isYoutube
            ? (
                <iframe
                  width="100%"
                  height="100%"
                  src={`https://www.youtube.com/embed/${getYouTubeVideoId(
                    url ?? ''
                  )}`}
                  style={mediaStyles}
                  allow="encrypted-media; autoplay"
                  allowFullScreen
                  onClick={handleClick}
                />
              )
            : isVimeo
              ? (
                  <iframe
                    width="100%"
                    height="100%"
                    src={`https://player.vimeo.com/video/${getVimeoVideoId(
                      url ?? ''
                    )}`}
                    style={mediaStyles}
                    allow="fullscreen; autoplay"
                    allowFullScreen
                    onClick={handleClick}
                  />
                )
              : (
                  <video
                    ref={videoRef}
                    src={url ?? ''}
                    style={{
                      ...mediaStyles,
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%'
                    }}
                    controls={!isPreview}
                    preload="metadata"
                    onClick={handleVideoClick}
                  />
                )}
        </Box>
      )
    }
    case 'link':
      return (
        <div className={previewStyles}>
          {image && <img src={image} alt={title || ''} style={mediaStyles} />}
          <a href={url ?? ''} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
          {isPreview && <p>{description}</p>}
        </div>
      )
    default:
      return null
  }
}

export default PostPreview
