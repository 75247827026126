import { isAuthorized } from '../../shared/permissions'
import { getOrganizationOwnerPolicy, getOrganizationAdminPolicy } from '../../shared/policies'
import { useAuthContext } from '../../context/AuthContext'
import { Entity, roles } from '../../shared/enums'
import './index.css'
import { OrganizationRole } from '../../types'
import { Checkbox } from '@mui/material'
import { getCurrentOrganizationRole, getCurrentOrganization } from '../../shared/utilities'

interface SelectPermissionProps {
  person: OrganizationRole
  updatePerson: (person: Partial<OrganizationRole>) => void
  prevTitle?: string
  spaceId?: number
}

function SelectPermission ({ person, updatePerson, prevTitle, spaceId } : SelectPermissionProps) {
  const { currentUser } = useAuthContext()
  const currentOrganizationRole = getCurrentOrganizationRole(currentUser)
  const currentOrganization = getCurrentOrganization(currentUser)
  const isAdminOrAbove = isAuthorized(getOrganizationAdminPolicy(currentUser?.currentOrganizationId), currentUser)

  const organizationRolesKey = {
    [roles.guest]: {
      value: roles.guest,
      displayTitle: 'Guest',
      details: 'Has access to basic learning and space functions but no account management privileges, and is limited to one space',
      isBillable: false
    },
    [roles.member]: {
      value: roles.member,
      displayTitle: 'Member',
      details: 'Has access to basic learning and space functions but no account management privileges',
      isBillable: true
    },
    [roles.admin]: {
      value: roles.admin,
      displayTitle: 'Admin',
      details: `Has full privileges to access and manage a ${Entity.Community}, but cannot take action on an Owner`,
      isBillable: true
    },
    [roles.owner]: {
      value: roles.owner,
      displayTitle: 'Owner',
      details: `Has full privileges to access and manage a ${Entity.Community}`,
      isBillable: true
    }
  }

  const currentUserIsOwner = isAuthorized(getOrganizationOwnerPolicy(currentUser?.currentOrganizationId), currentUser)

  function makeRoleSelection (e) {
    updatePerson({ [e.target.name]: e.target.value })
  }

  function isOwnerRoleAvailable (title) {
    return title === roles.owner ? currentUserIsOwner : true
  }

  const rolesDropDown =
    Object.values(organizationRolesKey).filter(role => isOwnerRoleAvailable(role.value))
      .filter(role => {
        if (!prevTitle) {
          return role.value !== roles.owner && role.value !== roles.admin
        }
        return true
      })
      .map(role =>
        <option
          key={role.value}
          data-testid={`permission-${role.value.toLowerCase()}-option`}
          value={role.value}>
          {role.displayTitle}
        </option>)

  return (
    <>
      <select
        disabled={currentOrganizationRole?.id === person?.id || !!spaceId || (!isAdminOrAbove && currentOrganization?.membersCanInvite && currentOrganization?.defaultSpace) }
        data-testid="role-select"
        className="permission-option-container"
        value={person?.title}
        onChange={makeRoleSelection}
        name="title">
        {rolesDropDown}
      </select>
      {currentUser?.currentOrganizationPrimaryOwner && person?.title === roles.owner && currentOrganizationRole?.id !== person?.id && !!person?.user?.email &&
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
        <Checkbox
            data-testid="remove-person-checkbox"
            checked={!!person?.primaryOwner}
            onChange={() => updatePerson({ primaryOwner: !person?.primaryOwner })}
          />
        <div style={{ marginLeft: '1rem', opacity: '0.6' }}>Primary Owner will receive all billing notifications</div>
      </div>}
      <div className="permission-details">
        <div className="permission-details-title">Details</div>
        <ul className="permission-details-list">
          <li>{organizationRolesKey[person?.title].details}</li>
        </ul>
      </div>
    </>
  )
}

export default SelectPermission
