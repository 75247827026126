import Table from '../Table'
import { DownloadWeeklyReportCsv } from './DownloadWeeklyAchievementsCsv'
import { DownloadWeeklyReportPdf } from './DownloadWeeklyAchievementsPdf'
import './index.css'

function WeeklyAchievements ({ data, timePeriod }) {
  const title = 'Achievements'

  const sortedDataBySpaceAndUserName = data.sort((a, b) => {
    const spaceComparison = a.spaceName.localeCompare(b.spaceName)
    if (spaceComparison !== 0) {
      return spaceComparison
    }
    return a.userName.localeCompare(b.userName)
  })

  const columns = [
    {
      header: 'Recipient',
      accessorKey: 'userName'
    },
    {
      header: 'Achievement',
      accessorKey: 'achievementName'
    },
    {
      header: 'Space',
      accessorKey: 'spaceName',
      cell: ({ row }) => (<div>{row.original.spaceName}</div>)
    }
  ]

  return (
    <div className="weekly-achievements-container">
      <div className="weekly-report-meta">
        <div>
          <h2 style={{ marginTop: 0 }}>Achievements</h2>
          <h4>{timePeriod}</h4>
        </div>
        {data.length > 0 && (
          <div className="download-buttons">
            <DownloadWeeklyReportPdf
              achievements={sortedDataBySpaceAndUserName}
              timePeriod={timePeriod}
            />
            <DownloadWeeklyReportCsv achievements={sortedDataBySpaceAndUserName} />
          </div>
        )}
      </div>
      <Table
        columns={columns}
        data={sortedDataBySpaceAndUserName}
        typeReferenceText={title}
        requiresSearch={false}
        tableWrapperClass='weekly-achievements-container'
      />
    </div>
  )
}

export default WeeklyAchievements
