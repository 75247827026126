import Attendance from '../../components/Attendance'
import './index.css'

function InsightAttendance ({ connection, clearModal }) {
  const totalConnectionAttendance =
    connection?.connectionAttendance?.length || 0

  return (
    <>
      {totalConnectionAttendance !== 0
        ? (<>
          <div className="metric-container" style={{ marginBottom: '1rem' }}>
            <div className="metric-data">{totalConnectionAttendance}</div>
            <div className="metric-label">Total Attendance</div>
          </div>
          <Attendance
            space={connection?.space}
            connection={connection}
            reviewing={true}
          />
        </>)
        : <div className="no-data">No attendance data for this space</div>}

      <div className="modal-footer">
        <div className="button secondary" onClick={() => clearModal()}>
          Close
        </div>
      </div>
    </>
  )
}

export default InsightAttendance
