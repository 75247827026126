import { useCreateDailyCheckinMutation } from '../../shared/queryHooks'
import { getOrganizationRoleDailyCheckins } from '../../shared/api'
import { PuffLoader } from 'react-spinners'
import { useModal } from '../../context/ModalContext'
import { useQueryClient } from '@tanstack/react-query'
import { useState, useEffect } from 'react'
import CheckinDisplay from '../../components/CheckinDisplay'
import './index.css'

interface DailyCheckinProps {
  actionId: number
}

type DailyCheckinResponse = {
  response: string
  checkinId: number
  description: string
  responseType: string
}

function DailyCheckin ({ actionId }: DailyCheckinProps) {
  const { clearModal } = useModal()
  const queryClient = useQueryClient()
  const submitDailyCheckin = useCreateDailyCheckinMutation()

  const [dailyCheckinResponses, setDailyCheckinResponses] = useState<DailyCheckinResponse[]>([])
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    const fetchDailyCheckins = async () => {
      const dailyCheckinData = await getDailyCheckins()
      const formatForResponse = dailyCheckinData?.map(data => ({ response: '', checkinId: data?.id, description: data?.description, responseType: data?.responseType }))
      setDailyCheckinResponses(formatForResponse)
    }
    void fetchDailyCheckins()
  }, [])

  async function getDailyCheckins () {
    return await queryClient.fetchQuery(['checkin'], () => getOrganizationRoleDailyCheckins())
  }

  function handleSubmit () {
    const isEmpty = dailyCheckinResponses?.filter(checkin => checkin?.response.trim() === '')
    if (isEmpty?.length > 0) {
      setErrorMessage('Please fill out all fields before submitting')
      return
    }
    const formatDailyResponses = dailyCheckinResponses?.map(data => ({ response: data?.response, actionId, checkinId: data?.checkinId }))
    submitDailyCheckin.mutate(formatDailyResponses)
    clearModal()
  }

  function handleChange ({ id, response }) {
    setErrorMessage('')
    setDailyCheckinResponses(prevCheckin => prevCheckin.map(checkin => {
      if (checkin?.checkinId === id) {
        return { ...checkin, response }
      }
      return checkin
    }))
  }

  return (
    <>
      {errorMessage && <div className="form-error">{errorMessage}</div>}
      <div className="checkin-prompt-container">
        <CheckinDisplay formattedCheckins={dailyCheckinResponses} handleChange={handleChange}/>
      </div>
      <div className="modal-footer">
        <div className="button secondary" onClick={() => clearModal()}>Cancel</div>
        <div className="submit-button" onClick={() => handleSubmit()}>{submitDailyCheckin?.isLoading ? <PuffLoader color="#fff" size={21} /> : 'Submit'}</div>
      </div>
    </>
  )
}

export default DailyCheckin
