import { Box, Typography, IconButton } from '@mui/material'
import {
  Favorite,
  ChatBubbleOutline,
  GridViewRounded
} from '@mui/icons-material'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import { Post, ReactionType } from '../../types'
import { useLocation, useNavigate } from 'react-router-dom'

interface PostReactionsProps {
  post: Post;
  showSpaceBadge?: boolean;
  currentOrganizationRoleId?: number;
  onReaction: (type: ReactionType) => void;
  onToggleComments: () => void;
}

function PostReactions ({
  post,
  showSpaceBadge = true,
  currentOrganizationRoleId,
  onReaction,
  onToggleComments
}: PostReactionsProps) {
  const navigate = useNavigate()
  const location = useLocation()

  const handleSpaceClick = () => {
    if (!location.pathname.includes('spaces')) {
      navigate(`/spaces/${post?.spaceId}`)
    }
  }

  const isLikedByCurrentUser = post?.reactions?.some(
    (reaction) => reaction.organizationRoleId === currentOrganizationRoleId
  )

  return (
    <Box className="post-feed_post-actions">
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          gap: '0.3rem'
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            alignItems: 'center',
            gap: '1px'
          }}
        >
          <IconButton
            size="small"
            color="inherit"
            className="reaction-comment-icons"
            onClick={() => onReaction(ReactionType.LIKE)}
          >
            {isLikedByCurrentUser
              ? (
              <Favorite
                fontSize="small"
                style={{ color: '#FF3044', fontSize: '1.1rem' }}
              />
                )
              : (
              <FavoriteBorderIcon
                fontSize="small"
                style={{ fontSize: '1.1rem' }}
              />
                )}
          </IconButton>
          <Typography
            className="post-feed_action-count"
            style={{
              color: isLikedByCurrentUser ? '#FF3044' : 'inherit',
              minWidth: '20px'
            }}
          >
            {post?.reactions?.length ?? 0}
          </Typography>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            alignItems: 'center',
            gap: '1px'
          }}
        >
          <IconButton
            size="small"
            color="inherit"
            className="reaction-comment-icons"
            onClick={onToggleComments}
          >
            <ChatBubbleOutline
              fontSize="small"
              style={{ fontSize: '1.1rem' }}
            />
          </IconButton>
          <Typography
            className="post-feed_action-count"
            style={{ minWidth: '20px' }}
          >
            {post?.totalComments ?? 0}
          </Typography>
        </div>
      </div>
      {post?.space?.name && showSpaceBadge && (
        <Box
          className="post-feed_group-tag"
          onClick={handleSpaceClick}
          style={{
            cursor: location.pathname.includes('spaces')
              ? 'default'
              : 'pointer'
          }}
        >
          <GridViewRounded fontSize="small" style={{ fontSize: '0.8rem' }} />
          <Typography className="post-feed_group-tag-text">
            {post?.space?.name ?? ''}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default PostReactions
