import { useEffectOnce } from '../../shared/hooks'
import { useParams } from 'react-router-dom'
import { useAuthContext, INVITE_CODE_KEY } from '../../context/AuthContext'
import LoadingScreen from '../../components/LoadingScreen'

function Invite () {
  const { signOutUser } = useAuthContext()
  const { inviteCode } = useParams<{inviteCode: string}>()

  useEffectOnce(() => {
    const handleLogout = async () => {
      if (inviteCode) {
        localStorage.setItem(INVITE_CODE_KEY, inviteCode)
      }
      await signOutUser()
    }
    void handleLogout()
  })

  return <LoadingScreen />
}

export default Invite
