import { Link } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthContext'
import { ProfileSelectionMenu } from '../ProfileSelectionMenu'
import './index.css'

function PublicNav () {
  const { currentUser } = useAuthContext()

  return (
    <div className="navigation">
      <div className="connectbetter-logo">
        <div className="logo-mark" />
        ConnectBetter
      </div>
      {currentUser
        ? (
        <div className="login">
          <ProfileSelectionMenu variant="public" />
        </div>
          )
        : (
        <Link to="/login" className="login">
          Login
        </Link>
          )}
    </div>
  )
}

export default PublicNav
