import * as Sentry from '@sentry/react'
import { parsedErrorMessage } from './errors'

export function logInfo (info) {
  console.info('INFO', info)
}

export function logWarn (warn) {
  console.warn('WARN', warn)
}

export function logError (error) {
  const errorMessage = parsedErrorMessage(error)
  console.error(errorMessage)
  Sentry.captureException(errorMessage)
}
