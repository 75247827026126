import { useModal } from '../../context/ModalContext'
import { useJoinEventMutation } from '../../shared/queryHooks'
import { useNavigate } from 'react-router-dom'
import { PuffLoader } from 'react-spinners'
import { formatConnectionDate, isVirtualMeeting, formatConnectionTime } from '../../shared/utilities'
import PlaceIcon from '@mui/icons-material/Place'
import GridViewRounded from '@mui/icons-material/GridViewRounded'
import './index.css'

function JoinEvent ({ event }) {
  const { clearModal } = useModal()
  const navigate = useNavigate()
  const joinEvent = useJoinEventMutation()
  const isVirtual = event.location ? isVirtualMeeting(event.location) : false

  function handleSubmit () {
    joinEvent.mutate({ eventUid: event.uid })
    navigate(`/connections/${event.uid}`)
    clearModal()
  }

  return (
    <div>
      <div style={{ marginBottom: '0.5rem' }}>Would you like to join {(event.title === null || event.title === '') && 'this'} event {event.title === null || event.title === '' ? '' : `"${event.title}"`} on {formatConnectionDate(event.startTime)} at {formatConnectionTime(event.startTime)}</div>
      <div style={{ fontStyle: 'italic' }}>This event is {' '}
        {event.endTime
          ? 'over'
          : new Date(event.startTime) > new Date()
            ? 'scheduled for later'
            : ' currently live'}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'space-between', marginTop: '2rem' }}>
        <div className="event-location"><PlaceIcon />
          <span>{isVirtual || event.location === '' ? 'Virtual Meeting' : 'Physical Location'}</span>
        </div>
        <div className="event-space" style={{ cursor: 'default' }}>
          <GridViewRounded
            fontSize="small"
            style={{ fontSize: '0.8rem', marginRight: '0.25rem' }}
          />
          {event.space?.name}
        </div>
      </div>
      <div className="modal-footer">
        <div className="button secondary" onClick={() => clearModal()}>
          Cancel
        </div>
        <div className="button" onClick={() => handleSubmit()}>
          {joinEvent.isLoading ? <PuffLoader color="#fff" size={21} /> : 'Join'}
        </div>
      </div>
    </div>
  )
}

export default JoinEvent
