import Avatar from '../Avatar'
import './index.css'

function SecondaryNavSpaceAvatars ({ spaceRoles }) {
  const checkRoleAndAvatar = ({ role, avatar }) => {
    const noAvatarClass = avatar ? '' : 'secondary-nav-no-avatar'
    const roleClass = role === 'LEADER' ? 'secondary-nav-leader-container secondary-nav-overlap' : 'secondary-nav-member-container secondary-nav-overlap'
    return `${roleClass} ${noAvatarClass}`
  }

  const contactDisplayAvatar = (role) => {
    return (
      <div key={role.organizationRoleId} className={checkRoleAndAvatar({ role: role?.title, avatar: role?.organizationRole?.user?.avatar })}>
        <Avatar className={role?.organizationRole?.user?.avatar && 'secondary-nav-spaces-avatar'} firstName={role?.organizationRole?.contact?.firstName} lastName={role?.organizationRole?.contact?.lastName} avatar={role?.organizationRole?.user?.avatar}/>
      </div>
    )
  }

  const contactDisplay = spaceRoles?.slice(0, 3).map(role => {
    return contactDisplayAvatar(role)
  })

  return (
    <>
      {contactDisplay}
      <div className="space-member-length-container">{spaceRoles?.length}</div>
    </>
  )
}

export default SecondaryNavSpaceAvatars
