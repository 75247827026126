import { useState, useRef, useEffect } from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useModal } from '../../context/ModalContext'
import './index.css'

function MediaDisplay ({ contentUrls, initialIndex }: { contentUrls: string[], initialIndex: number }) {
  const [currentIndex, setCurrentIndex] = useState(initialIndex)
  const { clearModal } = useModal()
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load()
    }
  }, [currentIndex])

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : contentUrls.length - 1))
  }

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < contentUrls.length - 1 ? prevIndex + 1 : 0))
  }

  const renderMedia = (url: string) => {
    if (url.match(/\.(jpeg|jpg|gif|png)$/)) {
      return <img src={url} alt="Post content" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }} />
    } else if (url.match(/\.(mp4|webm|ogg)$/)) {
      return <video ref={videoRef} key={currentIndex} src={url} controls style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }} />
    }
    return null
  }

  return (
    <>
      <div style={{ position: 'relative', flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', paddingTop: '15px' }}>
        {renderMedia(contentUrls[currentIndex])}
        {contentUrls.length > 1 && <div onClick={handlePrevious} className="media-toggle media-toggle-left" >
          <ChevronLeftIcon style={{ color: 'white' }} />
        </div>}
        {contentUrls.length > 1 && <div onClick={handleNext} className="media-toggle media-toggle-right">
          <ChevronRightIcon style={{ color: 'white' }} />
        </div>}
      </div>
      <div className="modal-footer">
        <div className="button" onClick={() => clearModal()}>Close</div>
      </div>
    </>
  )
}

export default MediaDisplay
