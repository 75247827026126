import { useDeleteActionMutation } from '../../shared/queryHooks'
import { PuffLoader } from 'react-spinners'
import './index.css'
import { getCurrentOrganizationRole } from '../../shared/utilities'

function DeleteAction ({ description, id, clearModal, setActionMenu, currentUser }) {
  const deleteAction = useDeleteActionMutation()
  const organizationRoleId = (getCurrentOrganizationRole(currentUser))?.id

  const handleDelete = () => {
    deleteAction.mutate({ id, organizationRoleId: organizationRoleId ?? 0 })
    setActionMenu(null)
    clearModal()
  }

  return (
    <>
      <div className="delete-description">Are you Sure you want to delete: {description}?</div>
      <div>This action will no longer be available for viewing and cannot be completed or recovered.</div>
      <div className="modal-footer">
        <div className="button secondary" onClick={() => clearModal()}>Cancel</div>
        <div className="button danger" onClick={() => handleDelete()}>{deleteAction?.isLoading ? <PuffLoader color="#fff" size={21} /> : 'Delete'}</div>
      </div>
    </>
  )
}

export default DeleteAction
