import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useEffect, useRef } from 'react'
import './index.css'
import { DisplayState } from '../../shared/utilities'
import { SharedOutsideAlerterType } from '../../pages/Connection'

interface ConnectShareDropdownProps {
  useSharedOutsideAlerter: (props: SharedOutsideAlerterType) => void
  setNavDisplay: DisplayState<string>
}

function ConnectShareDropdown ({ useSharedOutsideAlerter, setNavDisplay }: ConnectShareDropdownProps) {
  const editSpaceRef = useRef<any>(null)
  useSharedOutsideAlerter({ editSpaceRef })
  const ref = useRef<any>(null)

  useEffect(() => {
    if (ref.current) {
      ref.current.select()
    }
  }, [ref])

  async function handleLocation () {
    setNavDisplay('')
    await navigator.clipboard.writeText(window?.location?.href)
  }

  return (
    <div className="connect-share-wrapper" ref={editSpaceRef}>
      <div className="connect-share-container">
        Share this link to invite other space members to this connection
        {window?.location?.href && <div className="connect-location-container">
          <input className="location" value={window.location.href} readOnly ref={ref} onClick={() => { ref.current.select() }}/>
          <div className="copy-location" onClick={() => handleLocation()}><ContentCopyIcon/></div>
        </div>}
      </div>
    </div>
  )
}

export default ConnectShareDropdown
