import { useCallback, useEffect, useState } from 'react'
import { useAuthContext } from '../../context/AuthContext'
import SelectPermission from '../../components/SelectPermission'
import SelectSpaces from '../../components/SelectSpaces'
import { ExpandMore, ExpandLess } from '@mui/icons-material'
import {
  useAddPeopleMutation,
  useEditPersonMutation,
  useResendInvitationMutation
} from '../../shared/queryHooks'
import { useModal } from '../../context/ModalContext'
import { isAuthorized } from '../../shared/permissions'
import { getOrganizationAdminPolicy } from '../../shared/policies'
import { formatPhoneNumberInput, getCurrentOrganization } from '../../shared/utilities'
import { PuffLoader } from 'react-spinners'
import { logError } from '../../shared/logger'
import {
  validateEmail,
  validateRoleRequirements,
  validateFirstName,
  validateLastName
} from '../../shared/validators'
import GoogleAddressSearch from '../../components/GoogleAddressSearch'
import 'react-datepicker/dist/react-datepicker.css'
import './index.css'
import { Entity, roles } from '../../shared/enums'
import { OrganizationRole, InvitationStatus, Contact } from '../../types'
import { parsedErrorMessage } from '../../shared/errors'
import { formattedDate, parseDate } from '../../shared/dates'
import { useNavigate } from 'react-router-dom'
import { ReactMultiEmail } from 'react-multi-email'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { toast } from 'sonner'

interface AddOrEditPersonProps {
  existingPerson?: OrganizationRole;
  spaceId?: number;
  memberInviteOnly?: boolean;
}

function AddOrEditPerson ({ existingPerson, spaceId, memberInviteOnly }: AddOrEditPersonProps) {
  const navigate = useNavigate()
  const { currentUser } = useAuthContext()
  const currentOrganization = getCurrentOrganization(currentUser)
  const { clearModal } = useModal()
  const focusRef = useCallback((node) => {
    if (node !== null) {
      node.focus()
    }
  }, [])

  const {
    user: prevUser,
    contact: prevContact,
    title: prevTitle,
    organizationId: prevOrganizationId,
    spaceRoles: prevSpaceRoles
  } = existingPerson || {}

  const contact: Contact = {
    firstName: prevContact?.firstName || '',
    lastName: prevContact?.lastName || '',
    dob: prevContact?.dob ? new Date(prevContact?.dob).toISOString() : null,
    address: prevContact?.address || '',
    phone: prevContact?.phone || '',
    id: prevContact?.id || 0,
    email: prevContact?.email || prevUser?.email || '',
    shareData: prevContact?.shareData || false,
    createdAt: prevContact?.createdAt || null
  }

  const initialState = {
    contact,
    title: prevTitle || roles.member,
    organizationId: prevOrganizationId || currentUser?.currentOrganizationId,
    spaceRoles: prevSpaceRoles?.length ? prevSpaceRoles : []
  } as OrganizationRole

  const [person, setPerson] = useState<OrganizationRole>({
    ...existingPerson,
    ...initialState
  })

  const [submitted, setSubmitted] = useState<boolean>(false)

  const { email } = person?.contact || {}
  const { firstName, lastName, phone, dob, address } = person?.contact || {}
  const { title } = person || {}
  const [dateInput, setDateInput] = useState<string>(formattedDate(dob))
  const [emails, setEmails] = useState<string[]>([
    prevContact?.email || prevUser?.email || ''
  ])
  const [isMultiEmail, setIsMultiEmail] = useState<boolean>(false)

  const isAddingUser =
    (!prevContact?.email || email !== prevContact?.email) &&
    !prevUser &&
    email !== ''

  useEffect(() => {
    if (submitted) {
      isFormValid()
    }
  }, [firstName, lastName, email, emails, submitted, title, dob])

  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  function updatePerson (obj) {
    setPerson((prevState) => ({
      ...prevState,
      ...obj
    }))
  }

  function updateSpaceRoles (arr) {
    setPerson((prevState) => ({
      ...prevState,
      spaceRoles: arr
    }))
  }

  function updateContact (obj) {
    setPerson((prevState) => ({
      ...prevState,
      contact: {
        ...prevState?.contact,
        ...obj
      }
    }))
  }

  const setDob = (date: string) => {
    const parsedDate = parseDate(date)
    const parsedDateOffsetForDST = parsedDate?.setHours(
      parsedDate?.getHours() + 12
    )
    updateContact({ dob: parsedDateOffsetForDST })
  }

  const addPeople = useAddPeopleMutation()
  const editPerson = useEditPersonMutation()
  const resendInvite = useResendInvitationMutation()

  const onResendInvite = () => {
    resendInvite.mutate(person?.invitationCode ?? '')
  }

  async function callMutation () {
    try {
      if (existingPerson) {
        await editPerson.mutateAsync(person)
        clearModal()
      } else if (emails.length > 1) {
        const clearedPersonContact = { ...person, contact }
        await addPeople.mutateAsync({ person: clearedPersonContact, emails })
        if (!spaceId) {
          navigate('/organizations/settings/people')
        } else {
          clearModal()
        }
      } else {
        const response = await addPeople.mutateAsync({ person, emails })
        if (!spaceId) {
          navigate(`/organizations/settings/people/${response?.[0]?.id}`)
        } else {
          clearModal()
        }
      }
    } catch (error) {
      logError(error)
      setError(parsedErrorMessage(error))
    }
  }

  function isFormValid () {
    try {
      if (emails.length < 2) {
        validateFirstName(firstName)
        validateLastName(lastName)
      }

      for (const email of emails) {
        email !== '' && validateEmail(email)
        validateRoleRequirements({
          title,
          email
        })
      }
      setError(null)
      return true
    } catch (error) {
      setError(parsedErrorMessage(error))
      return false
    }
  }

  async function handleSubmit () {
    setSubmitted(true)
    isFormValid() && (await callMutation())
  }

  const additionalDetails = (
    <div>
      <input
        type="tel"
        name="phone"
        data-testid="phone-input"
        className="phone-field"
        value={phone && formatPhoneNumberInput(phone?.slice(2))}
        onChange={(e) =>
          updateContact({
            [e.target.name]:
              e.target.value && `+1${formatPhoneNumberInput(e.target.value)}`
          })
        }
        placeholder="Phone"
      />
      <input
        type="date"
        name="dob"
        data-testid="dob-input"
        className="date-field"
        value={dateInput}
        onBlur={() => setDob(dateInput)}
        onChange={(e) => setDateInput(e.target.value)}
        placeholder="Birthday"
      />
      <GoogleAddressSearch
        address={address}
        onChange={(e) => updateContact({ [e.target.name]: e.target.value })}
        setAddress={(address) => updateContact({ address })}
        placeholder="Address"
      />
    </div>
  )

  const submitText = existingPerson
    ? 'Save'
    : (emails.length === 1 && emails[0] === '') || emails.length === 0
        ? 'Add Person'
        : emails.length < 2 && !isMultiEmail
          ? 'Invite Person'
          : 'Invite People'

  const isAdminOrAbove = isAuthorized(
    getOrganizationAdminPolicy(currentUser?.currentOrganizationId),
    currentUser
  )

  const checkMultiEmail = (value: string) => {
    if (emails[0] !== '' && value !== '') {
      setIsMultiEmail(true)
    } else {
      setIsMultiEmail(false)
    }
  }

  function handleEmailChange (e) {
    const input = e.target.value
    updateContact({ email: input })
  }

  function showEmailOrSearchBar () {
    return emails.length > 0 &&
      (existingPerson?.invitationStatus === InvitationStatus.Claimed ||
        existingPerson?.invitationStatus === InvitationStatus.Pending ||
        existingPerson?.user)
      ? (
      <input
        data-testid="email-input"
        className="email-input"
        disabled={
          existingPerson?.invitationStatus === InvitationStatus.Claimed ||
          !!existingPerson?.user
        }
        placeholder={person.contact?.email || ''}
        type="text"
        value={person.contact?.email || ''}
        onChange={handleEmailChange}
      />
        )
      : (
      <>
        <ReactMultiEmail
          placeholder="Email(s) to invite - separate with commas"
          emails={emails}
          onChange={(_emails: string[]) => {
            setEmails(_emails)
          }}
          onChangeInput={(value: string) => checkMultiEmail(value)}
          autoFocus={true}
          getLabel={(email, index, removeEmail) => {
            return (
              <div data-tag key={index}>
                <div data-tag-item>{email}</div>
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  ×
                </span>
              </div>
            )
          }}
        />
      </>
        )
  }

  if (memberInviteOnly && currentOrganization?.inviteUid) {
    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginTop: '1rem' }}>
          <div style={{ paddingBottom: '0.5rem' }}>Share link to invite members to our community</div>
          <div className="invite-share-icon" onClick={() => {
            if (currentOrganization?.inviteUid) {
              navigator.clipboard.writeText(`${window.location.origin}/communities/invite/${currentOrganization.inviteUid}?organizationName=${currentOrganization.name}&organizationMembersTotal=${currentOrganization?._count?.organizationRoles}`)
              toast.success('Invite link copied to clipboard')
            }
          }}><ContentCopyIcon/></div>
        </div>
        <div className="modal-footer">
          <div className="button" onClick={() => clearModal()}>Close</div>
        </div>
      </div>
    )
  }

  return (
    <>
      {!existingPerson && (
        <div className="permission-subtitle">
          Who would you like to add to your {Entity.Community}?
        </div>
      )}
      {error && error.length > 0 && (
        <div
          className="message-banner"
          tabIndex={0}
          ref={focusRef}
          data-testid="invite-inline-error-message"
        >
          {error}
        </div>
      )}
      {showEmailOrSearchBar()}
      {person.invitationStatus === InvitationStatus.Pending && (
        <div
          style={{ fontSize: '0.9rem', opacity: 0.75, marginBottom: '1rem' }}
        >
          {isAddingUser
            ? (
                'A new invite will be sent to this email address.'
              )
            : (
            <>
              Invitation pending.{' '}
              <span
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={onResendInvite}
              >
                Resend invite?
              </span>
            </>
              )}
        </div>
      )}
      {emails.length === 1 && !isMultiEmail && (
        <div className={'name-inputs'}>
          <input
            data-testid="first-name-input"
            type="text"
            name="firstName"
            className="first-name-input"
            value={firstName}
            onChange={(e) => {
              updateContact({ [e.target.name]: e.target.value })
            }}
            placeholder="First Name"
          />
          <input
            data-testid="last-name-input"
            type="text"
            name="lastName"
            className="last-name-input"
            value={lastName}
            onChange={(e) => {
              updateContact({ [e.target.name]: e.target.value })
            }}
            placeholder="Last Name"
          />
        </div>
      )}
      {emails.length < 2 && !isMultiEmail && !existingPerson && (
        <button
          data-testid="additional-details"
          className={`button transparent additional-details-button ${
            showDetails ? 'open' : 'closed'
          }`}
          onClick={() => setShowDetails((prev) => !prev)}
        >
          {showDetails
            ? (
            <ExpandLess fontSize={'small'} />
              )
            : (
            <ExpandMore fontSize={'small'} />
              )}
          Additional Details
        </button>
      )}
      {(showDetails || !!existingPerson) &&
        emails.length < 2 &&
        additionalDetails}
      {(isAdminOrAbove || spaceId || (currentOrganization?.defaultSpace && currentOrganization?.membersCanInvite)) && (
        <>
          <div className="permission-subtitle">Role in {Entity.Community}</div>
          <SelectPermission
            person={person}
            updatePerson={updatePerson}
            prevTitle={prevTitle}
            spaceId={spaceId}
          />
        </>
      )}
      {(isAdminOrAbove || spaceId) && !existingPerson && (
        <SelectSpaces
          spaceRoles={person.spaceRoles || []}
          updateSpaceRoles={updateSpaceRoles}
          role={person.title}
          spaceId={spaceId}
        />
      )}
      <div className="modal-footer right-align-buttons">
        <div className="buttons-container">
          <button
            className="cancel-button button secondary"
            onClick={clearModal}
          >
            Cancel
          </button>
          <button
            className="button permission-button"
            data-testid="permission-button"
            onClick={handleSubmit}
          >
            {editPerson.isLoading || addPeople.isLoading
              ? (
              <PuffLoader color="#fff" size={21} />
                )
              : (
                  submitText
                )}
          </button>
        </div>
      </div>
    </>
  )
}

export default AddOrEditPerson
