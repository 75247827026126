import { Space } from '../../types'
import AddIcon from '@mui/icons-material/Add'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import './index.css'

interface SpaceNavProps {
  space: Space;
  activeSpaceFeature: string;
  setActiveSpaceFeature: (feature: string) => void;
  featureTypes: Record<string, string>;
  liveEvents?: string[];
}

function SpaceNav ({
  space,
  activeSpaceFeature,
  setActiveSpaceFeature,
  featureTypes,
  liveEvents
}: SpaceNavProps) {
  const spaceFeaturePreferences = space?.featurePreferences
    ? JSON.parse(space?.featurePreferences)
    : null

  const features = [
    {
      id: featureTypes.feed,
      order: 0,
      enabled: !!spaceFeaturePreferences?.feed
    },
    {
      id: featureTypes.events,
      order: 1,
      enabled: !!spaceFeaturePreferences?.events
    },
    {
      id: featureTypes.courses,
      order: 2,
      enabled: !!spaceFeaturePreferences?.courses
    },
    {
      id: featureTypes.media,
      order: 3,
      enabled: !!spaceFeaturePreferences?.media
    },
    {
      id: '',
      order: 4,
      enabled: true,
      icon: <AddIcon />
    }
  ]

  return (
    <div className="space-nav">
      {features.map((feature) => {
        if (feature.id === '' || !feature.enabled) {
          return null
        }

        return (
          <div
            key={feature.id}
            className={`space-nav-item ${
              activeSpaceFeature === feature.id ? 'active' : ''
            }`}
            onClick={() => setActiveSpaceFeature(feature.id)}
          >
            {feature.icon}
            {feature.id}
            {feature.id === featureTypes.events && liveEvents && liveEvents?.length > 0 && (
              <FiberManualRecordIcon sx={{ color: '#d23e3d', fontSize: '12px' }} />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default SpaceNav
