
import LoadingScreen from '../../components/LoadingScreen'
import { useEffectOnce } from '../../shared/hooks'

function ClearStorage () {
  useEffectOnce(() => {
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = '/'
  })

  return (<LoadingScreen />)
}

export default ClearStorage
