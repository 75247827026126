import { useOrganizationUserCoursePresitgeQuery } from '../../shared/queryHooks'
import { logError } from '../../shared/logger'
import './index.css'

function CoursePrestigeDisplay ({ id }) {
  const {
    isLoading,
    isError,
    data: courses,
    error
  } = useOrganizationUserCoursePresitgeQuery(id)

  if (isLoading) {
    return null
  }

  if (isError) {
    logError(error)
  }

  if (courses === null || courses?.length === 0) {
    return null
  }

  const getPrestigeColor = (prestige) => {
    switch (prestige) {
      case 1:
        return '#cd7f32'
      case 2:
        return 'silver'
      case 3:
        return 'gold'
      default:
        return '#e5e4e2'
    }
  }

  return (
    <div className="program-prestige-wrapper">
      <div style={{ fontWeight: 'bold', fontSize: '1.25rem', marginTop: '2rem' }}>Completed Courses</div>
      <div className="program-prestige-display">
       <div className="program-grid">
        {courses?.map((course, index) => (
          <div key={index} className="program-item">
            <div style={{ fontWeight: '600' }}>{course?.courseVersion?.course?.name}</div>
            <div className="program-prestige-circle" style={{
              backgroundColor: getPrestigeColor(course?.prestige),
              color: course?.prestige >= 4 ? '#232832' : undefined
            }}>{course?.prestige}
            </div>
          </div>
        ))}
      </div>
      </div>
    </div>
  )
}

export default CoursePrestigeDisplay
