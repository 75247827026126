import EmptyHero from '../../../components/EmptyHero'
import SecondaryNavBar from '../../../components/SecondaryNavBar'
import integrationsSvg from '../../../assets/integrations.svg'
import { openSupportChat } from '../../../shared/utilities'

function OrganizationSettingsIntegrations () {
  const action = [
    {
      onClick: openSupportChat,
      actionTitle: 'Give Feedback'
    }
  ]

  return (
    <>
      <SecondaryNavBar title="Integrations" />
      <div className="integrations-hero-wrapper">
        <EmptyHero
          image={integrationsSvg}
          title="Integrations"
          description="Boost your experience with other apps. What would you like to see built?"
          action={action}
        />
      </div>
    </>
  )
}

export default OrganizationSettingsIntegrations
