import { useAddContactNoteMutation, useEditContactNoteMutation } from '../../shared/queryHooks'
import { useModal } from '../../context/ModalContext'
import { useState } from 'react'
import { ContactNote } from '../../types'

interface AddOrEditContactNoteProps {
  contactNote?: ContactNote
  contactId: number
}

function AddOrEditContactNotes ({ contactNote, contactId }: AddOrEditContactNoteProps) {
  const { clearModal } = useModal()
  const addContactNotes = useAddContactNoteMutation()
  const editContactNotes = useEditContactNoteMutation()

  const [note, setNote] = useState(contactNote?.note ?? '')

  const saveContactNotes = () => {
    if (contactNote) {
      editContactNotes.mutate({ contactNoteId: contactNote.id, note })
    } else {
      addContactNotes.mutate({ contactId, note })
    }
  }

  return (
    <div data-testid='organization-user-notes-modal' className="notes-modal">
      <div className="notes-container">
        <textarea
          tabIndex={0}
          name='notes'
          data-testid="notes-textarea"
          className="notes-field"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Notes..."
        />
      </div>
      <div className="modal-footer">
        <div className="button secondary" onClick={clearModal}>Cancel</div>
        <div className="button" onClick={saveContactNotes}>Save</div>
      </div>
    </div>
  )
}

export default AddOrEditContactNotes
