import { Paper, MenuList, MenuItem, ListItemText } from '@mui/material'
import { useModal } from '../../context/ModalContext'
import { OrganizationRole } from '../../types'
import ManagePeopleStatuses from '../../modals/ManagePeopleStatuses'

interface ManagePeopleDropDownProps {
  organizationRoles: OrganizationRole[]
}

function ManagePeopleDropDown ({ organizationRoles }: ManagePeopleDropDownProps) {
  const { makeModal } = useModal()

  return (
    <div className="people-settings dropdown">
      <Paper
        sx={{ width: 175 }}
        elevation={24}>
        <MenuList dense data-testid="organization-menu">
          <div>
            <MenuItem
              data-testid="edit-member-spaces-button"
              onClick={() => makeModal({
                modal: <ManagePeopleStatuses organizationRoles={organizationRoles} activate={true}/>,
                title: 'Are you sure?'
              })}>
              <ListItemText>Activate</ListItemText>
            </MenuItem>
            <MenuItem
              data-testid="edit-member-spaces-button"
              onClick={() => makeModal({
                modalSize: 'LARGE',
                modal: <ManagePeopleStatuses organizationRoles={organizationRoles} activate={false}/>,
                title: 'Are you sure?'
              })}>
              <ListItemText>Deactivate</ListItemText>
            </MenuItem>
          </div>
        </MenuList>
      </Paper>
    </div>
  )
}

export default ManagePeopleDropDown
