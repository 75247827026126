import { useState } from 'react'
import { logError } from '../../shared/logger'
import { useModal } from '../../context/ModalContext'
import { useAuthContext } from '../../context/AuthContext'
import { getSortedContent } from '../../shared/utilities'
import { usePublicCoursesQuery } from '../../shared/queryHooks'
import LoadingScreen from '../../components/LoadingScreen'
import AddOrUpdateOrganizationCourse from '../../modals/AddOrUpdateOrganizationCourse'
import SecondaryNavBar from '../../components/SecondaryNavBar'
import './index.css'
import { Course, CourseVersion } from '../../types'

function MarketPlace () {
  const { makeModal, clearModal } = useModal()
  const { currentUser } = useAuthContext()
  const [searchParms, setSearchParms] = useState<string>('')
  const { isLoading, isError, error, data: courses } = usePublicCoursesQuery()

  if (isLoading) {
    return (<LoadingScreen />)
  }

  if (isError) {
    logError(error)
  }

  const handleChange = (search: string) => {
    setSearchParms(search)
  }

  const handleAddOrRemoveCourse = ({ courseVersion, courseVersionId, courseName, hasLatestOrganizationCourseVersion, hasOrganizationCourseVersion } : {
    courseVersion: number
    courseVersionId: number
    courseName: string
    hasLatestOrganizationCourseVersion: boolean
    hasOrganizationCourseVersion: CourseVersion[]
  }) => {
    if (hasLatestOrganizationCourseVersion) {
      return
    }
    const modalTitle = hasOrganizationCourseVersion?.length > 0 ? 'Update' : 'Add'
    makeModal({
      modal: <AddOrUpdateOrganizationCourse clearModal={clearModal} courseVersion={courseVersion} courseVersionId={courseVersionId} courseName={courseName} hasOrganizationCourseVersion={hasOrganizationCourseVersion} />,
      title: `${modalTitle} ${courseName}?`
    })
  }

  const marketCourseDisplay = courses?.map(course => {
    return courseItemDisplay(course)
  })

  function courseItemDisplay (course: Course) {
    const sortedCourseVersions = course?.courseVersion?.sort((a, b) => b.version - a.version) ?? []
    const hasOrganizationCourseVersion = sortedCourseVersions?.filter(courseVersion =>
      courseVersion?.organizationCourse?.some(orgCourse =>
        orgCourse?.organizationId === currentUser?.currentOrganizationId
      )
    ) ?? []
    const hasLatestOrganizationCourseVersion =
      sortedCourseVersions?.[0]?.organizationCourse?.some(orgCourse =>
        orgCourse?.organizationId === currentUser?.currentOrganizationId
      ) ?? false

    const courseButton = hasOrganizationCourseVersion.length > 0 ? hasLatestOrganizationCourseVersion ? 'Added' : 'Update' : 'Add'

    return (
      <div className="public-course-card-container" key={course?.id}>
        <div className="public-course-name">{course?.name}</div>
        <div className="public-course-publisher-name">{course?.program?.publisherName}</div>
        <div className="public-course-version">v{sortedCourseVersions?.[0]?.version ?? 0}.0.0</div>
        <div className="public-course-description">{course?.description}</div>
        <div className={hasLatestOrganizationCourseVersion ? 'button secondary public-remove-course-button disabled-button' : 'button public-course-button'} onClick={() => handleAddOrRemoveCourse({ courseVersion: sortedCourseVersions[0]?.version, courseVersionId: sortedCourseVersions[0]?.id, courseName: course?.name, hasLatestOrganizationCourseVersion, hasOrganizationCourseVersion })}>{courseButton}</div>
      </div>
    )
  }

  return (
    <>
      <SecondaryNavBar title="Marketplace" />
      <div className='marketplace-container'>
        <div className="marketplace">
          <div className="course-search">
            <input
              className="course-input"
              type="text"
              placeholder="Search Courses..."
              onChange={(e) => handleChange(e.target.value)}
              value={searchParms}
            />
          </div>
          <div className="courses-container">
            {searchParms ? getSortedContent(searchParms, courseItemDisplay, courses) : marketCourseDisplay}
          </div>
        </div>
      </div>
    </>)
}

export default MarketPlace
