import { useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import CourseHighlight from '../../components/CourseHighlight'
import { useAuthContext } from '../../context/AuthContext'
import {
  canCreateOrganization,
  getOrganizationsAsAdmin,
  getCourseUidFromSearchParams,
  useSwitchOrganization
} from '../../shared/utilities'
import {
  useCreateOrganizationMutation,
  usePlansQuery
} from '../../shared/queryHooks'
import { logError } from '../../shared/logger'
import LoadingScreen from '../../components/LoadingScreen'
import { Entity } from '../../shared/enums'
import './index.css'
import Button from '../../components/Button'
import { ProfileOnboarding } from '../../components/ProfileOnboarding'

function OrganizationNew () {
  const [searchParams] = useSearchParams()
  const { currentUser, refreshUser } = useAuthContext()
  const courseUid = getCourseUidFromSearchParams(searchParams)
  const createOrganization = useCreateOrganizationMutation()
  const { switchOrganization } = useSwitchOrganization()
  const navigate = useNavigate()
  const [organizationName, setOrganizationName] = useState('')
  const organizations = getOrganizationsAsAdmin(currentUser)
  const { isLoading, data: plans } = usePlansQuery()

  const handleCreateOrganization = async () => {
    if (!canCreateOrganization() || !organizationName) return
    try {
      const organization = await createOrganization.mutateAsync({
        name: organizationName,
        courseUid
      })
      await refreshUser()
      switchOrganization(organization?.id?.toString())
      navigate('/')
    } catch (error) {
      logError(error)
    }
  }

  if (isLoading) return <LoadingScreen />

  if (!currentUser?.firstName || !currentUser?.lastName) {
    return <ProfileOnboarding />
  }

  return (
    <div className="login-wrapper">
      {courseUid && <CourseHighlight courseUid={courseUid} />}
      <div className="login-container" style={{ marginTop: '1rem' }}>
        <h1 className="title">Let's create a {Entity.Community}</h1>
        <p className="subtitle">
          Free {plans?.trialDays}-day trial, no credit card required
        </p>
        {createOrganization.error instanceof Error && (
          <div className="login-error">
            Error creating {Entity.Community}: {createOrganization.error.message}
          </div>
        )}
        <input
          autoFocus
          type="text"
          data-testid="organization-name-input"
          placeholder={`${Entity.Community} name`}
          onChange={(event) => setOrganizationName(event.target.value)}
          value={organizationName}
        />
        <Button
          onClick={handleCreateOrganization}
          isLoading={createOrganization.isLoading}
          testId="login-button"
        >
          Create and continue
        </Button>
        {organizations?.length > 0 && courseUid && (
          <Link
            to={`/communities/connect?course=${courseUid}`}
            className="subtitle"
            style={{ marginTop: '1rem', marginBottom: 0 }}
          >
            Or choose an existing {Entity.Community}
          </Link>
        )}
      </div>
      {(currentUser?.organizationRoles?.length ?? 0) > 0 && (
        <Button variant='ghost' className="cancel-and-return" onClick={() => navigate('/')}>
          Cancel and return home
        </Button>
      )}
    </div>
  )
}

export default OrganizationNew
