import { useEffect } from 'react'
import PersonSettings from '../PersonSettings'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import './index.css'
import { OrganizationRole } from '../../types'

interface SettingsCellProps {
  person: OrganizationRole
  editPersonId: number | null
  personSelection: (personId: number | null) => void
  owners: OrganizationRole[]
  setExpandPersonSettingsView: (expand: boolean) => void
  requiresExpandedSettingsDisplay: boolean
}

function SettingsCell ({ person, editPersonId, personSelection, owners, setExpandPersonSettingsView, requiresExpandedSettingsDisplay }: SettingsCellProps) {
  function useOutsideAlerter (ref) {
    useEffect(() => {
      function handleClickOutside (event) {
        if (ref.current && !ref.current.contains(event.target)) {
          personSelection(null)
          setExpandPersonSettingsView(false)
        }
      }
      const isVisible = ref.current.getBoundingClientRect().bottom <= window.innerHeight

      if (!isVisible) {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const personSettingsMenu = ({ person }) => {
    return (
      <PersonSettings
        person={person}
        owners={owners}
        personSelection={personSelection}
        useOutsideAlerter={useOutsideAlerter}
      />
    )
  }

  function handleExpandPersonSettingsView () {
    editPersonId === person?.id ? personSelection(null) : personSelection(person?.id)
    if (editPersonId !== person?.id && requiresExpandedSettingsDisplay) {
      setExpandPersonSettingsView(true)
    } else {
      setExpandPersonSettingsView(false)
    }
  }

  return (
    <>
      <div className="button transparent"
        data-testid={`${person?.contact?.firstName}-${person?.contact?.lastName}-settings-icon`}
        onClick={() => handleExpandPersonSettingsView()}>
        <MoreHorizIcon />
      </div>
      <div className="settings-wrapper">
        { editPersonId === person?.id &&
        personSettingsMenu({ person }) }
      </div>
    </>
  )
}

export default SettingsCell
